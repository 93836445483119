import { Input, InputProps } from "@chakra-ui/react";
import { spacing } from "@design-system";
import { useEffect, useState } from "react";

interface DebouncedAttributeInputProps {
  value: string;
  onCommit: (newValue: number) => void | Promise<void>;
  onInvalid?: () => void;
  disabled?: boolean;
  placeholder?: string;
  inputType?: InputProps["type"];
  width?: string | number;
}

export function NumberInput({
  value,
  onCommit,
  onInvalid,
  disabled = false,
  placeholder = "0",
  width = "50%",
}: DebouncedAttributeInputProps): JSX.Element {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);

  return (
    <Input
      size="sm"
      width={width}
      height={spacing.space["600"]}
      type="number"
      placeholder={placeholder}
      disabled={disabled}
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      onBlur={() => {
        const parsed = parseFloat(localValue);

        if (isNaN(parsed)) {
          setLocalValue(value);
          onInvalid?.();
        } else {
          onCommit(parsed);
        }
      }}
    />
  );
}
