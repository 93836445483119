import { utility } from "@design-system";
import { Curve } from "@fragments/project/workspace-3d/curve";
import { useCalibrationCurves } from "@hooks/project";
import { Project } from "@models/backend";
import { Model } from "@models/project";
import { useModelsStore, useProjectState, SectionInfo, ColumnInfo } from "@state";

import {
  computeColumnCurves as v3dComputeColumnCurves,
  computeSectionCurves as v3dComputeSectionCurves,
  recordToArray,
} from "@utils/project";

import { Curve as V3dCurve, CurveSegment } from "@variant-tech/pattern-derivation";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Mesh } from "three";
import { workspace3dTokens } from "./workspace-3d-tokens.ts";

export type PartProps = {
  project: Project;
  model: Model;
  mesh: Mesh;
};

function GridCurve({
  model: { id: modelId },
  mesh,
  curve: { id, segments },
  type,
  style: { color: defaultColor, opacity: defaultOpacity },
}: {
  model: Model;
  mesh: Mesh;
  curve: { id: string; segments: CurveSegment[] };
  type: "section" | "column";
  style: { color: string; opacity: number };
}) {
  const { selectedObjects, hoveredObject, setSelectedObjects } = useModelsStore();
  const { renderModeSelection } = useProjectState();
  const selected = !!selectedObjects.find((o) => o.id === id);
  const hovered = hoveredObject?.id === id;

  const color = selected || hovered ? utility.blue : defaultColor;
  const opacity = selected || hovered ? 1.0 : defaultOpacity;
  const lineWidth = hovered ? 2.0 : 1.0;

  return segments.map(({ vertices }, idx) => (
    <Curve
      key={id + "," + idx}
      mesh={mesh}
      curve={vertices}
      color={color}
      opacity={opacity}
      width={renderModeSelection !== "design" ? lineWidth * 3 : lineWidth}
      showLengthOverride
      isSelected={selected}
      onSelected={() => setSelectedObjects([{ type, id, modelId }])}
      isHovered={hovered}
      userData={{ type, id, modelId }}
    />
  ));
}

export function Part({ project, model, mesh }: PartProps) {
  const { columns, sections, selectedObjects, setSectionsInfo, setColumnsInfo } = useModelsStore();

  const pathCollections = useModelsStore(({ pathCollections }) => pathCollections[model.id]?.collections);
  const guideSource = Object.values(pathCollections)?.find(({ usage }) => usage === "guide");

  const {
    generateGridCurves,
    getColumnCurves,
    getSectionCurves,
    removeColumnCurve,
    removeSectionCurve,
    setColumnsAndSections,
  } = useCalibrationCurves({
    project,
    model,
  });
  const sectionCurves = sections[model.id]?.sectionCurves;
  const sectionCurveStyle = workspace3dTokens.path.section;
  const columnCurves = columns[model.id]?.columnCurves;
  const columnCurveStyle = workspace3dTokens.path.column;

  const computeV3dSectionCurves = useCallback(async () => {
    if (!guideSource) return [];

    const result = await v3dComputeSectionCurves(
      model,
      guideSource,
      recordToArray(pathCollections),
      sectionCurves.map((curve) => curve.point),
    );
    const withId = (result || []).map((curve: V3dCurve, idx: number) => ({
      ...curve,
      id: sectionCurves[idx]?.id,
    }));
    const info: { [id: string]: SectionInfo } = {};
    withId.forEach(({ length, id }) => {
      info[id] = { length };
    });
    setSectionsInfo(info);
    return withId;
  }, [guideSource, model, pathCollections, sectionCurves, setSectionsInfo]);

  const computeV3dColumnCurves = useCallback(async () => {
    if (!guideSource) return [];

    const result = await v3dComputeColumnCurves(
      model,
      guideSource,
      recordToArray(pathCollections),
      columnCurves.map((curve) => curve.point),
    );
    const withId = (result || []).map((curve: V3dCurve, idx: number) => ({
      ...curve,
      id: columnCurves[idx]?.id,
    }));
    const info: { [id: string]: ColumnInfo } = {};
    withId.forEach(({ length, id }) => {
      info[id] = { length };
    });
    setColumnsInfo(info);
    return withId;
  }, [guideSource, model, pathCollections, columnCurves, setColumnsInfo]);

  const [v3dSectionCurves, setV3dSectionCurves] = useState<(V3dCurve & { id: string })[]>([]);
  const [v3dColumnCurves, setV3dColumnCurves] = useState<(V3dCurve & { id: string })[]>([]);

  useHotkeys(["delete", "backspace"], async () => {
    const selectedCurves = selectedObjects.filter((obj) => obj.type === "section" || obj.type === "column");
    await Promise.all(
      selectedCurves.map((obj) => {
        if (obj.type === "section") {
          return removeSectionCurve(obj.id);
        } else if (obj.type === "column") {
          return removeColumnCurve(obj.id);
        }
        return Promise.resolve();
      }),
    );
  });

  useEffect(() => {
    if (guideSource) {
      let numAutoColumns = model.attributes.columns?.count || 0;
      let numAutoSections = model.attributes.sections?.count || 0;

      const columnCurves = getColumnCurves(model.id);
      const sectionCurves = getSectionCurves(model.id);

      if (columnCurves.length) {
        numAutoColumns = 0;
      }

      if (sectionCurves.length) {
        numAutoSections = 0;
      }

      if (numAutoColumns || numAutoSections !== 0) {
        generateGridCurves(guideSource, numAutoSections, numAutoColumns).then();
      }
    } else {
      setColumnsAndSections([], []);
    }
  }, [guideSource]);

  const voidZones = useMemo(() => recordToArray(pathCollections).filter((it) => it.isVoid), [pathCollections]);

  useEffect(() => {
    computeV3dSectionCurves().then(setV3dSectionCurves);
  }, [sectionCurves, voidZones]);

  useEffect(() => {
    computeV3dColumnCurves().then(setV3dColumnCurves);
  }, [columnCurves, voidZones]);
  return (
    <>
      {v3dSectionCurves?.map((curve) => {
        return (
          <GridCurve
            key={curve.id}
            mesh={mesh}
            model={model}
            type="section"
            curve={{ id: curve.id, segments: curve.segments || [] }}
            style={sectionCurveStyle}
          />
        );
      })}
      {v3dColumnCurves?.map((curve) => {
        return (
          <GridCurve
            key={curve.id}
            mesh={mesh}
            model={model}
            type="column"
            curve={{ id: curve.id, segments: curve.segments || [] }}
            style={columnCurveStyle}
          />
        );
      })}
    </>
  );
}
