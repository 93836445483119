import { useProjectUpdate } from "@hooks";
import { analytics } from "@integrations/analytics";
import { Project } from "@models/backend";
import { useTemporalModelsStore } from "@state";
import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";

export function useUndoRedo(project: Project) {
  const { undo, redo, clear, pastStates, futureStates } = useTemporalModelsStore();
  const { updateProject } = useProjectUpdate(project);

  useHotkeys(["ctrl+z", "cmd+z"], async () => {
    const state = pastStates[pastStates.length - 1];

    if (state) {
      state.models?.forEach(({ mesh3DBase, scale }) => mesh3DBase.setScale(scale, scale, scale));

      await analytics.trackDurationAsync(
        "UNDO",
        async () => {
          undo();
          await updateProject(state);
        },
        { projectId: project.id, organizationId: project.organizationId },
      );
    }
  });

  useHotkeys(["ctrl+y", "cmd+y"], async () => {
    const state = futureStates[0];

    if (state) {
      state.models?.forEach(({ mesh3DBase, scale }) => mesh3DBase.setScale(scale, scale, scale));

      await analytics.trackDurationAsync(
        "REDO",
        async () => {
          redo();
          await updateProject(state);
        },
        { projectId: project.id, organizationId: project.organizationId },
      );
    }
  });

  useEffect(() => clear(), [project.id]);
}
