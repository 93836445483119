const gray = {
  "0": "#FFF",
  "50": "#F5F5F5",
  "100": "#F3F3F3",
  "200": "#DFDFDF",
  "300": "#CACACA",
  "400": "#BCBCBC",
  "500": "#AFAFAF",
  "600": "#898989",
  "650": "#5D5D5D",
  "700": "#4E4E4E",
  "750": "#3D3D3D",
  "800": "#313131",
  "850": "#2D2D2D",
  "900": "#242424",
  "950": "#1D1D1D",
  "1000": "#000",
  "0-08": "#FFFFFF14",
  "0-70": "#FFFFFFB3",
  "300-10": "#CACACA1A",
  "600-10": "#8989891A",
  "800-80": "#313131CC",
  "900-10": "#2424241A",
  "900-80": "#242424CC",
  "950-80": "#1D1D1DCC",
  "1000-16": "#00000040",
};

const orange = {
  "10": "#F75F2D1A",
  "50": "#FFF2EE",
  "100": "#FFE7DF",
  "150": "#F2D8B8",
  "400": "#F39F85",
  "600": "#F75F2D",
};

const blue = {
  "10": "#009CF21A",
  "50": "#EBF8FF",
  "100": "#DBF0FB",
  "600": "#009CF2",
  "600-20": "#009CF232",
};

const green = {
  "10": "#12B76A1A",
  "50": "#E3F8EE",
  "100": "#BEEED8",
  "600": "#12B76A",
};

const purple = {
  "10": "#6941C61A",
  "50": "#F9F5FF",
  "600": "#6941C6",
};

const red = {
  "10": "#B423181A",
  "100": "#FEF3F2",
  "800": "#FF3B2C",
};

const yellow = {
  "100": "#FFFAEB",
  "600": "#E68608",
  "800": "#B54708",
  "600-10": "#E686081A",
  "800-10": "#B547081A",
};

export const colors = {
  gray,
  orange,
  blue,
  green,
  purple,
  red,
  yellow,
};
