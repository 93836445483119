import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

export function YarnIcon({ fill = "none", ...props }: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" fill={fill} {...props}>
      <path
        d="M10 17.5C6.72372 17.5 3.93809 15.3992 2.91667 12.4712M10 17.5C13.2763 17.5 16.0619 15.3992 17.0833 12.4712M10 17.5C10 17.5 6.25 15.625 6.25 10C6.25 4.375 10 2.5 10 2.5M10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 10.8659 2.64672 11.6974 2.91667 12.4712M10 2.5C13.2655 2.5 16.0436 4.58702 17.0732 7.5M2.91667 7.5H17.0732M17.0732 7.5C17.3496 8.28195 17.5 9.12341 17.5 10C17.5 10.8659 17.3533 11.6974 17.0833 12.4712M2.91667 12.4712H17.0833"
        stroke="currentColor"
        strokeWidth="1.25"
      />
    </Icon>
  );
}
