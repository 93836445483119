import { utility } from "@design-system";
import { ProjectCommentsProjector } from "@fragments";
import { CameraController } from "@fragments/project/container/CameraController";
import { useCurrentProject } from "@fragments/project/container/useCurrentProject";
import { Grid } from "@fragments/project/workspace-3d/Grid";
import ModelViewer from "@fragments/project/workspace-3d/ModelViewer";
import { RaycasterSetup } from "@fragments/project/workspace-3d/RaycasterSetup";
import { SelectTool } from "@fragments/project/workspace-3d/SelectTool";
import { StudioLights } from "@fragments/project/workspace-3d/StudioLights";
import { Bounds, GizmoHelper, GizmoViewport, OrbitControls, PerspectiveCamera, Select } from "@react-three/drei";
import { useModelsStore, useProjectState } from "@state";
import { OnClickParams } from "@utils";

export function Workspace3D({ triggerAddCommentPopup }: { triggerAddCommentPopup: (params: OnClickParams) => void }) {
  const { project } = useCurrentProject();
  const { models } = useModelsStore(({ models }) => ({ models }));
  const { _3DToolbarSelection } = useProjectState(({ _3DToolbarSelection }) => ({ _3DToolbarSelection }));

  return (
    <>
      <PerspectiveCamera makeDefault position={[0, 0, 0]} zoom={0.75} fov={25} near={0.25} far={400}>
        <StudioLights />
      </PerspectiveCamera>
      <OrbitControls makeDefault target={[0, -0.01, 0]} dampingFactor={0.85} />
      <Grid />
      {models.length && (
        <GizmoHelper alignment="bottom-left" margin={[80, 80]}>
          <GizmoViewport axisColors={[utility.orange, utility.green, utility.blue]} labelColor={utility.white} />
        </GizmoHelper>
      )}
      <Bounds maxDuration={0}>
        <Select multiple filter={(items) => items}>
          <SelectTool>
            {models
              .filter((model) => !model.parentId)
              .map((model) => (
                <ModelViewer
                  key={model.id}
                  project={project}
                  model={model}
                  onClick={(params) => _3DToolbarSelection === "comment" && triggerAddCommentPopup(params)}
                />
              ))}
          </SelectTool>
        </Select>
      </Bounds>
      <CameraController />
      <RaycasterSetup />
      <ProjectCommentsProjector />
    </>
  );
}
