import { useDisclosure } from "@chakra-ui/react";
import { MouseEvent, useState } from "react";

export function useHeaderContextMenu() {
  const [headerContextMenuPosition, setHeaderContextMenuPosition] = useState<{ x: number; y: number } | null>(null);
  const [selectedColumnId, setSelectedColumnId] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleHeaderContextMenu = (event: MouseEvent, columnId: string) => {
    event.preventDefault();
    event.stopPropagation();

    // We still need to capture the position for the context menu
    // This is required since we're using a custom context menu with fixed positioning
    // Standard Chakra UI placements would need the menu to be a child of the element,
    // but our design requires the menu to be rendered at the Portal level
    setHeaderContextMenuPosition({ x: event.clientX, y: event.clientY });
    setSelectedColumnId(columnId);
    onOpen();
  };

  const handleCloseContextMenu = () => {
    onClose();
    setHeaderContextMenuPosition(null);
  };

  return {
    headerContextMenuPosition,
    selectedColumnId,
    isHeaderContextMenuOpen: isOpen,
    handleHeaderContextMenu,
    handleCloseContextMenu,
  };
}
