import { button, folder, useControls } from "leva";

export function SchematicShaderUniforms() {
  const defaults: Record<string, number> = {
    animation: 0.0,
    legHalfWidth: 0.13,
    legHalfPadding: -0.03,
    loopHalfWidth: 0.13,
    loopHalfPaddingX: 0.02,
    loopHalfPaddingY: 0.15,
    loopHalfBend: 0.2,
    loopGoringOffset: 0.5,
    loopGoringAAScalar: 0.3,
    farSideScale: 0.8,
    farSideDarkening: 0.5,
    distBlur: 0.0,
  };

  const [
    {
      animation,
      legHalfWidth,
      legHalfPadding,
      loopHalfWidth,
      loopHalfPaddingX,
      loopHalfPaddingY,
      loopHalfBend,
      loopGoringOffset,
      loopGoringAAScalar,
      farSideScale,
      farSideDarkening,
      distBlur,
      transparency,
      useColorFromStructure,
    },
    set,
  ] = useControls(() => ({
    "Schematic Shader Uniforms": folder({
      animation: {
        value: defaults["animation"],
        min: 0.0,
        max: 5.0,
        step: 0.01,
      },
      legHalfWidth: {
        value: defaults["legHalfWidth"],
        min: 0.01,
        max: 1.0,
        step: 0.01,
      },
      legHalfPadding: {
        value: defaults["legHalfPadding"],
        min: -0.1,
        max: 1.0,
        step: 0.01,
      },
      loopHalfWidth: {
        value: defaults["loopHalfWidth"],
        min: 0.01,
        max: 1.0,
        step: 0.01,
      },
      loopHalfPaddingX: {
        value: defaults["loopHalfPaddingX"],
        min: -1.0,
        max: 1.0,
        step: 0.01,
      },
      loopHalfPaddingY: {
        value: defaults["loopHalfPaddingY"],
        min: 0.01,
        max: 1.0,
        step: 0.01,
      },
      loopHalfBend: {
        value: defaults["loopHalfBend"],
        min: 0.01,
        max: 1.0,
        step: 0.01,
      },
      loopGoringOffset: {
        value: defaults["loopGoringOffset"],
        min: 0.01,
        max: 1.0,
        step: 0.01,
      },
      loopGoringAAScalar: {
        value: defaults["loopGoringAAScalar"],
        min: 0.01,
        max: 1.0,
        step: 0.01,
      },
      farSideScale: {
        value: defaults["farSideScale"],
        min: 0.01,
        max: 1.0,
        step: 0.01,
      },
      farSideDarkening: {
        value: defaults["farSideDarkening"],
        min: 0.0,
        max: 1.0,
        step: 0.01,
      },
      distBlur: {
        value: defaults["distBlur"],
        min: 0.0,
        max: 0.8,
        step: 0.01,
      },
      transparency: {
        value: true,
      },
      useColorFromStructure: {
        value: true,
      },
      "reset to defaults": button(() => resetToDefaults()),
    }),
  }));

  function resetToDefaults() {
    set({
      animation: defaults["animation"],
      legHalfWidth: defaults["legHalfWidth"],
      legHalfPadding: defaults["legHalfPadding"],
      loopHalfWidth: defaults["loopHalfWidth"],
      loopHalfPaddingX: defaults["loopHalfPaddingX"],
      loopHalfPaddingY: defaults["loopHalfPaddingY"],
      loopHalfBend: defaults["loopHalfBend"],
      loopGoringOffset: defaults["loopGoringOffset"],
      loopGoringAAScalar: defaults["loopGoringAAScalar"],
      farSideScale: defaults["farSideScale"],
      farSideDarkening: defaults["farSideDarkening"],
      distBlur: defaults["distBlur"],
    });
  }

  return {
    animation,
    legHalfWidth,
    legHalfPadding,
    loopHalfWidth,
    loopHalfPaddingX,
    loopHalfPaddingY,
    loopHalfBend,
    loopGoringOffset,
    loopGoringAAScalar,
    farSideScale,
    farSideDarkening,
    distBlur,
    transparency,
    useColorFromStructure,
  };
}
