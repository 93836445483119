import { useAuth } from "@auth";
import { Button, ButtonGroup, IconButton, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { LogoIcon, Separator, useSelectedTheme, useSemanticTokens } from "@design-system";
import { useTranslation } from "@hooks";
import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Link as RouterLink } from "react-router-dom";
import { HamburgerIcon } from "./icons";

type ApplicationContextMenuProps = {
  placement?: "bottom-start" | "bottom-end" | "top-start" | "top-end";
  showThemeButtons?: boolean;
  showDashboardLink?: boolean;
  showContactUs?: boolean;
  showLogout?: boolean;
  showColorways?: boolean;
  fileShareUrl?: string;
  onColorwaysClick?: () => void;
};

export const ApplicationContextMenu: React.FC<ApplicationContextMenuProps> = ({
  placement = "bottom-start",
  showThemeButtons = true,
  showDashboardLink = false,
  showContactUs = false,
  showLogout = false,
  showColorways = false,
  fileShareUrl,
  onColorwaysClick,
}) => {
  const { availableThemes, selectedTheme, setSelectedTheme, toggleTheme } = useSelectedTheme();
  const semanticTokens = useSemanticTokens();
  const { logout } = useAuth();
  const { t } = useTranslation("applicationContextMenu");

  useHotkeys("ctrl+t", (e) => {
    e.preventDefault();
    toggleTheme();
  });

  const openContactForm = () => {
    window.open("https://airtable.com/appZrtSy8nqllTBsN/pagZBJoHaLCJfxmvW/form", "_blank");
  };

  return (
    <Menu placement={placement}>
      <MenuButton
        as={IconButton}
        icon={
          <>
            <HamburgerIcon color={semanticTokens.icons.primary} transition="color 0.2s" />
            <LogoIcon color={semanticTokens.icons.primary} transition="color 0.2s" />
          </>
        }
        aria-label={t("menuLabel")}
        variant=""
        height="100%"
        ml="2px"
        _hover={{
          "& svg": { color: semanticTokens.icons.accent.primary },
        }}
        _active={{
          "& svg": { color: semanticTokens.icons.accent.primary },
        }}
        data-test-id="application-context-menu"
      />
      <MenuList>
        {showThemeButtons && (
          <MenuItem as="div">
            <ButtonGroup isAttached variant="inverse">
              {availableThemes.map((theme) => (
                <Button onClick={() => setSelectedTheme(theme)} isActive={selectedTheme === theme}>
                  {t(`${theme}Theme`)}
                </Button>
              ))}
            </ButtonGroup>
          </MenuItem>
        )}

        {showDashboardLink && (
          <MenuItem as={RouterLink} to="/">
            {t("goToDashboard")}
          </MenuItem>
        )}

        {showColorways && <MenuItem onClick={onColorwaysClick}>Colorways</MenuItem>}

        {(showDashboardLink || showColorways) && fileShareUrl && <Separator p={0} m={0} variant="inverse" />}

        {fileShareUrl && (
          <MenuItem onClick={() => window.open(fileShareUrl, "_blank")}>{t("openAssetsFolder")}</MenuItem>
        )}

        {fileShareUrl && <Separator p={0} m={0} variant="inverse" />}

        {showLogout && (
          <MenuItem onClick={logout} data-test-id="logout-button">
            {t("logout")}
          </MenuItem>
        )}

        {showContactUs && <MenuItem onClick={openContactForm}>{t("contactUs")}</MenuItem>}
      </MenuList>
    </Menu>
  );
};
