import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function ColorwaysIcon(props: IconProps) {
  const clipUrl = "#clip" + props.id + ")";
  return (
    <Icon viewBox="0 0 16 16" height="16px" width="16px" fill="none" {...props}>
      <g clipPath={"url(" + clipUrl + ")"}>
        <path
          d="M2.60938 12.6875C2.60938 12.2979 2.92432 11.9844 3.3125 11.9844C3.70215 11.9844 4.01562 12.2979 4.01562 12.6875C4.01562 13.0771 3.70215 13.3906 3.3125 13.3906C2.92432 13.3906 2.60938 13.0771 2.60938 12.6875ZM0.5 2.14062C0.5 1.23447 1.23447 0.5 2.14062 0.5H4.48438C5.38965 0.5 6.125 1.23447 6.125 2.14062V6.01367L8.79688 3.34092C9.43848 2.7002 10.4785 2.7002 11.1172 3.34092L12.7754 4.99707C13.417 5.63867 13.417 6.67871 12.7754 7.31738L10.2178 9.875H13.8594C14.7646 9.875 15.5 10.6104 15.5 11.5156V13.8594C15.5 14.7646 14.7646 15.5 13.8594 15.5H3.3125C1.75918 15.5 0.5 14.2402 0.5 12.6875V2.14062ZM14.5625 13.8594V11.5156C14.5625 11.126 14.249 10.8125 13.8594 10.8125H9.28027L5.53027 14.5625H13.8594C14.249 14.5625 14.5625 14.249 14.5625 13.8594ZM12.1133 5.66211L10.4551 4.00391C10.1797 3.72852 9.73438 3.72852 9.46191 4.00391L6.125 7.34082V12.6436L12.1133 6.65527C12.3887 6.37988 12.3887 5.93457 12.1133 5.66211ZM3.3125 14.5625C4.34668 14.5625 5.1875 13.7217 5.1875 12.6875V8.9375H1.4375V12.6875C1.4375 13.7217 2.27686 14.5625 3.3125 14.5625ZM1.4375 8H5.1875V5.1875H1.4375V8ZM1.4375 4.25H5.1875V2.14062C5.1875 1.75244 4.87402 1.4375 4.48438 1.4375H2.14062C1.75244 1.4375 1.4375 1.75244 1.4375 2.14062V4.25Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipUrl}>
          <rect width="15" height="15" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </Icon>
  );
}
