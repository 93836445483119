import { colors } from "./colors";
import { hexToRgba } from "@uiw/color-convert";

export type SemanticTokens = {
  text: {
    primary: string;
    secondary: string;
    tertiary: string;
    accent: {
      primary: string;
      secondary: string;
      tertiary: string;
    };
    invert: {
      primary: string;
      secondary: string;
      tertiary: string;
    };
    warning: string;
    success: string;
    error: string;
    classic: {
      primary: string;
      secondary: string;
    };
  };
  surface: {
    accent: {
      primary: string;
      secondary: string;
      tertiary: string;
      "tertiary-opacity": string;
    };
    invert: {
      secondary: string;
      tertiary: string;
      accent: {
        primary: string;
        secondary: string;
      };
    };
    warning: string;
    error: string;
    disabled: string;
    modal: string;
    primary: string;
    secondary: string;
    tertiary: string;
    quaternary: string;
    quinary: string;
    "primary-opacity": string;
    classic: {
      primary: string;
      "primary-opacity": string;
      secondary: string;
      tertiary: string;
      quaternary: string;
      quinary: string;
    };
  };
  icons: {
    primary: string;
    accent: {
      primary: string;
      secondary: string;
      tertiary: string;
    };
    invert: string;
    warning: string;
    success: string;
    disabled: string;
    secondary: string;
    tertiary: string;
    classic: {
      secondary: string;
      tertiary: string;
    };
  };
  border: {
    border: string;
    secondary: string;
    invert: {
      primary: string;
      secondary: string;
    };
    accent: {
      secondary: string;
      primary: string;
    };
    success: string;
    warning: string;
    error: string;
    classic: {
      primary: string;
      secondary: string;
      tertiary: string;
      invert: {
        primary: string;
      };
    };
  };
};

export const utility = {
  "warm-white": colors.orange["150"],
  white: colors.gray["0"],
  beige: colors.gray["100"],
  orange: colors.orange["600"],
  blue: colors.blue["600"],
  green: colors.green["600"],
  purple: colors.purple["600"],
  red: colors.red["800"],
  yellow: colors.yellow["600"],
  brown: colors.yellow["800"],
  gray: colors.gray["300"],
  lightGray: colors.gray["200"],
  darkGray: colors.gray["650"],
  black: colors.gray["950"],
  secondary: {
    orange: colors.orange["10"],
    blue: colors.blue["10"],
    green: colors.green["10"],
    purple: colors.purple["10"],
    red: colors.red["10"],
    yellow: colors.yellow["600-10"],
    brown: colors.yellow["800-10"],
    gray: colors.gray["300-10"],
    white: colors.gray["0-08"],
    darkGray: colors.gray["900-10"],
    black: colors.gray["1000-16"],
  },
};

export const classic: SemanticTokens = {
  text: {
    primary: colors.gray["950"],
    secondary: colors.gray["500"],
    tertiary: colors.gray["500"],
    accent: {
      primary: colors.orange["600"],
      secondary: colors.blue["600"],
      tertiary: colors.purple["600"],
    },
    invert: {
      primary: colors.gray["0"],
      secondary: colors.gray["300"],
      tertiary: colors.gray["600"],
    },
    warning: colors.yellow["600"],
    success: colors.green["600"],
    error: colors.red["800"],
    classic: {
      primary: colors.gray["50"],
      secondary: colors.gray["500"],
    },
  },
  surface: {
    accent: {
      primary: colors.orange["50"],
      secondary: colors.orange["400"],
      tertiary: colors.blue["600"],
      "tertiary-opacity": colors.blue["600-20"],
    },
    invert: {
      secondary: colors.gray["50"],
      tertiary: colors.gray["100"],
      accent: {
        primary: colors.orange["600"],
        secondary: colors.orange["10"],
      },
    },
    warning: colors.yellow["600"],
    error: colors.red["800"],
    disabled: colors.gray["50"],
    modal: colors.gray["0"],
    primary: colors.gray["0"],
    secondary: colors.gray["100"],
    tertiary: colors.gray["200"],
    quaternary: colors.gray["300"],
    quinary: colors.gray["400"],
    "primary-opacity": colors.gray["0-70"],
    classic: {
      primary: colors.gray["900"],
      "primary-opacity": colors.gray["900-80"],
      secondary: colors.gray["850"],
      tertiary: colors.gray["750"],
      quaternary: colors.gray["700"],
      quinary: colors.gray["650"],
    },
  },
  icons: {
    primary: colors.gray["600"],
    accent: {
      primary: colors.orange["600"],
      secondary: colors.orange["400"],
      tertiary: colors.blue["600"],
    },
    invert: colors.gray["0"],
    warning: colors.yellow["600"],
    success: colors.green["600"],
    disabled: colors.gray["200"],
    secondary: colors.gray["950"],
    tertiary: colors.gray["750"],
    classic: {
      secondary: colors.gray["0"],
      tertiary: colors.gray["750"],
    },
  },
  border: {
    border: colors.gray["300"],
    secondary: colors.gray["200"],
    invert: {
      primary: colors.gray["200"],
      secondary: colors.gray["650"],
    },
    accent: {
      primary: colors.orange["600"],
      secondary: colors.blue["600"],
    },
    success: colors.green["600"],
    warning: colors.yellow["600"],
    error: colors.red["800"],
    classic: {
      primary: colors.gray["750"],
      secondary: colors.gray["750"],
      tertiary: colors.gray["750"],
      invert: { primary: colors.gray["800"] },
    },
  },
};

export const dark: SemanticTokens = {
  text: {
    primary: colors.gray["50"],
    invert: {
      primary: colors.gray["0"],
      secondary: colors.gray["300"],
      tertiary: colors.gray["600"],
    },
    accent: {
      primary: colors.orange["600"],
      secondary: colors.blue["600"],
      tertiary: colors.purple["600"],
    },
    secondary: colors.gray["500"],
    tertiary: colors.gray["600"],
    warning: colors.yellow["600"],
    success: colors.green["600"],
    error: colors.red["800"],
    classic: {
      primary: colors.gray["50"],
      secondary: colors.gray["500"],
    },
  },
  surface: {
    accent: {
      primary: colors.gray["800"],
      secondary: colors.orange["400"],
      tertiary: colors.blue["600"],
      "tertiary-opacity": colors.blue["600-20"],
    },
    invert: {
      secondary: colors.gray["800"],
      tertiary: colors.gray["700"],
      accent: {
        primary: colors.orange["600"],
        secondary: colors.orange["10"],
      },
    },
    warning: colors.yellow["600"],
    error: colors.red["800"],
    disabled: colors.gray["50"],
    modal: colors.gray["900"],
    primary: colors.gray["900"],
    secondary: colors.gray["800"],
    tertiary: colors.gray["750"],
    quaternary: colors.gray["700"],
    quinary: colors.gray["650"],
    "primary-opacity": colors.gray["900-80"],
    classic: {
      primary: colors.gray["900"],
      "primary-opacity": colors.gray["900-80"],
      secondary: colors.gray["850"],
      tertiary: colors.gray["750"],
      quaternary: colors.gray["700"],
      quinary: colors.gray["650"],
    },
  },
  icons: {
    primary: colors.gray["600"],
    accent: {
      primary: colors.orange["600"],
      secondary: colors.orange["400"],
      tertiary: colors.blue["600"],
    },
    invert: colors.gray["0"],
    warning: colors.yellow["600"],
    success: colors.green["600"],
    disabled: colors.gray["200"],
    secondary: colors.gray["0"],
    tertiary: colors.gray["750"],
    classic: {
      secondary: colors.gray["0"],
      tertiary: colors.gray["750"],
    },
  },
  border: {
    border: colors.gray["750"],
    secondary: colors.gray["750"],
    invert: {
      primary: colors.gray["800"],
      secondary: colors.gray["650"],
    },
    accent: {
      primary: colors.orange["600"],
      secondary: colors.blue["600"],
    },
    success: colors.green["600"],
    warning: colors.yellow["600"],
    error: colors.red["800"],
    classic: {
      primary: colors.gray["750"],
      secondary: colors.gray["750"],
      tertiary: colors.gray["750"],
      invert: { primary: colors.gray["800"] },
    },
  },
};

export const light: SemanticTokens = {
  text: {
    primary: colors.gray["950"],
    invert: {
      secondary: colors.gray["300"],
      primary: colors.gray["0"],
      tertiary: colors.gray["600"],
    },
    accent: {
      primary: colors.orange["600"],
      secondary: colors.blue["600"],
      tertiary: colors.purple["600"],
    },
    secondary: colors.gray["600"],
    tertiary: colors.gray["500"],
    warning: colors.yellow["600"],
    success: colors.green["600"],
    error: colors.red["800"],
    classic: {
      primary: colors.gray["950"],
      secondary: colors.gray["600"],
    },
  },
  surface: {
    accent: {
      primary: colors.orange["50"],
      secondary: colors.orange["400"],
      tertiary: colors.blue["600"],
      "tertiary-opacity": colors.blue["600-20"],
    },
    invert: {
      secondary: colors.gray["50"],
      tertiary: colors.gray["100"],
      accent: {
        primary: colors.orange["600"],
        secondary: colors.orange["10"],
      },
    },
    warning: colors.yellow["600"],
    error: colors.red["800"],
    disabled: colors.gray["50"],
    modal: colors.gray["0"],
    primary: colors.gray["0"],
    secondary: colors.gray["100"],
    tertiary: colors.gray["200"],
    quaternary: colors.gray["300"],
    quinary: colors.gray["400"],
    "primary-opacity": colors.gray["0-70"],
    classic: {
      primary: colors.gray["0"],
      "primary-opacity": colors.gray["0-70"],
      secondary: colors.gray["100"],
      tertiary: colors.gray["200"],
      quaternary: colors.gray["300"],
      quinary: colors.gray["400"],
    },
  },
  icons: {
    primary: colors.gray["600"],
    accent: {
      primary: colors.orange["600"],
      secondary: colors.orange["400"],
      tertiary: colors.blue["600"],
    },
    invert: colors.gray["0"],
    warning: colors.yellow["600"],
    success: colors.green["600"],
    disabled: colors.gray["200"],
    secondary: colors.gray["950"],
    tertiary: colors.gray["750"],
    classic: {
      secondary: colors.gray["950"],
      tertiary: colors.gray["200"],
    },
  },
  border: {
    border: colors.gray["300"],
    secondary: colors.gray["200"],
    invert: {
      primary: colors.gray["200"],
      secondary: colors.gray["650"],
    },
    accent: {
      primary: colors.orange["600"],
      secondary: colors.blue["600"],
    },
    success: colors.green["600"],
    warning: colors.yellow["600"],
    error: colors.red["800"],
    classic: {
      primary: colors.gray["300"],
      secondary: colors.gray["200"],
      tertiary: colors.gray["200"],
      invert: { primary: colors.gray["200"] },
    },
  },
};

export const base = { dark, light };

export function semanticTokens(theme: Record<string, unknown>, colorMode: keyof typeof base) {
  return theme.theme === "classic" ? classic : base[colorMode];
}

export function resolveHexValue(hexOrColorValue: string) {
  const hexShortAlphaRegex = /^#[0-9A-Fa-f]{4}$/;

  if (hexShortAlphaRegex.test(hexOrColorValue)) {
    return hexOrColorValue.slice(0, -1);
  }

  const hexAlphaRegex = /^#[0-9A-Fa-f]{8}$/;

  if (hexAlphaRegex.test(hexOrColorValue)) {
    return hexOrColorValue.slice(0, -2);
  }

  return hexOrColorValue;
}

export function resolveHexValueRgba(hexOrColorValue: string) {
  return hexToRgba(resolveHexValue(hexOrColorValue));
}

export function resolveHexValueRgbaNormalized(hexOrColorValue: string) {
  const rgba = resolveHexValueRgba(hexOrColorValue);
  return [rgba.r / 255, rgba.g / 255, rgba.b / 255, rgba.a / 255];
}
