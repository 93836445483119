import { Yarn } from "@models/backend";
import { arrayToRecord } from "@utils";
import equal from "fast-deep-equal/es6";
import { temporal } from "zundo";
import { create } from "zustand";
import { useStoreWithEqualityFn } from "zustand/traditional";

export type YarnsStore = {
  yarns: Record<Yarn["id"], Yarn>;

  setYarns: (yarns: Yarn[]) => void;
  updateYarn: (yarn: Yarn) => void;
  removeYarn: (id: Yarn["id"]) => void;
  clear: () => void;
};

export const useYarnsStore = create<YarnsStore>()(
  temporal(
    (set): YarnsStore => ({
      yarns: {},

      setYarns: (yarns) =>
        set({
          yarns: arrayToRecord(yarns, (y) => y.id),
        }),

      updateYarn: (yarn) =>
        set((state) => ({
          yarns: {
            ...state.yarns,
            [yarn.id]: yarn,
          },
        })),

      removeYarn: (id) =>
        set((state) => {
          const newYarns = { ...state.yarns };
          delete newYarns[id];
          return { yarns: newYarns };
        }),

      clear: () => set({ yarns: {} }),
    }),
    {
      limit: 20,
      partialize: ({ yarns }) => ({ yarns }),
      equality: (pastState, currentState) => equal(pastState.yarns, currentState.yarns),
    },
  ),
);

export const useTemporalYarnsStore = () => useStoreWithEqualityFn(useYarnsStore.temporal);
