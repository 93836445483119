import { resolveHexValue, useSemanticTokens } from "@design-system";
import { Grid as DreiGrid } from "@react-three/drei";
import { useModelsStore } from "@state";
import { recordToArray } from "@utils";
import { useEffect, useState } from "react";
import { DoubleSide, Vector3 } from "three";

export function Grid() {
  const [gridPos, setGridPos] = useState<Vector3>(new Vector3(0, 0, 0));
  const { meshes } = useModelsStore();

  const gridColor = resolveHexValue(useSemanticTokens().surface.quaternary);

  useEffect(() => {
    const center = new Vector3();
    let height = Infinity;

    recordToArray(meshes)
      .map((mesh) => mesh.mesh.geometry)
      .forEach((geometry) => {
        geometry.computeBoundingBox();

        if (geometry.boundingBox && geometry.boundingBox.min.y < height) {
          height = geometry.boundingBox.min.y;
          geometry.boundingBox.getCenter(center);
        }
      });

    setGridPos(new Vector3(center.x, height, center.z));
  }, [meshes]);

  return (
    <DreiGrid
      name="grid"
      position={[gridPos.x, gridPos.y, gridPos.z]}
      args={[50, 50]}
      cellSize={5}
      sectionSize={5}
      cellColor={gridColor}
      sectionColor={gridColor}
      sectionThickness={0.75}
      cellThickness={0.75}
      fadeDistance={250}
      infiniteGrid
      side={DoubleSide}
    />
  );
}
