import { Size, useFrame, useThree } from "@react-three/fiber";
import { CommentThreadState, useModelsStore } from "@state";
import { recordToArray } from "@utils";
import { Vector3 as Vector3Tuple } from "@variant-tech/pattern-derivation";
import { Camera, Group, Mesh, Raycaster, Vector3 } from "three";

function project(camera: Camera, size: Size, position: Vector3Tuple) {
  const vector = new Vector3(...position);

  vector.project(camera);

  const x = Math.round((vector.x * 0.5 + 0.5) * size.width);
  const y = Math.round((1 - vector.y * 0.5 - 0.5) * size.height);

  return { x, y };
}

const raycaster = new Raycaster();

function checkForOcclusion(camera: Camera, group: Group, position: Vector3Tuple) {
  const v = new Vector3();

  v.copy(camera.position).sub(new Vector3(...position));
  raycaster.set(new Vector3(...position), v);

  const intersections = raycaster.intersectObject(group);
  const tolerance = intersections.some((i) => i.point.distanceTo(new Vector3(...position)) > 0.1);

  return intersections.length > 0 && tolerance;
}

function updateProjections(
  camera: Camera,
  size: Size,
  meshes: Mesh[],
  states: CommentThreadState[],
  setCommentThread: (state: CommentThreadState) => void,
) {
  return states
    .map((thread) => {
      const projection = project(camera, size, thread.position);

      if (thread.projection && thread.projection.x == projection.x && thread.projection.y == projection.y) {
        return thread;
      }

      const occluded = checkForOcclusion(camera, new Group().add(...meshes), thread.position);

      return { ...thread, projection, occluded } as CommentThreadState;
    })
    .forEach(setCommentThread);
}

export function ProjectCommentsProjector() {
  const { getCommentThreads, setCommentThread, meshes } = useModelsStore();
  const { camera, size } = useThree();

  useFrame(() => {
    updateProjections(
      camera,
      size,
      recordToArray(meshes).map(({ mesh }) => mesh),
      getCommentThreads(),
      setCommentThread,
    );
  });

  return null;
}
