import {
  columnsSlice,
  commentThreadsState,
  errorsSlice,
  knitStructuresSlice,
  machinesSlice,
  meshesSlice,
  modelsSlice,
  pathCollectionsSlice,
  postprocessorDocumentsSlice,
  processesSlice,
  sectionsSlice,
  selectionSlice,
  infoSlice,
} from "@state/models/slices";
import { colorwaysSlice } from "@state/models/slices/colorways.ts";
import { yarnTokensSlice } from "@state/models/slices/yarnTokens.ts";
import equal from "fast-deep-equal/es6";
import { temporal } from "zundo";
import { create } from "zustand";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { ModelsStore } from "./types";

export type {
  ModelsStore,
  PathCollections,
  SelectedObject,
  CommentThreadState,
  ProjectCommentThreadsState,
  ColumnInfo,
  SectionInfo,
} from "./types";

export type TemporalModelsStore = Pick<
  ModelsStore,
  "pathCollections" | "models" | "columns" | "sections" | "commentThreads"
>;

export const useModelsStore = create<ModelsStore>()(
  temporal(
    (set, get): ModelsStore => ({
      ...meshesSlice(set),
      ...modelsSlice(set),
      ...pathCollectionsSlice(set, get),
      ...sectionsSlice(set, get),
      ...columnsSlice(set, get),
      ...selectionSlice(set),
      ...knitStructuresSlice(set),
      ...machinesSlice(set),
      ...postprocessorDocumentsSlice(set),
      ...processesSlice(set),
      ...commentThreadsState(set, get),
      ...errorsSlice(set),
      ...infoSlice(set),
      ...colorwaysSlice(set, get),
      ...yarnTokensSlice(set, get),
      clear: () => {
        set({
          commentThreads: {},
          models: [],
          pathCollections: {},
          sections: {},
          knitStructures: [],
          machines: [],
          postprocessorDocuments: [],
          columns: {},
          selectedObjects: [],
          process: undefined,
          errorObjects: [],
          colorways: [],
          yarnTokens: [],
          pathsInfo: {},
        });
      },
      logModels: () => console.log("logModels", get().models),
    }),
    {
      limit: 20,
      equality: (pastState: TemporalModelsStore, currentState: TemporalModelsStore) => {
        return (
          equal(pastState.pathCollections, currentState.pathCollections) &&
          equal(pastState.models, currentState.models) &&
          equal(pastState.sections, currentState.sections) &&
          equal(pastState.columns, currentState.columns)
        );
      },
      partialize: ({ pathCollections, models, sections, columns, commentThreads, colorways, yarnTokens }) => ({
        pathCollections,
        models,
        sections,
        columns,
        commentThreads,
        colorways,
        yarnTokens,
      }),
    },
  ),
);

export const useTemporalModelsStore = () => useStoreWithEqualityFn(useModelsStore.temporal);
