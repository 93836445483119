import React, { useRef, useState, useEffect } from "react";
import { Box, Portal, Flex, IconButton, Text } from "@chakra-ui/react";
import { CloseIcon } from "@design-system/icons/CloseIcon";
import { useSemanticTokens, useThemeTokens, spacing } from "@design-system";

interface DraggableResizablePanelProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  initialPosition?: { x: number; y: number };
  initialSize?: { width: number; height: number };
  minWidth?: number;
  minHeight?: number;
  closeButtonSize?: "xs" | "sm" | "md" | "lg";
  headerTextColor?: string;
  closeIconColor?: string;
  children: React.ReactNode;
}

export const DraggableResizablePanel: React.FC<DraggableResizablePanelProps> = ({
  isOpen,
  onClose,
  title,
  initialPosition = { x: 100, y: 100 },
  initialSize = { width: 800, height: 500 },
  minWidth = 400,
  minHeight = 300,
  closeButtonSize = "xs",
  headerTextColor,
  closeIconColor,
  children,
}) => {
  const semanticTokens = useSemanticTokens();
  const { border } = useThemeTokens();
  const panelRef = useRef<HTMLDivElement>(null);

  const [position, setPosition] = useState(initialPosition);
  const [size, setSize] = useState(initialSize);
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [resizeDirection, setResizeDirection] = useState<string | null>(null);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e: React.MouseEvent) => {
    if (e.button !== 0) return;
    e.preventDefault();

    const rect = panelRef.current?.getBoundingClientRect();
    if (!rect) return;

    setIsDragging(true);
    setDragOffset({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  const handleResizeMouseDown = (e: React.MouseEvent, direction: string) => {
    e.preventDefault();
    e.stopPropagation();
    setIsResizing(true);
    setResizeDirection(direction);
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        setPosition({
          x: e.clientX - dragOffset.x,
          y: e.clientY - dragOffset.y,
        });
      } else if (isResizing && resizeDirection) {
        const rect = panelRef.current?.getBoundingClientRect();
        if (!rect) return;

        let newWidth = size.width;
        let newHeight = size.height;

        if (resizeDirection.includes("e")) {
          newWidth = Math.max(minWidth, e.clientX - rect.left);
        }
        if (resizeDirection.includes("s")) {
          newHeight = Math.max(minHeight, e.clientY - rect.top);
        }
        if (resizeDirection.includes("w")) {
          const deltaX = rect.left - e.clientX;
          newWidth = Math.max(minWidth, size.width + deltaX);
          if (newWidth !== size.width) {
            setPosition((prev) => ({ ...prev, x: prev.x - deltaX }));
          }
        }
        if (resizeDirection.includes("n")) {
          const deltaY = rect.top - e.clientY;
          newHeight = Math.max(minHeight, size.height + deltaY);
          if (newHeight !== size.height) {
            setPosition((prev) => ({ ...prev, y: prev.y - deltaY }));
          }
        }

        setSize({ width: newWidth, height: newHeight });
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      setIsResizing(false);
      setResizeDirection(null);
    };

    if (isDragging || isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, isResizing, resizeDirection, dragOffset, size, minWidth, minHeight]);

  if (!isOpen) return null;

  return (
    <Portal>
      <Box
        ref={panelRef}
        position="fixed"
        zIndex={1400}
        top={`${position.y}px`}
        left={`${position.x}px`}
        width={`${size.width}px`}
        height={`${size.height}px`}
        bg={semanticTokens.surface.classic.primary}
        boxShadow="0px 4px 8px rgba(0, 0, 0, 0.3)"
        border={border.width}
        borderColor={semanticTokens.border.classic.primary}
        display="flex"
        flexDirection="column"
        overflow="hidden"
      >
        <Flex
          p={spacing.space[200]}
          bg={semanticTokens.surface.classic.secondary}
          borderBottom={border.width}
          borderColor={semanticTokens.border.classic.primary}
          alignItems="center"
          onMouseDown={handleMouseDown}
          cursor="default"
          userSelect="none"
        >
          <Text fontWeight="medium" flex="1" ml={spacing.space[200]} color={headerTextColor}>
            {title}
          </Text>
          <IconButton
            aria-label="Close panel"
            icon={<CloseIcon color={closeIconColor} />}
            size={closeButtonSize}
            variant="ghost"
            onClick={onClose}
          />
        </Flex>

        <Box
          flex="1"
          overflow="auto"
          position="relative"
          onScroll={(e) => {
            e.stopPropagation();
          }}
          style={{ willChange: "transform" }}
        >
          {React.Children.map(children, (child) => {
            return React.cloneElement(child as React.ReactElement, {
              panelscrolltop: panelRef.current?.scrollTop,
            });
          })}
        </Box>

        <Box
          position="absolute"
          bottom={0}
          right={0}
          width={spacing.space[200]}
          height={spacing.space[200]}
          cursor="nwse-resize"
          onMouseDown={(e) => handleResizeMouseDown(e, "se")}
        />
        <Box
          position="absolute"
          bottom={0}
          left={0}
          width={spacing.space[200]}
          height={spacing.space[200]}
          cursor="nesw-resize"
          onMouseDown={(e) => handleResizeMouseDown(e, "sw")}
        />
        <Box
          position="absolute"
          top={0}
          right={0}
          width={spacing.space[200]}
          height={spacing.space[200]}
          cursor="nesw-resize"
          onMouseDown={(e) => handleResizeMouseDown(e, "ne")}
        />
        <Box
          position="absolute"
          top={0}
          left={0}
          width={spacing.space[200]}
          height={spacing.space[200]}
          cursor="nwse-resize"
          onMouseDown={(e) => handleResizeMouseDown(e, "nw")}
        />
        <Box
          position="absolute"
          right={0}
          top={spacing.space[200]}
          width={spacing.space[200]}
          height={`calc(100% - ${spacing.space[400]})`}
          cursor="ew-resize"
          onMouseDown={(e) => handleResizeMouseDown(e, "e")}
        />
        <Box
          position="absolute"
          left={0}
          top={spacing.space[200]}
          width={spacing.space[200]}
          height={`calc(100% - ${spacing.space[400]})`}
          cursor="ew-resize"
          onMouseDown={(e) => handleResizeMouseDown(e, "w")}
        />
        <Box
          position="absolute"
          bottom={0}
          left={spacing.space[200]}
          width={`calc(100% - ${spacing.space[400]})`}
          height={spacing.space[200]}
          cursor="ns-resize"
          onMouseDown={(e) => handleResizeMouseDown(e, "s")}
        />
        <Box
          position="absolute"
          top={0}
          left={spacing.space[200]}
          width={`calc(100% - ${spacing.space[400]})`}
          height={spacing.space[200]}
          cursor="ns-resize"
          onMouseDown={(e) => handleResizeMouseDown(e, "n")}
        />
      </Box>
    </Portal>
  );
};
