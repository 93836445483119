import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { useToast } from "@design-system";
import { useTranslation } from "@hooks";
import { ColumnCurve } from "@models/project";
import { useCallback } from "react";

function fromModel(curve: Omit<ColumnCurve, "id"> & { id?: ColumnCurve["id"] }) {
  return {
    id: curve.id,
    name: curve.name,
    point: curve.point,
    attributes: curve.attributes,
  };
}

export function useBackendColumnAnchor() {
  const { headers } = useAuth();
  const { t } = useTranslation("hooks.columnAnchors");
  const toast = useToast();

  const createBackendColumnAnchor = useCallback(
    async (projectId: string, modelId: string, columnAnchor: Omit<ColumnCurve, "id">) => {
      const request = {
        headers,
        params: {
          projectId,
          modelId,
        },
        body: fromModel(columnAnchor),
      };

      try {
        return await BackendApi.createColumnAnchor(request);
      } catch (error) {
        console.log({ message: "Failed to upload column anchors", error, project: request.body });
        toast({
          title: t("create.toast.status.error.title"),
          children: t("create.toast.status.error.description"),
          variant: "error",
        });
        throw error;
      }
    },
    [headers, t, toast],
  );

  const updateBackendColumnAnchors = useCallback(
    async (
      projectId: string,
      modelId: string,
      columnAnchors: (Omit<ColumnCurve, "id"> & { id?: ColumnCurve["id"] })[],
    ) => {
      const anchors = columnAnchors.map((columnAnchor) => fromModel(columnAnchor));

      const request = {
        headers,
        params: {
          projectId,
          modelId,
        },
        body: {
          anchors,
        },
      };

      try {
        return await BackendApi.updateColumnAnchors(request);
      } catch (error) {
        console.log({ message: "Failed to upload column anchor", error, project: request.body });
        toast({
          title: t("create.toast.status.error.title"),
          children: t("create.toast.status.error.description"),
          variant: "error",
        });
        throw error;
      }
    },
    [headers, t, toast],
  );

  const updateBackendColumnAnchor = useCallback(
    async (projectId: string, modelId: string, columnAnchor: ColumnCurve) => {
      if (columnAnchor.id === undefined) {
        return null;
      }
      const request = {
        headers,
        params: {
          projectId: projectId,
          modelId: modelId,
          id: columnAnchor.id,
        },
        body: fromModel(columnAnchor),
      };

      try {
        await BackendApi.updateColumnAnchor(request);
      } catch (error) {
        console.log({ message: "Failed to update column anchor", error, project: request.body });
        toast({
          title: t("update.toast.status.error.title"),
          children: t("update.toast.status.error.description"),
          variant: "error",
        });
      }
    },
    [headers, t, toast],
  );

  const deleteBackendColumnAnchor = useCallback(
    async (projectId: string, modelId: string, columnAnchorId: string) => {
      const request = {
        headers,
        params: {
          projectId: projectId,
          modelId: modelId,
          id: columnAnchorId,
        },
      };

      try {
        await BackendApi.deleteColumnAnchor(request);
      } catch (error) {
        console.log({ message: "Failed to delete column anchor", error, request });
        toast({
          title: t("delete.toast.status.error.title"),
          children: t("delete.toast.status.error.description"),
          variant: "error",
        });
      }
    },
    [headers, t, toast],
  );

  return {
    createBackendColumnAnchor,
    deleteBackendColumnAnchor,
    updateBackendColumnAnchor,
    updateBackendColumnAnchors,
  };
}
