import { trackBitmapExported } from "@/events";
import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { useCurrentProject } from "@fragments/project/container/useCurrentProject";
import { useProgressToast, useTranslation } from "@hooks";
import { KnitStructure, PostprocessorDocument } from "@models/backend";
import { ColumnCurve, Model, PathCollection, SectionCurve } from "@models/project";
import { captureException } from "@sentry/react";
import { useModelsStore } from "@state/models";
import { recordToArray } from "@utils/project/collection";
import knitProcess from "@utils/project/knitProcess";
import { sortPathCollections } from "@utils/project/pathCollections";
import { saveAs } from "file-saver";
import { useCallback, useMemo } from "react";
import { useProcessLoader } from "./useProcessLoader";

const MAX_STEPS = 60;
function sleep(millis: number) {
  return new Promise((resolve) => setTimeout(resolve, millis));
}

type useModelExporterProps = {
  refresh?: boolean;
};

export function useModelExporter({ refresh = false }: useModelExporterProps) {
  const { columns, pathCollections, sections, knitStructures, models, postprocessorDocuments } = useModelsStore(
    (s) => s,
  );
  const model: Model | undefined = models[0];
  const mode = refresh ? "refreshBMP" : "exportBMP";
  const { t } = useTranslation(`hooks.project.${mode}.progress`);
  const toast = useProgressToast();

  const {
    project: { id: projectId, organizationId },
  } = useCurrentProject();
  const { headers } = useAuth();

  const { setKnitMesh } = useProcessLoader();

  const exportBMP = useCallback(
    async (
      model: Model,
      guideSource: PathCollection,
      pathCollections: PathCollection[],
      sectionCurves: SectionCurve[],
      columnCurves: ColumnCurve[],
      defaultKnitStructure: KnitStructure,
      postprocessorDocument: PostprocessorDocument,
      binary?: boolean,
      debug?: boolean,
    ) => {
      const { update } = toast(t, { title: t("title"), status: "in-progress" });

      try {
        update({ status: "in-progress", message: t("status.in-progress.knitProcess") });

        const content = await knitProcess(
          model,
          guideSource,
          pathCollections,
          sectionCurves,
          columnCurves,
          defaultKnitStructure,
          postprocessorDocument,
          binary,
          debug,
        );

        update({ status: "in-progress", message: t("status.in-progress.createProcess") });

        let result = await BackendApi.createProcess({
          content,
          onProgress: ({ percentage }: { loaded: number; total: number; percentage: number }) =>
            update({
              status: "in-progress",
              progress: percentage * 0.3,
              message: t("status.in-progress.createProcess"),
            }),
          params: {
            projectId,
          },
          headers,
        });

        let i = 0;
        const id = result.id;
        update({ status: "in-progress", progress: 30, message: t("status.in-progress.getProcess") });

        do {
          await sleep(1000);

          result = await BackendApi.getProcess({
            params: {
              projectId,
              id,
            },
            headers,
          });

          if (result.status !== "ready" && result.status !== "processing") {
            break;
          }

          i += 1;
          update({
            status: "in-progress",
            progress: 30 + (i * 70) / MAX_STEPS,
            message: t("status.in-progress.getProcess"),
          });
        } while (i < MAX_STEPS);

        if (result.status !== "done") {
          throw new Error(`Process failed with status: ${result.status}, message: ${result.info?.message}`);
        }

        update({ status: "in-progress", progress: 99, message: t("status.in-progress.saveAs") });

        const url = result.output?.bmpUrl;
        if (!url) {
          throw new Error("BMP URL not found in process output");
        }

        if (!refresh) saveAs(url, "output.bmp");

        trackBitmapExported({
          organizationId,
          projectId,
          size: content.size,
          status: "success",
        });

        setKnitMesh(result);

        update({ status: "success" });

        return result;
      } catch (error) {
        captureException(error, {
          extra: {
            projectId,
            binary,
            debug,
          },
        });

        trackBitmapExported({
          organizationId,
          projectId,
          status: "failed",
          error: error instanceof Error ? error.message : String(error),
        });

        update({
          title: t("status.error.title"),
          status: "error",
          message: t("status.error.knitProcess", {
            message: error instanceof Error ? error.message : String(error),
          }),
        });
      }
    },
    [toast, t, projectId, headers],
  );

  const isExportModelDisabled = useMemo(() => {
    if (!model) {
      return true;
    }

    const pathCollectionsArray = recordToArray(pathCollections[model.id]?.collections);
    if (!pathCollectionsArray?.length) {
      return true;
    }

    const guideSource = pathCollectionsArray.find(({ usage }) => usage === "guide");

    return !guideSource?.points?.length;
  }, [model, pathCollections]);

  const exportModel = useCallback(
    (debug = false) => {
      if (!model) {
        return;
      }

      const defaultKnitStructure = knitStructures.find(({ isDefault }) => isDefault);
      const postprocessorDocument =
        postprocessorDocuments.find(
          (document) => document.id === model.attributes.postprocessorDocument?.postprocessorDocumentId,
        ) ?? postprocessorDocuments.find(({ system }) => system);

      if (!defaultKnitStructure || !postprocessorDocument) {
        console.error("default knit structure or post-processor document not found");
        return;
      }

      const pathCollectionsArray = sortPathCollections(
        recordToArray(pathCollections[model.id].collections),
        model["zoneOrder"],
      );
      const guideSource = pathCollectionsArray.find(({ usage }) => usage === "guide");

      if (!guideSource) {
        return;
      }

      const filteredPathCollectionsArray = pathCollectionsArray.filter(
        ({ usage }) => usage === "zone" || usage === "goring",
      );
      const sectionCurves = sections[model.id].sectionCurves;
      const columnCurves = columns[model.id].columnCurves;

      return exportBMP(
        model,
        guideSource,
        filteredPathCollectionsArray,
        sectionCurves,
        columnCurves,
        defaultKnitStructure,
        postprocessorDocument,
        true,
        debug,
      ).catch();
    },
    [exportBMP, model, columns, pathCollections, sections, knitStructures, postprocessorDocuments],
  );
  return { exportModel, isExportModelDisabled };
}
