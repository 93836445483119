import { fromBackendPathCollection, useProgressToast, useTranslation } from "@hooks";
import { useModelLoader } from "@hooks/project";
import { Project } from "@models/backend";
import { useModelsStore, useProjectState, useTemporalModelsStore } from "@state";
import { useEffect } from "react";
import { useProcessLoader } from "./useProcessLoader";

type UseSavedModelProps = {
  project: Project;
  triggerUploadModelModal: () => void;
};

export function useLoadProject({ project, triggerUploadModelModal }: UseSavedModelProps) {
  const {
    clear: clearModels,
    pushPathCollections,
    setColumnCurves,
    setSectionCurves,
    setColorways,
    setYarnTokens,
  } = useModelsStore(
    ({ clear, setColumnCurves, setSectionCurves, pushPathCollections, setYarnTokens, setColorways }) => ({
      clear,
      pushPathCollections,
      setColumnCurves,
      setSectionCurves,
      setColorways,
      setYarnTokens,
    }),
  );
  const { t } = useTranslation("hooks.models.load.progress");
  const toast = useProgressToast();
  const { loadModel } = useModelLoader();
  const { loadLastProcess } = useProcessLoader();
  const { _3DToolbarSelection, set3DToolbarSelection, renderModeSelection, setRenderModeSelection } = useProjectState();

  const { clear: clearTemporalStore } = useTemporalModelsStore();

  const loadProject = async (project: Project) => {
    if (_3DToolbarSelection !== "select") {
      set3DToolbarSelection("select");
    }

    if (renderModeSelection !== "design") {
      setRenderModeSelection("design");
    }

    clearModels();

    if (project.yarnTokens) {
      setYarnTokens(project.yarnTokens);
    }

    if (project.colorways) {
      setColorways(project.colorways);
    }

    for (const backendModel of project.models) {
      const { update } = toast(t, { title: backendModel.name, status: "in-progress" });

      try {
        const model = await loadModel(backendModel);

        if (!backendModel.parentId) {
          update({
            message: t(`status.in-progress.path-collections`),
            status: "in-progress",
          });

          pushPathCollections(
            model.id,
            backendModel.pathCollections.map((pathCollection) => fromBackendPathCollection(model, pathCollection)),
          );

          if (backendModel.columnAnchors?.length) {
            setColumnCurves(
              model.id,
              backendModel.columnAnchors?.map((curve, index) => ({
                ...curve,
                name: `Column ${index + 1}`,
              })),
            );
          }

          if (backendModel.sectionAnchors?.length) {
            setSectionCurves(
              model.id,
              backendModel.sectionAnchors?.map((curve, index) => ({
                ...curve,
                name: `Section ${index + 1}`,
              })),
            );
          }

          loadLastProcess(project.id).catch((e) => console.log("Error loading last process", e));
        }

        update({ status: "success" });
      } catch (error) {
        console.error("Error loading backend model", backendModel, error);

        update({
          status: "error",
          message: t("status.error.loadFailed"),
        });

        if (!backendModel.parentId && triggerUploadModelModal) {
          triggerUploadModelModal();
        }
      }
    }

    if (!project.models?.length && triggerUploadModelModal) {
      triggerUploadModelModal();
    }

    clearTemporalStore();
  };

  useEffect(() => {
    loadProject(project).catch((e) => console.warn("Error loading project", e));
  }, [project]);
}
