import { AuthHeaders, OrganizationMember, User } from "@auth";
import {
  Colorway,
  ColumnAnchor,
  CommentThread,
  KnitStructure,
  Machine,
  Model,
  Path,
  PathCollection,
  PostprocessorDocument,
  Process,
  Project,
  ProjectComment,
  SectionAnchor,
  ThreadState,
  Yarn,
  YarnToken,
  YarnTokenValue,
} from "@models/backend";
import { Vector3 } from "@variant-tech/pattern-derivation";
import { env } from "../config";
import FetchBackendApi from "./FetchBackendApi";
import { MockBackendApi } from "./mock";

type Body<T> = { body: T };
type Params<T> = { params: T };

type Id = { id: string };
type MemberId = { memberId: string };
type OrganizationId = { organizationId: string };
type ProjectId = { projectId: string };
type ModelId = { modelId: string };
type ColorwayId = { colorwayId: string };
type YarnTokenId = { yarnTokenId: string };
type SectionAnchors = { anchors: (Omit<SectionAnchor, "id"> & { id?: SectionAnchor["id"] })[] };
type ColumnAnchors = { anchors: (Omit<ColumnAnchor, "id"> & { id?: ColumnAnchor["id"] })[] };

type PathCollectionCreateInput = Omit<PathCollection, "id" | "paths"> & { paths: Omit<Path, "id">[] };

export interface Upload<T extends Blob = Blob> {
  content: T;
  onProgress?: (props: { loaded: number; total: number; percentage: number }) => void;
}

export type CreateProjectRequest = AuthHeaders & Body<Partial<Project> & { name: Project["name"] }>;
export type DeleteProjectRequest = AuthHeaders & Params<Id>;
export type GetProjectRequest = AuthHeaders & Params<Id>;
export type GetProjectsRequest = AuthHeaders & Params<Partial<OrganizationId>>;
export type Projects = { projects: Project[] };
export type AddProjectModelRequest = AuthHeaders & Params<ProjectId> & Body<Partial<Model>> & Upload<File>;
export type DecimateProjectModelRequest = AuthHeaders & Params<ProjectId & Id> & Body<Partial<Model>> & Upload<File>;
export type PatchProjectModelRequest = AuthHeaders &
  Params<ProjectId & Id> &
  Body<Partial<Pick<Model, "unit" | "attributes" | "name">>>;
export type DeleteProjectModelRequest = AuthHeaders & Params<ProjectId & Id>;
export type UpdateProjectThumbnailRequest = AuthHeaders & Params<ProjectId> & Upload;
export type CreatePathCollectionRequest = AuthHeaders & Params<ProjectId & ModelId> & Body<PathCollectionCreateInput>;
export type UpdatePathCollectionRequest = AuthHeaders &
  Params<ProjectId & ModelId & Id> &
  Body<Partial<PathCollection>>;
export type UpdatePathCollectionsRequest = AuthHeaders & Params<ProjectId & ModelId> & Body<PathCollection[]>;
export type DeletePathCollectionRequest = AuthHeaders & Params<ProjectId & ModelId & Id>;

export type CreateSectionAnchorRequest = AuthHeaders & Params<ProjectId & ModelId> & Body<Omit<SectionAnchor, "id">>;
export type UpdateSectionAnchorRequest = AuthHeaders & Params<ProjectId & ModelId & Id> & Body<Partial<SectionAnchor>>;
export type UpdateSectionAnchorsRequest = AuthHeaders & Params<ProjectId & ModelId> & Body<SectionAnchors>;
export type DeleteSectionAnchorRequest = AuthHeaders & Params<ProjectId & ModelId & Id>;
export type DeleteSectionAnchorsRequest = AuthHeaders & Params<ProjectId & ModelId>;

export type CreateColumnAnchorRequest = AuthHeaders & Params<ProjectId & ModelId> & Body<Omit<ColumnAnchor, "id">>;
export type UpdateColumnAnchorRequest = AuthHeaders & Params<ProjectId & ModelId & Id> & Body<Partial<ColumnAnchor>>;
export type UpdateColumnAnchorsRequest = AuthHeaders & Params<ProjectId & ModelId> & Body<ColumnAnchors>;
export type DeleteColumnAnchorRequest = AuthHeaders & Params<ProjectId & ModelId & Id>;
export type DeleteColumnAnchorsRequest = AuthHeaders & Params<ProjectId & ModelId>;

export type GetOrganizationMembersRequest = AuthHeaders & Params<OrganizationId>;
export type AddOrganizationMemberRequest = AuthHeaders & Params<OrganizationId> & Body<Partial<OrganizationMember>>;
export type UpdateOrganizationMemberRequest = AuthHeaders &
  Params<OrganizationId & MemberId> &
  Body<Partial<OrganizationMember>>;
export type RemoveOrganizationMembersRequest = AuthHeaders &
  Params<OrganizationId> &
  Body<{ ids: OrganizationMember["id"][] }>;
export type OrganizationMembers = { members: OrganizationMember[] } & OrganizationId;

export type GetUserRequest = AuthHeaders;

export type CreateProcessRequest = AuthHeaders & Params<ProjectId> & Upload<File>;
export type GetProcessRequest = AuthHeaders & Params<ProjectId & Id>;
export type GetProcessesRequest = AuthHeaders & Params<ProjectId>;
export type PatchProjectRequest = AuthHeaders & Params<ProjectId> & Body<{ name?: string; fileShareUrl?: string }>;
export type GetKnitStructuresRequest = AuthHeaders;
export type GetMachinesRequest = AuthHeaders;
export type GetPostprocessorDocumentsRequest = AuthHeaders & Params<OrganizationId>;
export type CreatePostprocessorDocumentRequest = AuthHeaders &
  Body<{ name: string; organizationId: string; originalId: string; change?: Partial<PostprocessorDocument> }>;

export interface KnitStructuresResponse {
  success: boolean;
  result: {
    structures: KnitStructure[];
  };
}

export interface MachinesResponse {
  success: boolean;
  result: {
    machines: Machine[];
  };
}

export interface PostprocessorDocumentsResponse {
  success: boolean;
  postprocessorDocuments: PostprocessorDocument[];
}

export type TrackRequest = {
  event: string;
  properties: Record<string, unknown> | undefined;
  userId: string | undefined;
  traits: Record<string, unknown> | undefined;
  createdAt?: Date;
};

export type PatchPostprocessorDocumentNameRequest = AuthHeaders &
  Params<{ postprocessorDocumentId: string }> &
  Body<{
    name: string;
  }>;

export type PatchPostprocessorDocumentColorEntryRequest = AuthHeaders &
  Params<{
    postprocessorDocumentId: string;
    structureId: string;
  }> &
  Body<{
    colorCodeId: number;
  }>;

export type CreateThreadRequest = AuthHeaders &
  Params<ProjectId> &
  Body<{
    content: string;
    position: Vector3;
    state?: ThreadState;
    layerId?: string;
  }>;

export type AddCommentRequest = AuthHeaders &
  Params<ProjectId & { threadId: string }> &
  Body<{
    content: string;
  }>;

export type UpdateCommentRequest = AuthHeaders &
  Params<ProjectId & { threadId: string; commentId: string }> &
  Body<{
    content: string;
  }>;

export type DeleteCommentRequest = AuthHeaders & Params<ProjectId & { threadId: string; commentId: string }>;

export type GetThreadsRequest = AuthHeaders & Params<ProjectId>;
export type GetThreadRequest = AuthHeaders & Params<ProjectId & { threadId: string }>;
export type UpdateThreadRequest = AuthHeaders & Params<ProjectId & { threadId: string }> & Body<{ state: ThreadState }>;

export type GetYarnsRequest = AuthHeaders & Params<OrganizationId>;
export type UpdateYarnsRequest = AuthHeaders &
  Params<OrganizationId> &
  Body<{ Upsert: Omit<Partial<Yarn>, "createdAt" | "updatedAt" | "member">[]; Delete: Yarn["id"][] }>;
export type GetYarnRequest = AuthHeaders & Params<OrganizationId & Id>;
export type CreateYarnRequest = AuthHeaders &
  Params<OrganizationId> &
  Body<Omit<Yarn, "id" | "createdAt" | "updatedAt" | "member">>;
export type UpdateYarnRequest = AuthHeaders &
  Params<OrganizationId & Id> &
  Body<Partial<Omit<Yarn, "id" | "createdAt" | "updatedAt" | "member">>>;
export type DeleteYarnRequest = AuthHeaders & Params<OrganizationId & Id>;

export type UpdateYarnTokensRequest = AuthHeaders & Params<ProjectId> & Body<{ yarnTokens: YarnToken[] }>;
export type CreateYarnTokenRequest = AuthHeaders & Params<ProjectId> & Body<Omit<YarnToken, "id">>;
export type UpdateYarnTokenRequest = AuthHeaders & Params<ProjectId & Id> & Body<Partial<Omit<YarnToken, "id">>>;
export type DeleteYarnTokenRequest = AuthHeaders & Params<ProjectId & Id>;

export type UpdateColorwaysRequest = AuthHeaders & Params<ProjectId> & Body<{ colorways: Colorway[] }>;
export type CreateColorwayRequest = AuthHeaders & Params<ProjectId> & Body<Omit<Colorway, "id" | "yarnTokens">>;
export type UpdateColorwayRequest = AuthHeaders &
  Params<ProjectId & Id> &
  Body<Partial<Omit<Colorway, "id" | "yarnTokens">>>;
export type DeleteColorwayRequest = AuthHeaders & Params<ProjectId & Id>;
export type UpdateColorwayYarnTokenRequest = AuthHeaders &
  Params<ProjectId & ColorwayId & YarnTokenId> &
  Body<Partial<Omit<YarnTokenValue, "id">>>;

export interface BackendApi {
  createProject(request: CreateProjectRequest): Promise<Project>;

  deleteProject(request: DeleteProjectRequest): Promise<void>;

  getProjects(request: GetProjectsRequest): Promise<Projects>;

  getProject(request: GetProjectRequest): Promise<Project>;

  addProjectModel(request: AddProjectModelRequest): Promise<Model>;

  decimateProjectModel(request: DecimateProjectModelRequest): Promise<Model>;

  patchProjectModel(request: PatchProjectModelRequest): Promise<Model>;

  deleteProjectModel(request: DeleteProjectModelRequest): Promise<void>;

  createProcess(request: CreateProcessRequest): Promise<Process>;

  getProcess(request: GetProcessRequest): Promise<Process>;

  getProcesses(request: GetProcessesRequest): Promise<Array<Process>>;

  replacePathCollections(request: UpdatePathCollectionsRequest): Promise<PathCollection[]>;

  updateProjectThumbnail(request: UpdateProjectThumbnailRequest): Promise<void>;

  createPathCollection(request: CreatePathCollectionRequest): Promise<PathCollection>;

  updatePathCollection(request: UpdatePathCollectionRequest): Promise<PathCollection>;

  updatePathCollections(request: UpdatePathCollectionsRequest): Promise<PathCollection[]>;

  deletePathCollection(request: DeletePathCollectionRequest): Promise<void>;

  createSectionAnchor(request: CreateSectionAnchorRequest): Promise<SectionAnchor>;

  updateSectionAnchors(request: UpdateSectionAnchorsRequest): Promise<SectionAnchor[]>;

  updateSectionAnchor(request: UpdateSectionAnchorRequest): Promise<SectionAnchor>;

  deleteSectionAnchor(request: DeleteSectionAnchorRequest): Promise<void>;

  createColumnAnchor(request: CreateColumnAnchorRequest): Promise<ColumnAnchor>;

  updateColumnAnchors(request: UpdateColumnAnchorsRequest): Promise<ColumnAnchor[]>;

  updateColumnAnchor(request: UpdateColumnAnchorRequest): Promise<ColumnAnchor>;

  deleteColumnAnchor(request: DeleteColumnAnchorRequest): Promise<void>;

  getOrganizationMembers(request: GetOrganizationMembersRequest): Promise<OrganizationMembers>;

  addOrganizationMember(request: AddOrganizationMemberRequest): Promise<OrganizationMember>;

  updateOrganizationMember(request: UpdateOrganizationMemberRequest): Promise<OrganizationMember>;

  removeOrganizationMembers(request: RemoveOrganizationMembersRequest): Promise<OrganizationMembers>;

  getUser(request: GetUserRequest): Promise<User>;

  patchProject(request: PatchProjectRequest): Promise<Project>;

  getKnitStructures(request: GetKnitStructuresRequest): Promise<KnitStructuresResponse>;

  getMachines(request: GetMachinesRequest): Promise<MachinesResponse>;

  getPostprocessorDocuments(request: GetPostprocessorDocumentsRequest): Promise<PostprocessorDocumentsResponse>;

  createPostprocessorDocument(request: CreatePostprocessorDocumentRequest): Promise<PostprocessorDocument>;

  patchPostprocessorDocumentName(request: PatchPostprocessorDocumentNameRequest): Promise<PostprocessorDocument>;

  patchPostprocessorDocumentColorEntry(
    request: PatchPostprocessorDocumentColorEntryRequest,
  ): Promise<PostprocessorDocument>;

  track<T extends TrackRequest>(request: T | T[]): Promise<void>;

  createThread(request: CreateThreadRequest): Promise<CommentThread>;

  addComment(request: AddCommentRequest): Promise<ProjectComment>;

  getThreads(request: GetThreadsRequest): Promise<CommentThread[]>;

  getThread(request: GetThreadRequest): Promise<CommentThread>;

  updateThread(request: UpdateThreadRequest): Promise<CommentThread>;

  updateComment(request: UpdateCommentRequest): Promise<ProjectComment>;

  deleteComment(request: DeleteCommentRequest): Promise<CommentThread>;

  getYarns(request: GetYarnsRequest): Promise<Yarn[]>;

  getYarn(request: GetYarnRequest): Promise<Yarn>;

  createYarn(request: CreateYarnRequest): Promise<Yarn>;

  updateYarn(request: UpdateYarnRequest): Promise<Yarn>;

  updateYarns(request: UpdateYarnsRequest): Promise<Yarn[]>;

  deleteYarn(request: DeleteYarnRequest): Promise<void>;

  createYarnToken(request: CreateYarnTokenRequest): Promise<YarnToken>;

  updateYarnToken(request: UpdateYarnTokenRequest): Promise<YarnToken>;

  updateYarnTokens(request: UpdateYarnTokensRequest): Promise<YarnToken[]>;

  deleteYarnToken(request: DeleteYarnTokenRequest): Promise<void>;

  createColorway(request: CreateColorwayRequest): Promise<Colorway>;

  updateColorway(request: UpdateColorwayRequest): Promise<Colorway>;

  updateColorways(request: UpdateColorwaysRequest): Promise<Colorway[]>;

  deleteColorway(request: DeleteColorwayRequest): Promise<void>;

  updateColorwayYarnToken(request: UpdateColorwayYarnTokenRequest): Promise<YarnTokenValue>;
}

const backendApi: BackendApi = env === "mock" ? MockBackendApi : FetchBackendApi;

export default backendApi;
