import BackendApi from "@api/BackendApi.ts";
import { useAuth } from "@auth";
import { analytics } from "@integrations/analytics";
import { Yarn } from "@models/backend";
import { useTemporalYarnsStore, useYarnsStore } from "@state";
import equal from "fast-deep-equal/es6";
import { omit } from "lodash";
import { useCallback, useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";

function diffYarns(previous: Record<string, Yarn>, next: Record<string, Yarn>) {
  const propertiesToIgnore = ["member", "updatedAt", "createdAt", "lastEditedBy", "organizationId"];
  const Delete: Yarn["id"][] = [];
  const Upsert: Partial<Yarn>[] = [];

  for (const id in next) {
    const element = previous[id];
    if (!element) {
      Upsert.push(omit(next[id], propertiesToIgnore));
    }
  }

  for (const id in previous) {
    const nextElement = next[id];

    if (!nextElement) {
      Delete.push(id);
    } else {
      const previousElement = previous[id];

      if (!equal(nextElement, previousElement)) {
        const record: Partial<Record<keyof Yarn, Yarn[keyof Yarn] | null>> = { id };

        for (const key in previousElement) {
          const property = key as keyof Yarn;

          if (propertiesToIgnore.indexOf(property) !== -1) {
            continue;
          }

          if (!equal(nextElement[property], previousElement[property])) {
            record[property] = nextElement[property] ?? null;
          }
        }

        Upsert.push(record as Partial<Yarn>);
      }
    }
  }

  return { Delete, Upsert };
}

export function useYarnUndoRedo(organizationId: string) {
  const { headers } = useAuth();
  const yarns = useYarnsStore(({ yarns }) => yarns);
  const { undo, redo, clear, pastStates, futureStates, pause, resume, isTracking } = useTemporalYarnsStore();

  const updateYarns = useCallback(
    async (previous: Record<Yarn["id"], Yarn>, next: Record<Yarn["id"], Yarn>) => {
      const body = diffYarns(previous, next);
      await BackendApi.updateYarns({ headers, params: { organizationId }, body });
      return body;
    },
    [headers, organizationId],
  );

  const handleUndo = useCallback(async () => {
    const pastState = pastStates[pastStates.length - 1];

    if (pastState) {
      await analytics.trackDurationAsync(
        "YARN_UNDO",
        async () => {
          undo();
          await updateYarns(yarns, pastState?.yarns ?? {});
        },
        { organizationId },
      );
    }
  }, [undo, pastStates, yarns, updateYarns, organizationId]);

  const handleRedo = useCallback(async () => {
    const futureState = futureStates[0];

    if (futureState) {
      await analytics.trackDurationAsync(
        "YARN_REDO",
        async () => {
          redo();
          await updateYarns(yarns, futureState?.yarns ?? {});
        },
        { organizationId },
      );
    }
  }, [redo, futureStates, yarns, updateYarns, organizationId]);

  useHotkeys(["ctrl+z", "cmd+z"], handleUndo);
  useHotkeys(["ctrl+y", "cmd+y"], handleRedo);

  useEffect(() => clear(), [organizationId, clear]);

  return {
    canUndo: pastStates.length > 0,
    canRedo: futureStates.length > 0,
    undo: handleUndo,
    redo: handleRedo,
    clear,
    pause,
    resume,
    isTracking,
  };
}
