import { Colorway, ProjectKnitStructure } from "@models/backend";
import { Texture } from "three";

/*
  DATA STRUCTURE for knitCellTexture:
  - KnitCell type {0, 1, 2, 3, 4, 5, 6, 7}
    0 - Front
    1 - Back
    2 - Double parallel
    3 - Double Interlock
    4 - Waste
    5 - Void
  - Yarn ID 0 {0, 1, 2, 3, .. , 15} 
  - Yarn ID 1 {0, 1, 2, 3, .. , 15}
  - Yarn ID 2 {0, 1, 2, 3, .. , 15}
  - Yarn ID 3 {0, 1, 2, 3, .. , 15}
  - Yarn ID 4 {0, 1, 2, 3, .. , 15}

  5 x 4 bits + 4 bits = 24 bits
*/

// KnitCellType Enum (4-bit values: 0-15)
enum KnitCellType {
  Front = 0,
  Back = 1,
  DoubleParallel = 2,
  DoubleInterlock = 3,
  Waste = 4,
  Void = 5,
}

// Structure to store the values before encoding
interface KnitData {
  type: KnitCellType; // 4 bits (0-15)
  yarn0: number; // 4 bits (0-15)
  yarn1: number; // 4 bits (0-15)
  yarn2: number; // 4 bits (0-15)
  yarn3: number; // 4 bits (0-15)
  yarn4: number; // 4 bits (0-15)
}

interface StructureKnitData {
  knitData: KnitData[];
  squareSize: number;
}

const NO_FLOAT = 15;

function decodeRGB(rgb: [number, number, number]): { isGoringLeft: boolean; isGoringRight: boolean; zone: number } {
  const r = Math.round(rgb[0] * 255); // Convert normalized [0,1] to [0,255]
  const g = Math.round(rgb[1] * 255); // Convert normalized [0,1] to [0,255]

  const isGoringLeft = (r & 2) !== 0; // Extract bit 1
  const isGoringRight = (r & 1) !== 0; // Extract bit 0
  const zone = g; // Green channel is the zone

  return { isGoringLeft, isGoringRight, zone };
}

function encodeKnitData(data: KnitData): [number, number, number, number] {
  const r = ((data.type & 0b1111) << 4) | (data.yarn0 & 0b1111); // 4 bits + 4 bits
  const g = ((data.yarn1 & 0b1111) << 4) | (data.yarn2 & 0b1111); // 4 bits + 4 bits
  const b = ((data.yarn3 & 0b1111) << 4) | (data.yarn4 & 0b1111); // 4 bits + 4 bits

  const a = 255; // Set full opacity

  // return the encoded values
  return [r, g, b, a];
}

function ribEncoder(x: number, structure: StructureKnitData) {
  let encodedPixel: [number, number, number, number];
  if ((x / structure.squareSize) % 2 === 0) {
    encodedPixel = encodeKnitData(structure.knitData[0]);
  } else {
    encodedPixel = encodeKnitData(structure.knitData[1]);
  }
  return encodedPixel;
}

function checkerboardEncoder(x: number, y: number, structure: StructureKnitData) {
  let encodedPixel: [number, number, number, number];
  if ((x / structure.squareSize + y / structure.squareSize) % 2 === 0) {
    encodedPixel = encodeKnitData(structure.knitData[0]);
  } else {
    encodedPixel = encodeKnitData(structure.knitData[1]);
  }
  return encodedPixel;
}

function mapYarnTokenIndexes(colorway: Colorway, knitStructures: ProjectKnitStructure[]): Array<number[]> {
  const result: Array<number[]> = [];

  knitStructures.forEach((structure, structureIndex) => {
    const indexes: number[] = [];
    if (structure.yarnTokenIds) {
      for (const yarnTokenId of structure.yarnTokenIds) {
        const index = colorway.yarnTokens.findIndex((token) => token.id === yarnTokenId);
        indexes.push(index); // will be -1 if not found, which is valid and should be handled downstream
      }
    }
    result[structureIndex] = indexes;
  });

  return result;
}

/**
 * Creates a stand-in pattern on a new texture
 * @param originalTexture The original texture to modify.
 * @param columnWidth The width of each alternating column in pixels.
 * @returns A new Three.js Texture with selective column overlay.
 */
export function structureGenerator(
  originalTexture: Texture,
  zoneStructures: ProjectKnitStructure[],
  colorway: Colorway,
): Texture {
  const image = originalTexture.image as HTMLImageElement;

  if (!image) {
    console.error("Original texture has no image.");
    return originalTexture;
  }

  // Create a new canvas with the same dimensions as the original texture
  const canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    console.error("Failed to get 2D context.");
    return originalTexture;
  }

  // Draw the original texture as the base
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

  // Extract pixel data
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const pixelData = imageData.data; // Uint8ClampedArray containing RGBA pixel data
  const structureTokenIndexMap = mapYarnTokenIndexes(colorway, zoneStructures);

  for (let y = 0; y < canvas.height; y++) {
    for (let x = 0; x < canvas.width; x++) {
      const index = (y * canvas.width + x) * 4; // RGBA index for current pixel

      const r = pixelData[index] / 255; // Normalize to [0,1]
      const g = pixelData[index + 1] / 255;
      const b = pixelData[index + 2] / 255;

      const { zone } = decodeRGB([r, g, b]);

      let entry;
      const yarns = [0, NO_FLOAT, NO_FLOAT, NO_FLOAT, NO_FLOAT];

      if (zone - 1 < zoneStructures.length) {
        if (zoneStructures[zone - 1] && structureTokenIndexMap[zone - 1]) {
          entry = structureTokenIndexMap[zone - 1];
        }

        if (entry) {
          entry.map((yarnIndex, entryIndex) => (yarns[entryIndex] = yarnIndex));
        }

        const structureData: StructureKnitData = {
          knitData: [
            {
              type: KnitCellType.Front,
              yarn0: yarns[0],
              yarn1: yarns[1],
              yarn2: yarns[2],
              yarn3: yarns[3],
              yarn4: yarns[4],
            },
            {
              type: KnitCellType.Back,
              yarn0: yarns[0],
              yarn1: yarns[1],
              yarn2: yarns[2],
              yarn3: yarns[3],
              yarn4: yarns[4],
            },
          ],
          squareSize: 1,
        };

        const encodedPixel = encodeKnitData(structureData.knitData[0]);
        pixelData[index] = encodedPixel[0]; // Red
        pixelData[index + 1] = encodedPixel[1]; // Green
        pixelData[index + 2] = encodedPixel[2]; // Blue
        pixelData[index + 3] = encodedPixel[3]; // Alpha

        if (zoneStructures[zone - 1]?.name == "Checker 1x1") {
          const checkerboardStructure: StructureKnitData = {
            knitData: [
              {
                type: KnitCellType.Front,
                yarn0: yarns[0],
                yarn1: yarns[1],
                yarn2: NO_FLOAT,
                yarn3: NO_FLOAT,
                yarn4: NO_FLOAT,
              },
              {
                type: KnitCellType.Front,
                yarn0: yarns[1],
                yarn1: yarns[0],
                yarn2: NO_FLOAT,
                yarn3: NO_FLOAT,
                yarn4: NO_FLOAT,
              },
            ],
            squareSize: 1,
          };
          const encodedPixel = checkerboardEncoder(x, y, checkerboardStructure);
          pixelData[index] = encodedPixel[0]; // Red
          pixelData[index + 1] = encodedPixel[1]; // Green
          pixelData[index + 2] = encodedPixel[2]; // Blue
          pixelData[index + 3] = encodedPixel[3]; // Alpha
        }

        if (zoneStructures[zone - 1]?.name == "Vertical Ribbing 1x1") {
          const ribStructure: StructureKnitData = {
            knitData: [
              {
                type: KnitCellType.Front,
                yarn0: yarns[0],
                yarn1: NO_FLOAT,
                yarn2: NO_FLOAT,
                yarn3: NO_FLOAT,
                yarn4: NO_FLOAT,
              },
              {
                type: KnitCellType.Back,
                yarn0: yarns[0],
                yarn1: NO_FLOAT,
                yarn2: NO_FLOAT,
                yarn3: NO_FLOAT,
                yarn4: NO_FLOAT,
              },
            ],
            squareSize: 1,
          };

          const encodedPixel = ribEncoder(x, ribStructure);
          pixelData[index] = encodedPixel[0]; // Red
          pixelData[index + 1] = encodedPixel[1]; // Green
          pixelData[index + 2] = encodedPixel[2]; // Blue
          pixelData[index + 3] = encodedPixel[3]; // Alpha
        }

        // if (zoneStructures[zone - 1]?.name == "Transfer Mesh 1x1") {
        //   const encodedPixel = checkerboardEncoder(x, y, checkerboardStructure2);
        //   pixelData[index] = encodedPixel[0]; // Red
        //   pixelData[index + 1] = encodedPixel[1]; // Green
        //   pixelData[index + 2] = encodedPixel[2]; // Blue
        //   pixelData[index + 3] = encodedPixel[3]; // Alpha
        // }

        // if (zoneStructures[zone - 1]?.name == "Transfer Mesh 2x1") {
        //   const encodedPixel = checkerboardEncoder(x, y, checkerboardStructure3);
        //   pixelData[index] = encodedPixel[0]; // Red
        //   pixelData[index + 1] = encodedPixel[1]; // Green
        //   pixelData[index + 2] = encodedPixel[2]; // Blue
        //   pixelData[index + 3] = encodedPixel[3]; // Alpha
        // }

        // if (zoneStructures[zone - 1]?.name == "Ribbing 1x1") {
        //   const encodedPixel = ribEncoder(x);
        //   pixelData[index] = encodedPixel[0]; // Red
        //   pixelData[index + 1] = encodedPixel[1]; // Green
        //   pixelData[index + 2] = encodedPixel[2]; // Blue
        //   pixelData[index + 3] = encodedPixel[3]; // Alpha
        // }
      }
    }
  }

  // Apply modified pixels back to the canvas
  ctx.putImageData(imageData, 0, 0);

  // Create a new texture from the modified canvas
  const newTexture = new Texture(canvas);
  newTexture.needsUpdate = true; // Ensure Three.js updates the texture

  return newTexture;
}

// moving these here for posterity
// const checkerboardStructure2: StructureKnitData = {
//   knitData: [
//     {
//       type: KnitCellType.Front,
//       yarn0: 3,
//       yarn1: 4,
//       yarn2: NO_FLOAT,
//       yarn3: NO_FLOAT,
//       yarn4: NO_FLOAT,
//     },
//     {
//       type: KnitCellType.Front,
//       yarn0: 4,
//       yarn1: 3,
//       yarn2: NO_FLOAT,
//       yarn3: NO_FLOAT,
//       yarn4: NO_FLOAT,
//     },
//   ],
//   squareSize: 1,
// };

// const checkerboardStructure3: StructureKnitData = {
//   knitData: [
//     {
//       type: KnitCellType.Front,
//       yarn0: 5,
//       yarn1: 6,
//       yarn2: NO_FLOAT,
//       yarn3: NO_FLOAT,
//       yarn4: NO_FLOAT,
//     },
//     {
//       type: KnitCellType.Back,
//       yarn0: 6,
//       yarn1: 5,
//       yarn2: NO_FLOAT,
//       yarn3: NO_FLOAT,
//       yarn4: NO_FLOAT,
//     },
//   ],
//   squareSize: 1,
// };
