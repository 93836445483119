import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import {
  fromBackendPathCollection,
  useBackendColumnAnchor,
  useBackendPathCollection,
  useBackendSectionAnchor,
} from "@hooks";
import { useBackendCommentThread } from "@hooks/backend-api/useBackendCommentThread";
import { ModelAttributes } from "@models/backend";
import { Layer, Model, ModelLayers } from "@models/project";
import { pick } from "lodash";
import { useCallback } from "react";

export function useBackendModel() {
  const { headers } = useAuth();
  const { replaceBackendPathCollections } = useBackendPathCollection();
  const { updateBackendSectionAnchors } = useBackendSectionAnchor();
  const { updateBackendColumnAnchors } = useBackendColumnAnchor();
  const { updateBackendCommentThreads } = useBackendCommentThread();

  const updateBackendModel = useCallback(
    async (projectId: string, modelId: string, data: Partial<Model>) => {
      try {
        return await BackendApi.patchProjectModel({
          headers,
          params: { projectId, id: modelId },
          body: pick(data, ["name", "unit", "attributes"]),
        });
      } catch (error) {
        console.error("Failed to update backend model:", error);
        throw error;
      }
    },
    [headers],
  );

  const updateBackendModelLayers = useCallback(
    async (
      projectId: string,
      model: Layer<ModelAttributes>,
      { pathCollections, columnCurves, sectionCurves, commentThreads }: ModelLayers,
    ): Promise<ModelLayers> => {
      try {
        const [updatedColumnCurves, updatedPathCollections, updatedSectionCurves, updatedCommentThreads] =
          await Promise.all([
            updateBackendColumnAnchors(projectId, model.id, columnCurves),
            replaceBackendPathCollections(projectId, model.id, pathCollections),
            updateBackendSectionAnchors(projectId, model.id, sectionCurves),
            updateBackendCommentThreads(projectId, commentThreads),
          ]);

        return {
          columnCurves: updatedColumnCurves,
          commentThreads: updatedCommentThreads,
          pathCollections: updatedPathCollections.map((p) => fromBackendPathCollection(model, p)),
          sectionCurves: updatedSectionCurves,
        };
      } catch (error) {
        console.error("Failed to update model:", error);
        throw error;
      }
    },
    [headers],
  );

  return { updateBackendModel, updateBackendModelLayers };
}
