import { ToggleIconDropdown, spacing } from "@design-system";
import { ColorwaysIcon } from "./icons";
import { useEffect } from "react";
import { useTranslation } from "@hooks";
import { useColorways } from "@hooks/project/useColorways";
import { useCurrentProject } from "@fragments/project/container/useCurrentProject";
import { Colorway } from "@models/backend";
import { Box, Text, useDisclosure } from "@chakra-ui/react";
import { useSemanticTokens } from "@design-system/hooks/useSemanticTokens";
import { useModelsStore } from "@state";

function ColorwayOption({
  colorway,
  onClose,
  selectedColorwayId,
  setSelectedColorwayId,
}: {
  colorway: Colorway;
  onClose: () => void;
  selectedColorwayId: string;
  setSelectedColorwayId: (id: string) => void;
}) {
  const semanticTokens = useSemanticTokens();
  const isSelected = selectedColorwayId === colorway.id;

  return (
    <Box
      width="100%"
      px={spacing.space[400]}
      py={spacing.space[300]}
      w="140px"
      height="40px"
      backgroundColor={isSelected ? semanticTokens.surface.tertiary : "transparent"}
      cursor="pointer"
      onClick={() => {
        setSelectedColorwayId(colorway.id);
        onClose();
      }}
      _hover={{
        backgroundColor: !isSelected ? semanticTokens.surface.secondary : undefined,
      }}
    >
      <Text
        color={semanticTokens.text.primary}
        fontSize="sm"
        fontWeight={isSelected ? "medium" : "normal"}
        isTruncated
        maxWidth="100%"
      >
        {colorway.name}
      </Text>
    </Box>
  );
}

export function ColorwaysDropdown() {
  const { t } = useTranslation("project3D.colorways");
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const { project } = useCurrentProject();
  const { colorways } = useColorways({ projectId: project?.id });
  const semanticTokens = useSemanticTokens();

  const selectedColorwayId = useModelsStore((state) => state.selectedColorwayId);
  const setSelectedColorwayId = useModelsStore((state) => {
    if (!state.setSelectedColorwayId) {
      console.error("setSelectedColorwayId function is missing from the store");
      return (id: string) => console.warn("Unable to set colorway ID:", id);
    }
    return state.setSelectedColorwayId;
  });

  useEffect(() => {
    if (colorways.length > 0 && !selectedColorwayId) {
      setSelectedColorwayId(colorways[0].id);
    }
  }, [colorways, selectedColorwayId, setSelectedColorwayId]);

  const selectedColorway = colorways.find((c) => c.id === selectedColorwayId);
  const displayName = selectedColorway?.name
    ? selectedColorway.name.length > 15
      ? `${selectedColorway.name.substring(0, 12)}...`
      : selectedColorway.name
    : t("label");

  if (!colorways.length) {
    return null;
  }

  return (
    <ToggleIconDropdown
      isSelected={isOpen}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onToggle={onToggle}
      icon={<ColorwaysIcon />}
      label={displayName}
      variant="inverse"
      placement={"bottom-end"}
      menuListStyleProps={{
        minWidth: "100px",
        width: "auto",
        padding: spacing.space[50],
        bg: semanticTokens.surface.classic.secondary,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        border: "none",
        zIndex: 11,
      }}
      buttonStyleProps={{
        width: "44px",
        height: "44px",
        padding: 0,
        borderRadius: 0,
        _hover: { bg: "transparent" },
      }}
    >
      <Box width="100%">
        {colorways.map((colorway) => (
          <ColorwayOption
            key={colorway.id}
            colorway={colorway}
            onClose={onClose}
            selectedColorwayId={selectedColorwayId}
            setSelectedColorwayId={setSelectedColorwayId}
          />
        ))}
      </Box>
    </ToggleIconDropdown>
  );
}
