import { Model as ProjectModel, Unit, Units } from "@models/backend";
import { Model } from "@models/project";
import { useModelsStore } from "@state/models";
import { importMesh } from "@utils/project/mesh";
import { useCallback } from "react";
import { v4 } from "uuid";

export function useModelLoader() {
  const { addModel, addReferenceModel, models } = useModelsStore(({ addModel, addReferenceModel, models }) => ({
    addModel,
    addReferenceModel,
    models,
  }));

  const loadModel = useCallback(
    async function (projectModel: ProjectModel) {
      const {
        id,
        url,
        name,
        filename: fileName,
        decimatedUrl,
        zoneOrder,
        unit: bcUnit,
        attributes,
        parentId,
      } = projectModel;
      const loaderUrl = decimatedUrl ?? url;
      const filename = decimatedUrl ? ".glb" : fileName;
      const flipNormals = attributes?.normals?.flipNormals ?? false;

      function getModelUnit(): Unit {
        if (parentId) {
          const parentModel = models.find((model) => model.id === parentId);
          if (parentModel) {
            return parentModel.unit;
          }
        }
        return bcUnit ?? "cm";
      }

      const unit = getModelUnit();
      const scale = Units[unit];
      const mesh3DBase = await importMesh(loaderUrl, filename, { flipNormal: flipNormals ?? false });

      const model: Model = {
        id,
        name: name ?? fileName.substring(0, fileName.lastIndexOf(".")),
        mesh3DBase,
        unit,
        parentId,
        zoneOrder,
        scale,
        attributes: attributes ?? {},
        references: [],
      };

      if (parentId) {
        addReferenceModel(parentId, model);
      } else {
        await addModel(model);
      }

      return model;
    },
    [addReferenceModel, addModel, models],
  );

  const getDefaultModel = useCallback(
    async function () {
      const path = "/models/default_standardized.obj";
      const filename = path.split("/").pop() as string;

      const model: ProjectModel = {
        id: v4(),
        url: path,
        filename,
        name: "default_standardized.obj",
        pathCollections: [],
        sectionAnchors: [],
        columnAnchors: [],
      };
      await loadModel(model);
    },
    [loadModel],
  );

  return { getDefaultModel, loadModel };
}
