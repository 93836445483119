import { SectionCurve, ColumnCurve } from "@models/project";
import { GetState, SetState } from "zustand";
import { ModelsStore } from "../types";

export const sectionsSlice = (set: SetState<ModelsStore>, get: GetState<ModelsStore>) => ({
  sections: {},

  nextSectionNumber: (modelId: string) => {
    return (get().sections[modelId]?.sectionCurves?.length ?? 0) + 1;
  },

  addSectionCurve: (modelId: string, sectionCurve: SectionCurve) => {
    set(({ sections }: ModelsStore) => {
      const modelSectionCurves = sections[modelId]?.sectionCurves || [];
      const changedSections = [...modelSectionCurves, sectionCurve];
      return {
        sections: {
          ...sections,
          [modelId]: {
            sectionCurves: changedSections,
          },
        },
      };
    });
  },
  removeSectionCurve: (modelId: string, id: string) => {
    set(({ sections }: ModelsStore) => {
      const modelSectionCurves = sections[modelId].sectionCurves;
      const changedSections = modelSectionCurves.filter((sectionCurve) => sectionCurve.id !== id);
      return {
        sections: {
          ...sections,
          [modelId]: {
            sectionCurves: changedSections,
          },
        },
      };
    });
  },
  setSectionCurves: (modelId: string, sectionCurves: SectionCurve[]) => {
    set(({ sections }) => {
      return {
        sections: {
          ...sections,
          [modelId]: {
            sectionCurves,
          },
        },
      };
    });
  },
  setGridCurves: (
    modelId: string,
    { sectionCurves, columnCurves }: { sectionCurves: SectionCurve[]; columnCurves: ColumnCurve[] },
  ) => {
    set(({ sections, columns }) => {
      return {
        sections: {
          ...sections,
          [modelId]: {
            sectionCurves,
          },
        },
        columns: {
          ...columns,
          [modelId]: {
            columnCurves,
          },
        },
      };
    });
  },
});
