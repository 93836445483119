import { Menu, MenuList, MenuItem, Portal } from "@chakra-ui/react";
import { useSemanticTokens, spacing } from "@design-system";

interface YarnRowContextMenuProps {
  isOpen: boolean;
  onClose: () => void;
  position: { x: number; y: number } | null;
  onDelete: () => void;
  onDuplicate: () => void;
  onRename?: () => void;
}

export function YarnRowContextMenu({
  isOpen,
  onClose,
  position,
  onDelete,
  onDuplicate,
  onRename,
}: YarnRowContextMenuProps) {
  const semanticTokens = useSemanticTokens();

  if (!position) return null;

  const handleRename = () => {
    onClose();

    if (onRename) {
      setTimeout(() => {
        onRename();
      }, 10);
    }
  };

  return (
    <Portal>
      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuList
          position="fixed"
          top={`${position.y}px`}
          left={`${position.x}px`}
          zIndex={1500}
          bg={semanticTokens.surface.classic.secondary}
          borderColor={semanticTokens.border.classic.primary}
          borderWidth="1px"
          borderRadius="4px"
          boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.3)"
          fontSize="sm"
          p={0}
          width="176px"
          minW="176px"
          data-test-id="yarn-row-context-menu"
        >
          {onRename && (
            <MenuItem
              onClick={handleRename}
              color={semanticTokens.text.classic.primary}
              _hover={{ bg: semanticTokens.surface.classic.tertiary }}
              fontSize="sm"
              h="44px"
              px={spacing.space[100]}
              fontWeight="normal"
              py={0}
              borderRadius={0}
            >
              Rename
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              onDuplicate();
              onClose();
            }}
            color={semanticTokens.text.classic.primary}
            _hover={{ bg: semanticTokens.surface.classic.tertiary }}
            fontSize="sm"
            h="44px"
            px={spacing.space[100]}
            fontWeight="normal"
            py={0}
            borderRadius={0}
          >
            Duplicate row
          </MenuItem>
          <MenuItem
            onClick={() => {
              onDelete();
              onClose();
            }}
            color={semanticTokens.text.error}
            _hover={{ bg: semanticTokens.surface.classic.tertiary }}
            fontSize="sm"
            h="44px"
            px={spacing.space[100]}
            fontWeight="normal"
            py={0}
            borderRadius={0}
          >
            Delete row
          </MenuItem>
        </MenuList>
      </Menu>
    </Portal>
  );
}
