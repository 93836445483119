import { useAuth } from "@auth";
import { Project } from "@models/backend";
import { generateThumbnail } from "@utils/generateThumbnail";
import { useEffect, useRef } from "react";
import { useDebouncedCallback } from "use-debounce";

export function useUpdateProjectThumbnail(project: Project, delay = 3000) {
  const { headers } = useAuth();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const updateProjectThumbnail = useDebouncedCallback(() => generateThumbnail({ project, canvasRef, headers }), delay);

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.getContext("webgl2", { preserveDrawingBuffer: true });
    }
  }, [canvasRef]);

  return { canvasRef, updateProjectThumbnail };
}
