import "@/globals";
import {
  modelAttributes as defaultModelAttributes,
  pathCollectionAttributes as defaultPathCollectionAttributes,
} from "@defaults";
import { KnitStructure, PostprocessorDocument } from "@models/backend";
import { ColumnCurve, Model, PathCollection, SectionCurve } from "@models/project";
import { defaultsDeep } from "lodash";
import { Mesh } from "three";
import { exportMeshToGLB, generateMesh, getDummyMeshFromGeometry, getMeshPayload } from "./mesh";

function computeModelAttributes(model: Model, defaultKnitStructure?: KnitStructure) {
  return defaultsDeep(
    {
      ...model?.attributes,
      knitStructure: model?.attributes?.knitStructure ?? defaultKnitStructure,
    },
    defaultModelAttributes,
  );
}

export function computeStitchDensity(model: Model, defaultKnitStructure?: KnitStructure) {
  const modelAttributes = computeModelAttributes(model, defaultKnitStructure);

  return {
    course: modelAttributes.stitchDensity?.course ?? modelAttributes.knitStructure?.courseDensity ?? 12,
    wale: modelAttributes.stitchDensity?.wale ?? modelAttributes.knitStructure?.waleDensity ?? 8,
  };
}

function createSectionCurvesObject(sectionCurves: SectionCurve[]) {
  return sectionCurves.map((sectionCurve) => {
    return { excludeGoring: sectionCurve.attributes?.excludeGoring === true };
  });
}

function createKnitStructuresObject(
  modelAttributes: ReturnType<typeof computeModelAttributes>,
  stitchDensity: ReturnType<typeof computeStitchDensity>,
  postprocessorDocument: PostprocessorDocument,
  zones: PathCollection[],
) {
  const defaultKnitStructureColor =
    postprocessorDocument.entries.find((entry) => entry.knitStructure.id === modelAttributes.knitStructure.id)
      ?.machineColorEntry.color ?? modelAttributes.knitStructure.color;

  const defaultKnitStructure = {
    ...modelAttributes.knitStructure,
    color: defaultKnitStructureColor,
    courseDensity: stitchDensity.course,
    waleDensity: stitchDensity.wale,
  };

  return {
    default: defaultKnitStructure,
    zones: zones.map((zone) => {
      const knitStructure = zone.attributes?.knitStructure ?? modelAttributes.knitStructure;
      const color =
        postprocessorDocument.entries.find((entry) => entry.knitStructure.id === knitStructure.id)?.machineColorEntry
          .color ?? knitStructure.color;
      const courseDensity = zone.attributes?.stitchDensity?.course ?? knitStructure.courseDensity;
      const transition = zone.attributes?.transition ?? defaultPathCollectionAttributes.transition;
      const general = zone.attributes?.general ?? {};
      return { ...knitStructure, ...general, color, courseDensity, transition };
    }),
  };
}
export default async function exportBMP(
  model: Model,
  guideSource: PathCollection,
  pathCollections: PathCollection[],
  sectionCurves: SectionCurve[],
  columnCurves: ColumnCurve[],
  defaultKnitStructure: KnitStructure,
  postprocessorDocument: PostprocessorDocument,
  binary = true,
  debug = false,
): Promise<File> {
  const modelAttributes = computeModelAttributes(model, defaultKnitStructure);
  const bitmapSettings = modelAttributes.bitmapSettings;
  const stitchDensity = computeStitchDensity(model, defaultKnitStructure);
  const result = await getMeshPayload(
    model,
    guideSource,
    pathCollections,
    columnCurves.map((c) => c.point),
    sectionCurves.map((c) => c.point),
    stitchDensity,
  );
  let partMesh: Mesh;
  if (debug) {
    partMesh = await generateMesh(model.mesh3DBase);
  } else {
    partMesh = getDummyMeshFromGeometry();
  }

  partMesh.geometry.userData = {
    ...result,
    knitParameters: {
      knitStructures: createKnitStructuresObject(
        modelAttributes,
        stitchDensity,
        postprocessorDocument,
        pathCollections,
      ),
      sectionCurves: createSectionCurvesObject(sectionCurves),
      bitmapSettings: bitmapSettings,
    },
  };

  if (debug) {
    console.log(partMesh.geometry.userData);
  }

  return exportMeshToGLB(partMesh, "scene", binary, debug);
}
