import { MeshState, ModelsStore } from "@state/models/types";
import { Mesh } from "three";
import { StoreApi } from "zustand";

export const meshesSlice = (set: StoreApi<ModelsStore>["setState"]): MeshState => ({
  meshes: {},
  setMesh: (modelId: string, mesh: Mesh) =>
    set(({ meshes }) => {
      return {
        meshes: {
          ...meshes,
          [modelId]: { mesh },
        },
      };
    }),
});
