import { Colorway } from "@models/backend";
import { GetState, SetState } from "zustand";
import { ColorwaysState, ModelsStore } from "../types";

export const colorwaysSlice = (set: SetState<ModelsStore>, get: GetState<ModelsStore>): ColorwaysState => ({
  colorways: [],
  selectedColorwayId: "",

  setColorways: (colorways: Colorway[]) => {
    const { selectedColorwayId } = get();

    if (colorways.length > 0) {
      const selectedIdExists = selectedColorwayId && colorways.some((c) => c.id === selectedColorwayId);

      if (!selectedIdExists && colorways.length > 0) {
        set({
          colorways,
          selectedColorwayId: colorways[0].id,
        });
      } else {
        set({ colorways });
      }
    } else {
      set({
        colorways,
        selectedColorwayId: "",
      });
    }
  },

  setColorway: (colorway) => {
    set({ colorways: get().colorways.map((c) => (c.id === colorway.id ? colorway : c)) });
  },

  setSelectedColorwayId: (id: string) => {
    const colorways = get().colorways;
    const colorwayExists = colorways.some((c) => c.id === id);

    if (colorwayExists) {
      set({ selectedColorwayId: id });
    } else {
      console.warn("Cannot select colorway with ID:", id, "- not found in colorways list");
      if (colorways.length > 0) {
        console.log("Falling back to first colorway:", colorways[0].id);
        set({ selectedColorwayId: colorways[0].id });
      }
    }
  },
});
