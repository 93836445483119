import { useState } from "react";
import { useBetween } from "use-between";

export type ModifierKeys = { altKey: boolean; ctrlKey: boolean; metaKey: boolean; shiftKey: boolean };

// helper for tracking pointer down/up events
// TODO: add handling for modifier keys (e.g. shift, ctrl, alt, etc. change mouse behavior)
function usePointerStateNotShared() {
  const [pointer0Down, setPointer0Down] = useState(false);
  const [pointer1Down, setPointer1Down] = useState(false);
  const [pointer2Down, setPointer2Down] = useState(false);

  const [metaKey, setMetaKey] = useState(false);
  const [ctrlKey, setCtrlKey] = useState(false);
  const [shiftKey, setShiftKey] = useState(false);
  const [altKey, setAltKey] = useState(false);

  const handlePointerDown = (event: PointerEvent) => {
    switch (event.button) {
      case 0:
        setPointer0Down(true);
        break;
      case 1:
        setPointer1Down(true);
        break;
      case 2:
        setPointer2Down(true);
        break;
    }
    handleModifiers(event);
  };

  const handlePointerUp = (event: PointerEvent) => {
    switch (event.button) {
      case 0:
        setPointer0Down(false);
        break;
      case 1:
        setPointer1Down(false);
        break;
      case 2:
        setPointer2Down(false);
        break;
    }
    handleModifiers(event);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (document.activeElement instanceof HTMLInputElement || document.activeElement instanceof HTMLTextAreaElement) {
      return;
    }

    switch (event.key) {
      case "Shift":
        setShiftKey(true);
        break;
      case "Control":
        setCtrlKey(true);
        break;
      case "Alt":
        setAltKey(true);
        break;
      case "Meta":
        event.preventDefault();
        setMetaKey(true);
        break;
    }
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (document.activeElement instanceof HTMLInputElement || document.activeElement instanceof HTMLTextAreaElement) {
      return;
    }

    switch (event.key) {
      case "Shift":
        setShiftKey(false);
        break;
      case "Control":
        setCtrlKey(false);
        break;
      case "Alt":
        setAltKey(false);
        break;
      case "Meta":
        setMetaKey(false);
        break;
    }
  };

  const handleModifiers = (event: MouseEvent) => {
    if (metaKey != event.metaKey) setMetaKey(event.metaKey);
    if (ctrlKey != event.ctrlKey) setCtrlKey(event.ctrlKey);
    if (shiftKey != event.shiftKey) setShiftKey(event.shiftKey);
    if (altKey != event.altKey) setAltKey(event.altKey);
  };

  const disableContextMenu = (event: HTMLElementEventMap["contextmenu"]) => {
    event.stopPropagation();
    event.preventDefault();
  };

  function setUpListeners() {
    const workspace3D = document.getElementById("workspace3D");
    workspace3D?.addEventListener("pointerdown", handlePointerDown);
    workspace3D?.addEventListener("pointerup", handlePointerUp);
    window.addEventListener("keydown", handleKeyDown, true);
    window.addEventListener("keyup", handleKeyUp, true);
    workspace3D?.addEventListener("contextmenu", disableContextMenu);

    return () => {
      workspace3D?.removeEventListener("pointerdown", handlePointerDown);
      workspace3D?.removeEventListener("pointerup", handlePointerUp);
      window.removeEventListener("keydown", handleKeyDown, true);
      window.removeEventListener("keyup", handleKeyUp, true);
      window.removeEventListener("contextmenu", disableContextMenu);
    };
  }

  return { pointer0Down, pointer1Down, pointer2Down, metaKey, ctrlKey, shiftKey, altKey, setUpListeners };
}

export const usePointerState = () => useBetween(usePointerStateNotShared);
