import { useEffect } from "react";
import { useThree } from "@react-three/fiber";

const RAY_CASTER_POINTS_THRESHOLD = 0.025;

type RaycasterSetupProps = {
  pointsThreshold?: number;
};

export function RaycasterSetup({ pointsThreshold = RAY_CASTER_POINTS_THRESHOLD }: RaycasterSetupProps) {
  const { raycaster } = useThree();

  useEffect(() => {
    raycaster.params.Points!.threshold = pointsThreshold;
  }, [pointsThreshold]);

  return null;
}
