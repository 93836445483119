import { Accordion, Container, Text } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { spacing, useSemanticTokens } from "@design-system";
import { useModelApi, useTranslation } from "@hooks";
import { useBackendPathCollection } from "@hooks/backend-api/useBackendPathCollection";
import { Project } from "@models/backend";
import { useModelsStore } from "@state/models";
import { useCallback, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ColumnIcon, GuideCurveIcon, PathIcon, SectionIcon, ZoneIcon } from "./icons";
import { PathInfo, ColumnInfo, SectionInfo } from "./Common";
import { useProjectState } from "@state";
import { sortPathCollections } from "@utils/project/pathCollections";
import { recordToArray } from "@utils/project/collection";
import { SidebarModelLayer } from "./SidebarModelLayer";

export function SidebarLayers({ project, ...props }: StyleProps & { project: Project }) {
  const { id: projectId } = project;
  const models = useModelsStore((state) => state.models).filter((model) => !model.parentId);
  const { columns, pathCollections, sections } = useModelsStore((s) => s);
  const { selectedObjects, setSelectedObjects, hoveredObject, setHoveredObject, changePathCollection } =
    useModelsStore();
  const { updateBackendPathCollection } = useBackendPathCollection();
  const { set3DToolbarSelection } = useProjectState();

  const { t } = useTranslation("project.sidebar.layers");
  const semanticTokens = useSemanticTokens();
  const { patchModel } = useModelApi();

  const handleRename = useCallback(
    (modelId: string, layerId: string | null, newValue: string) => {
      if (layerId === null) {
        patchModel(projectId, modelId, { name: newValue }).catch((error) => {
          console.error(`Failed to update model name:`, error);
        });
      } else {
        const updatedCollections = changePathCollection(modelId, layerId, { name: newValue });

        if (updatedCollections.length > 0) {
          const updatedCollection = updatedCollections[0];
          updateBackendPathCollection(projectId, modelId, updatedCollection).catch((error) => {
            console.error("Failed to update backend:", error);
            changePathCollection(modelId, layerId, { name: updatedCollection.name });
          });
        }
      }
    },
    [changePathCollection, updateBackendPathCollection, patchModel, projectId],
  );

  const pathsInfo: { [key: string]: Array<PathInfo> } = useMemo(() => {
    const result: { [key: string]: Array<PathInfo> } = {};
    for (const { id: modelKey, zoneOrder } of models) {
      result[modelKey] = [];
      const collections = recordToArray(pathCollections[modelKey].collections);
      const sortedPathCollections = sortPathCollections(collections, zoneOrder);

      for (const collection of sortedPathCollections) {
        const { usage, name, isLoop } = collection;
        const icon =
          usage === "zone" ? (
            isLoop ? (
              <ZoneIcon />
            ) : (
              <PathIcon />
            )
          ) : usage === "guide" ? (
            <GuideCurveIcon />
          ) : (
            <PathIcon />
          );
        const layerName = name || t("types.unnamed");
        const group = usage ?? "unused";
        const colKey = collection.id!;
        const layer: PathInfo = {
          key: colKey,
          name: layerName,
          icon,
          layerId: colKey,
          layerType: "path",
          group,
          isLoop: isLoop ?? false,
          selected: !!selectedObjects.find((o) => o.id === colKey),
          hovered: hoveredObject?.id === colKey,
          onSelect: () => {
            setSelectedObjects([{ id: colKey, type: "path", modelId: modelKey }]);
          },
          onHover: () => setHoveredObject({ id: colKey, type: "path", modelId: modelKey }),
          onUnhover: () => setHoveredObject(null),
          isEditable: true,
        };
        result[modelKey].push(layer);
      }
    }
    return result;
  }, [pathCollections, models, selectedObjects, hoveredObject, setSelectedObjects, setHoveredObject, t]);

  const sectionsInfo: { [key: string]: Array<SectionInfo> } = useMemo(() => {
    const result: { [key: string]: Array<SectionInfo> } = {};
    for (const { id: modelKey } of models) {
      result[modelKey] = [];
      sections[modelKey]?.sectionCurves.map((sectionCurve, index) => {
        const id = sectionCurve.id!;
        const icon = <SectionIcon />;
        const layer: SectionInfo = {
          key: id,
          name: "Section " + (index + 1),
          icon,
          layerId: id,
          layerType: "section",
          group: "section",
          selected: !!selectedObjects.find((o) => o.id === id),
          hovered: hoveredObject?.id === id,
          onSelect: () => {
            setSelectedObjects([{ id: id, type: "section", modelId: modelKey }]);
            set3DToolbarSelection("select");
          },
          onHover: () => setHoveredObject({ id: id, type: "section", modelId: modelKey }),
          onUnhover: () => setHoveredObject(null),
        };
        result[modelKey].push(layer);
      });
    }
    return result;
  }, [sections, models, selectedObjects, hoveredObject, setSelectedObjects, setHoveredObject]);

  const columnsInfo: { [key: string]: Array<ColumnInfo> } = useMemo(() => {
    const result: { [key: string]: Array<ColumnInfo> } = {};
    for (const { id: modelKey } of models) {
      result[modelKey] = [];
      columns[modelKey]?.columnCurves.map((columnCurve, index) => {
        const id = columnCurve.id!;
        const icon = <ColumnIcon />;
        const layer: ColumnInfo = {
          key: id,
          name: "Column " + (index + 1),
          icon,
          layerId: id,
          layerType: "column",
          group: "column",
          selected: !!selectedObjects.find((o) => o.id === id),
          hovered: hoveredObject?.id === id,
          onSelect: () => {
            setSelectedObjects([{ id: id, type: "column", modelId: modelKey }]);
            set3DToolbarSelection("select");
          },
          onHover: () => setHoveredObject({ id: id, type: "column", modelId: modelKey }),
          onUnhover: () => setHoveredObject(null),
        };
        result[modelKey].push(layer);
      });
    }
    return result;
  }, [columns, models, selectedObjects, hoveredObject, setSelectedObjects, setHoveredObject]);

  if (!models.length) {
    return (
      <Text color={semanticTokens.text.invert.tertiary} padding={spacing.space[200]} variant="2xs-medium">
        {t("emptyState")}
      </Text>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Container as={Accordion} variant="classic" allowMultiple defaultIndex={models.map((_, i) => i)} {...props}>
        {models.map((model) => (
          <SidebarModelLayer
            key={model.id}
            project={project}
            model={model}
            paths={pathsInfo[model.id]}
            sections={sectionsInfo[model.id]}
            columns={columnsInfo[model.id]}
            onHover={() => setHoveredObject({ id: model.id, type: "model" })}
            onUnhover={() => setHoveredObject(null)}
            onSelect={() => {
              setSelectedObjects([{ type: "model", id: model.id }]);
              set3DToolbarSelection("select");
            }}
            selected={!!selectedObjects.find((o) => o.id === model.id)}
            hovered={hoveredObject?.id === model.id}
            onRename={handleRename}
          />
        ))}
      </Container>
    </DndProvider>
  );
}
