import { ModelsStore } from "../types";
import { Process } from "@models/project";
import { SetState } from "zustand";

export const processesSlice = (set: SetState<ModelsStore>) => ({
  process: undefined,
  setProcess: (process: Process) => set({ process }),
  knitMeshDirty: false,
  setKnitMeshDirty: (knitMeshDirty: boolean) => {
    set(() => ({ knitMeshDirty }));
  },
});
