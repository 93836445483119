import { Accordion, Button, Container, HStack, Input, Switch, Text } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { pathCollectionAttributes as defaultPathCollectionAttributes } from "@defaults/project";
import { Dropdown, DropdownItem, spacing, useSemanticTokens, useThemeTokens } from "@design-system";
import { useBackendPathCollection, usePath, useTranslation } from "@hooks";
import { Project as ProjectData, PathCollectionAttributes, ProjectKnitStructure } from "@models/backend";
import { BooleanOperation } from "@variant-tech/pattern-derivation";
import { Model, PathCollection } from "@models/project";
import { useModelsStore } from "@state/models";
import { useCallback, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { StructureLabel } from "./Common";
import { FlipIcon } from "./icons";
import { SidebarPropertiesSection } from "./SidebarPropertiesSection";
import { toPrecision } from "@utils/project";

const {
  left: defaultLeftBoundary,
  right: defaultRightBoundary,
  top: defaultTopBoundary,
  bottom: defaultBottomBoundary,
} = defaultPathCollectionAttributes.transition;

type PathCollectionProps = {
  modelId: Model["id"];
  pathCollectionId: NonNullable<PathCollection["id"]>;
  project: ProjectData;
} & StyleProps;

export function SidebarPathCollectionProperties({ modelId, pathCollectionId, project, ...props }: PathCollectionProps) {
  const { t } = useTranslation("projectProperties.propertiesSidebar.tools");
  const { pathCollection, changePathCollection } = useModelsStore(
    useShallow(({ pathCollections, changePathCollection }) => ({
      pathCollection: pathCollections[modelId]?.collections[pathCollectionId],
      changePathCollection,
    })),
  );
  const model = useModelsStore(useShallow(({ models }) => models.find(({ id }) => modelId === id)))!;
  const setKnitMeshDirty = useModelsStore((s) => s.setKnitMeshDirty);
  const { updateBackendPathCollection } = useBackendPathCollection();
  const { knitStructures, yarnTokens } = useModelsStore((s) => s);
  const pathInfo = useModelsStore(({ pathsInfo }) => pathsInfo[pathCollectionId]);
  const { flipPath } = usePath(project, model, pathCollection);

  const semanticTokens = useSemanticTokens();
  const textClassicSecondary = semanticTokens.text.classic.secondary;
  const textClassicPrimary = semanticTokens.text.classic.primary;
  const { border } = useThemeTokens();

  const modelStructure = model?.attributes?.knitStructure;
  const defaultStructure = {
    ...knitStructures.find((structure) => structure.isDefault == true),
    yarnTokenIds: [],
  } as ProjectKnitStructure;
  const selectedStructure = pathCollection?.attributes?.knitStructure ?? modelStructure ?? defaultStructure;

  const pathCollectionCourse =
    pathCollection?.attributes?.stitchDensity?.course ?? selectedStructure?.courseDensity ?? 0;

  const pathCollectionLegacyBoundary = pathCollection?.attributes?.transition?.boundary;

  const pathCollectionLeftBoundary =
    pathCollection?.attributes?.transition?.left ?? pathCollectionLegacyBoundary ?? defaultLeftBoundary;
  const pathCollectionRightBoundary =
    pathCollection?.attributes?.transition?.right ?? pathCollectionLegacyBoundary ?? defaultRightBoundary;
  const pathCollectionTopBoundary = pathCollection?.attributes?.transition?.top ?? defaultTopBoundary;
  const pathCollectionBottomBoundary = pathCollection?.attributes?.transition?.bottom ?? defaultBottomBoundary;

  const isKnitStructureDefault = !pathCollection?.attributes?.knitStructure;
  const isStitchDensityDefault = !pathCollection?.attributes?.stitchDensity;

  const [selectedCourse, setSelectedCourse] = useState<string>(pathCollectionCourse.toFixed(3) ?? "");
  const [selectedRightBoundary, setSelectedRightBoundary] = useState<string>(pathCollectionRightBoundary.toString());
  const [selectedLeftBoundary, setSelectedLeftBoundary] = useState<string>(pathCollectionLeftBoundary.toString());
  const [selectedTopBoundary, setSelectedTopBoundary] = useState<string>(pathCollectionTopBoundary.toString());
  const [selectedBottomBoundary, setSelectedBottomBoundary] = useState<string>(pathCollectionBottomBoundary.toString());

  const setKnitStructure = useCallback(
    async (newStructure: ProjectKnitStructure | null) => {
      const updated = changePathCollection(modelId, pathCollectionId, {
        attributes: {
          knitStructure: newStructure,
        },
      });
      await Promise.all(updated.map((u) => updateBackendPathCollection(project.id, modelId, u)));
      if (!newStructure || selectedStructure?.id === newStructure?.id) {
        return;
      }
      setKnitMeshDirty(true);
    },
    [isStitchDensityDefault, knitStructures, changePathCollection],
  );

  const setKnitStructureTokenId = useCallback(
    async (index: number, tokenId: string) => {
      if (selectedStructure?.yarnTokenIds?.length && selectedStructure?.yarnTokenIds[index] === tokenId) {
        return;
      }

      const newStructure = { ...selectedStructure };
      if (newStructure.yarnTokenIds) {
        newStructure.yarnTokenIds[index] = tokenId;
      } else {
        newStructure.yarnTokenIds = [tokenId];
      }

      const updated = changePathCollection(modelId, pathCollectionId, {
        attributes: {
          knitStructure: newStructure,
        },
      });
      await Promise.all(updated.map((u) => updateBackendPathCollection(project.id, modelId, u)));
    },
    [changePathCollection, selectedStructure],
  );

  const saveCourse = useCallback(async () => {
    const course = isNaN(parseFloat(selectedCourse)) ? null : parseFloat(selectedCourse);
    if (course == pathCollectionCourse) {
      return;
    }
    const updated = changePathCollection(modelId, pathCollectionId, { attributes: { stitchDensity: { course } } });
    setSelectedCourse(course !== null ? course.toFixed(3) : "");
    await Promise.all(updated.map((u) => updateBackendPathCollection(project.id, modelId, u)));
    setKnitMeshDirty(true);
  }, [selectedCourse, pathCollectionId, modelId]);

  const saveBoundary = useCallback(async () => {
    const left = isNaN(parseInt(selectedLeftBoundary)) ? defaultLeftBoundary : parseInt(selectedLeftBoundary);
    const right = isNaN(parseInt(selectedRightBoundary)) ? defaultRightBoundary : parseInt(selectedRightBoundary);
    const top = isNaN(parseInt(selectedTopBoundary)) ? defaultTopBoundary : parseInt(selectedTopBoundary);
    const bottom = isNaN(parseInt(selectedBottomBoundary)) ? defaultBottomBoundary : parseInt(selectedBottomBoundary);
    if (
      pathCollectionLeftBoundary == left &&
      pathCollectionRightBoundary == right &&
      pathCollectionTopBoundary == top &&
      pathCollectionBottomBoundary == bottom
    ) {
      return;
    }
    const updated = changePathCollection(modelId, pathCollectionId, {
      attributes: { transition: { left, right, top, bottom } },
    });
    setSelectedRightBoundary(right.toString());
    setSelectedLeftBoundary(left.toString());
    setSelectedTopBoundary(top.toString());
    setSelectedBottomBoundary(bottom.toString());
    await Promise.all(updated.map((u) => updateBackendPathCollection(project.id, modelId, u)));
    setKnitMeshDirty(true);
  }, [
    selectedLeftBoundary,
    selectedRightBoundary,
    selectedTopBoundary,
    selectedBottomBoundary,
    pathCollectionId,
    modelId,
  ]);

  const changeGeneral = useCallback(
    async (change: PathCollectionAttributes["general"]) => {
      const updated = changePathCollection(modelId, pathCollectionId, { attributes: { general: change } });
      await Promise.all(updated.map((u) => updateBackendPathCollection(project.id, modelId, u)));
    },
    [changePathCollection, pathCollectionId, modelId],
  );
  const excludeGoring = pathCollection?.attributes?.general?.excludeGoring ?? false;
  const finishedEdges = pathCollection?.attributes?.general?.finishedEdges ?? false;

  if (!pathCollection) {
    return null;
  }
  const isZone = pathCollection.usage === "zone";
  const isGuide = pathCollection.usage === "guide";
  const hasOperation = !!pathCollection.operation;

  return (
    <Container variant="classic" borderTopWidth={border.width} {...props}>
      <Accordion variant="outline" allowMultiple defaultIndex={[0, 1, 2]} paddingX={spacing.space["200"]} w="full">
        {isZone ? (
          <>
            <SidebarPropertiesSection borderTop="unset" title={t("curveSettings.zoneSettings.label")}>
              <HStack justifyContent="space-between" w={"100%"}>
                <Text color={textClassicSecondary} variant="2xs-regular">
                  {t("curveSettings.zoneSettings.invert.label")}
                </Text>
                <Switch
                  isChecked={hasOperation}
                  marginLeft="auto"
                  size="sm"
                  onChange={async (e) => {
                    const operation: BooleanOperation | null = e.target.checked
                      ? { name: "COMPLEMENT", operands: [null] }
                      : null;
                    const updated = changePathCollection(modelId, pathCollectionId, { operation });
                    await Promise.all(updated.map((u) => updateBackendPathCollection(project.id, modelId, u)));
                    setKnitMeshDirty(true);
                  }}
                />
              </HStack>
            </SidebarPropertiesSection>
            <SidebarPropertiesSection title={t("curveSettings.knitStructure.label")}>
              <HStack justifyContent="space-between" w={"100%"}>
                <Text color={textClassicSecondary} variant="2xs-regular">
                  {t("curveSettings.knitStructure.default.label")}
                </Text>
                <Switch
                  isChecked={isKnitStructureDefault}
                  marginLeft="auto"
                  size="sm"
                  onChange={async (e) => {
                    if (e.target.checked) {
                      setKnitStructure(null);
                    } else {
                      setKnitStructure(modelStructure ?? defaultStructure!);
                    }
                  }}
                />
              </HStack>
              <HStack justifyContent="space-between" w="full">
                <Dropdown
                  variant={isKnitStructureDefault ? "classic-dropdown-disabled" : "classic-dropdown"}
                  label={<StructureLabel structure={selectedStructure} />}
                  buttonStyleProps={{
                    size: "sm",
                    width: "100%",
                    height: spacing.space["600"],
                    isDisabled: isKnitStructureDefault,
                    cursor: isKnitStructureDefault ? "not-allowed" : "pointer",
                  }}
                  menuListStyleProps={{
                    paddingY: spacing.space["100"],
                    maxHeight: "200px",
                    overflowY: "auto",
                    maxWidth: "200px",
                  }}
                >
                  {knitStructures.map((structure) => (
                    <DropdownItem
                      key={structure.id}
                      content={<StructureLabel structure={structure} />}
                      value={structure.id}
                      selectedValue={selectedStructure?.id}
                      setSelectedValue={async () => {
                        setKnitStructure({ ...structure, yarnTokenIds: [] });
                        if (isStitchDensityDefault) {
                          setSelectedCourse(structure.courseDensity?.toFixed(3) ?? "");
                        }
                      }}
                    />
                  ))}
                </Dropdown>
              </HStack>
              {selectedStructure && selectedStructure.yarnTokenCount > 0 && (
                <>
                  <Text color={textClassicSecondary} variant="2xs-regular" width="50%">
                    {t("curveSettings.knitStructure.yarnAssignments.label")}
                  </Text>
                  {Array.from({ length: selectedStructure.yarnTokenCount }, (_, i) => (
                    <HStack key={i} justifyContent="space-between" w="full">
                      <Dropdown
                        variant={isKnitStructureDefault ? "classic-dropdown-disabled" : "classic-dropdown"}
                        labelVariant="2xs-regular"
                        label={
                          selectedStructure.yarnTokenIds &&
                          yarnTokens?.find((token) => token?.id == selectedStructure.yarnTokenIds[i])?.name
                        }
                        buttonStyleProps={{
                          size: "sm",
                          width: "100%",
                          height: spacing.space["600"],
                          isDisabled: isKnitStructureDefault,
                          cursor: isKnitStructureDefault ? "not-allowed" : "pointer",
                        }}
                        menuListStyleProps={{
                          paddingY: spacing.space["100"],
                          maxHeight: "200px",
                          overflowY: "auto",
                          maxWidth: "200px",
                        }}
                      >
                        {yarnTokens?.map((token) => (
                          <DropdownItem
                            key={token?.id}
                            variant="2xs-regular"
                            content={token?.name}
                            value={token?.id}
                            selectedValue={selectedStructure.yarnTokenIds && selectedStructure.yarnTokenIds[i]}
                            setSelectedValue={() => token && setKnitStructureTokenId(i, token?.id)}
                          />
                        ))}
                      </Dropdown>
                    </HStack>
                  ))}
                </>
              )}
              <HStack justifyContent="space-between" w={"100%"}>
                <Text color={textClassicSecondary} variant="2xs-regular">
                  {t("meshSettings.exclude.label")}
                </Text>
                <Switch
                  isChecked={excludeGoring}
                  marginLeft="auto"
                  size="sm"
                  onChange={async (e) => {
                    if (e.target.checked) {
                      changeGeneral({ excludeGoring: true });
                    } else {
                      changeGeneral({ excludeGoring: false });
                    }
                  }}
                />
              </HStack>
            </SidebarPropertiesSection>
            <SidebarPropertiesSection title={t("stitchDensity.label")}>
              <HStack justifyContent="space-between" w={"100%"}>
                <Text color={textClassicSecondary} variant="2xs-regular">
                  {t("stitchDensity.default.knitStructure.label")}
                </Text>
                <Switch
                  isChecked={isStitchDensityDefault}
                  marginLeft="auto"
                  size="sm"
                  onChange={async (e) => {
                    let updated: PathCollection[] = [];
                    if (e.target.checked) {
                      updated = changePathCollection(modelId, pathCollectionId, {
                        attributes: { stitchDensity: null },
                      });
                    } else {
                      updated = changePathCollection(modelId, pathCollectionId, {
                        attributes: { stitchDensity: { course: selectedStructure?.courseDensity ?? 0 } },
                      });
                    }
                    setSelectedCourse(selectedStructure?.courseDensity?.toFixed(3) ?? "");
                    await Promise.all(updated.map((u) => updateBackendPathCollection(project.id, modelId, u)));
                  }}
                />
              </HStack>
              <HStack justifyContent="space-between" w={"100%"}>
                <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
                  {t("stitchDensity.course.label")}
                </Text>
                <Input
                  disabled={isStitchDensityDefault}
                  size="sm"
                  w="50%"
                  height={spacing.space["600"]}
                  type="number"
                  placeholder="0"
                  onChange={(e) => setSelectedCourse(e.target.value)}
                  onBlur={saveCourse}
                  value={selectedCourse}
                />
              </HStack>
            </SidebarPropertiesSection>
            <SidebarPropertiesSection title={t("boundary.label")}>
              <HStack justifyContent="space-between" w={"100%"}>
                <Text color={textClassicSecondary} variant="2xs-regular">
                  {t("boundary.finished.label")}
                </Text>
                <Switch
                  isChecked={finishedEdges}
                  marginLeft="auto"
                  size="sm"
                  onChange={async (e) => {
                    if (e.target.checked) {
                      changeGeneral({ finishedEdges: true });
                    } else {
                      changeGeneral({ finishedEdges: false });
                    }
                  }}
                />
              </HStack>
              <HStack justifyContent="space-between" w={"100%"}>
                <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
                  {t("boundary.left.label")}
                </Text>
                <Input
                  size="sm"
                  w="50%"
                  height={spacing.space["600"]}
                  type="number"
                  placeholder="0"
                  onChange={(e) => setSelectedLeftBoundary(e.target.value)}
                  onBlur={saveBoundary}
                  value={selectedLeftBoundary}
                />
              </HStack>
              <HStack justifyContent="space-between" w={"100%"}>
                <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
                  {t("boundary.left.label")}
                </Text>
                <Input
                  size="sm"
                  w="50%"
                  height={spacing.space["600"]}
                  type="number"
                  placeholder="0"
                  onChange={(e) => setSelectedLeftBoundary(e.target.value)}
                  onBlur={saveBoundary}
                  value={selectedLeftBoundary}
                />
              </HStack>
              <HStack justifyContent="space-between" w={"100%"}>
                <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
                  {t("boundary.right.label")}
                </Text>
                <Input
                  size="sm"
                  w="50%"
                  height={spacing.space["600"]}
                  type="number"
                  placeholder="0"
                  onChange={(e) => setSelectedRightBoundary(e.target.value)}
                  onBlur={saveBoundary}
                  value={selectedRightBoundary}
                />
              </HStack>
              <HStack justifyContent="space-between" w={"100%"}>
                <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
                  {t("boundary.top.label")}
                </Text>
                <Input
                  size="sm"
                  w="50%"
                  height={spacing.space["600"]}
                  type="number"
                  placeholder="0"
                  onChange={(e) => setSelectedTopBoundary(e.target.value)}
                  onBlur={saveBoundary}
                  value={selectedTopBoundary}
                />
              </HStack>
              <HStack justifyContent="space-between" w={"100%"}>
                <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
                  {t("boundary.bottom.label")}
                </Text>
                <Input
                  size="sm"
                  w="50%"
                  height={spacing.space["600"]}
                  type="number"
                  placeholder="0"
                  onChange={(e) => setSelectedBottomBoundary(e.target.value)}
                  onBlur={saveBoundary}
                  value={selectedBottomBoundary}
                />
              </HStack>
            </SidebarPropertiesSection>
          </>
        ) : null}
        {isGuide ? (
          <SidebarPropertiesSection borderTop="unset" title={t("curveSettings.label")}>
            <HStack justifyContent="space-between" w={"100%"}>
              <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
                {t("curveSettings.direction.label")}
              </Text>
              <Button
                onClick={async () => {
                  await flipPath();
                }}
                size="xs"
                variant="outline"
                width={spacing.space["800"]}
              >
                <FlipIcon width={spacing.space["400"]} />
              </Button>
            </HStack>
          </SidebarPropertiesSection>
        ) : null}
        {pathInfo ? (
          <SidebarPropertiesSection borderTop="unset" title={t("curveInfo.label")}>
            <HStack justifyContent="space-between" w={"100%"}>
              <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
                {t("curveInfo.total")}
              </Text>
              <Text color={textClassicPrimary} variant="2xs-regular" w="50%">
                {toPrecision(pathInfo.length ?? 0.0, 3)}
              </Text>
            </HStack>
          </SidebarPropertiesSection>
        ) : null}
      </Accordion>
    </Container>
  );
}
