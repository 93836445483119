import { Box, Flex, LinkBox, LinkOverlay, Spacer, Text } from "@chakra-ui/react";
import { CircleIcon, spacing, useSemanticTokens, utility } from "@design-system";
import { cloneElement, ReactElement } from "react";
import { NavLink } from "react-router-dom";

type HomeSidebarItemProps = {
  icon: ReactElement;
  label: string;
  path: string;
  count?: number;
  selected?: boolean;
};

export function HomeSidebarItem({ icon, label, path, count, selected }: HomeSidebarItemProps) {
  const semanticTokens = useSemanticTokens();
  const background = "transparent";
  const color = semanticTokens.text.classic.primary;
  const selectedBackground = semanticTokens.surface.invert.accent.secondary;
  const selectedColor = semanticTokens.text.accent.primary;
  const adjustedIcon = cloneElement(icon, {
    color: selected ? selectedColor : color,
    fill: selected ? selectedColor : "none",
    boxSize: "5",
  });

  return (
    <LinkBox
      as={Flex}
      alignItems="center"
      dir="row"
      gap="1rem"
      w="16.25rem"
      px={spacing.space["600"]}
      py={spacing.space["400"]}
      _hover={{
        background: semanticTokens.surface.classic.secondary,
      }}
      background={selected ? selectedBackground : background}
    >
      {adjustedIcon}
      <LinkOverlay as={NavLink} to={path}>
        <Text variant="s-medium" color={selected ? selectedColor : color}>
          {label}
        </Text>
      </LinkOverlay>
      <Spacer />
      {count && (
        <Box as={Flex} alignItems="center" dir="row" background={utility.blue} gap={1} px={2} py="1px">
          <CircleIcon color="white" />
          <Text color="white">{count}</Text>
        </Box>
      )}
    </LinkBox>
  );
}
