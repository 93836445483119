import { ColumnCurve } from "@models/project";
import { GetState, SetState } from "zustand";
import { ModelsStore } from "../types";

export const columnsSlice = (set: SetState<ModelsStore>, get: GetState<ModelsStore>) => ({
  columns: {},

  nextColumnNumber: (modelId: string) => {
    return (get().columns[modelId]?.columnCurves?.length ?? 0) + 1;
  },

  addColumnCurve: (modelId: string, columnCurve: ColumnCurve) => {
    set(({ columns }: ModelsStore) => {
      const modelColumnCurves = columns[modelId]?.columnCurves || [];
      const changedColumns = [...modelColumnCurves, columnCurve];
      return {
        columns: {
          ...columns,
          [modelId]: {
            columnCurves: changedColumns,
          },
        },
      };
    });
  },
  removeColumnCurve: (modelId: string, id: string) => {
    set(({ columns }: ModelsStore) => {
      const modelColumnCurves = columns[modelId].columnCurves;
      const changedColumns = modelColumnCurves.filter((columnCurve) => columnCurve.id !== id);
      return {
        columns: {
          ...columns,
          [modelId]: {
            columnCurves: changedColumns,
          },
        },
      };
    });
  },
  setColumnCurves: (modelId: string, columnCurves: ColumnCurve[]) => {
    set(({ columns }) => {
      return {
        columns: {
          ...columns,
          [modelId]: {
            columnCurves,
          },
        },
      };
    });
  },
});
