import { Theme, useColorMode } from "@chakra-ui/react";
import { useCallback, useContext, useMemo } from "react";
import { SelectedThemeContext } from "../SelectedThemeProvider";

const availableThemes = ["light", "dark", "classic"] as const;

type AvailableTheme = (typeof availableThemes)[number];

export type UseSelectedTheme = {
  availableThemes: typeof availableThemes;
  chakraTheme: Partial<Theme>;
  selectedTheme: AvailableTheme;
  setSelectedTheme: (theme: AvailableTheme) => void;
  toggleTheme: () => void;
};

export function useSelectedTheme(): UseSelectedTheme {
  const {
    selectedTheme: selectedContextTheme,
    setSelectedTheme: setSelectedContextTheme,
    chakraTheme,
  } = useContext(SelectedThemeContext);
  const { colorMode, setColorMode } = useColorMode();
  const selectedTheme = useMemo(() => {
    if (selectedContextTheme === "classic") {
      return selectedContextTheme;
    } else {
      return colorMode;
    }
  }, [selectedContextTheme, colorMode]);

  const setSelectedTheme = useCallback(
    (value: AvailableTheme) => {
      if (value === "dark") {
        setColorMode("dark");
      } else {
        setColorMode("light");
      }

      setSelectedContextTheme(value === "classic" ? value : "base");
    },
    [setColorMode, setSelectedContextTheme],
  );

  const toggleTheme = useCallback(() => {
    const selectedThemeIndex = availableThemes.indexOf(selectedTheme);
    const nextTheme = availableThemes[(selectedThemeIndex + 1) % availableThemes.length];
    setSelectedTheme(nextTheme);
  }, [selectedTheme, setSelectedTheme]);

  return {
    availableThemes,
    chakraTheme,
    selectedTheme,
    setSelectedTheme,
    toggleTheme,
  };
}
