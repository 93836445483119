import { useBackendModel } from "@hooks/backend-api/useBackendModel.ts";
import { Project, Units } from "@models/backend";
import { Model, ModelLayers } from "@models/project";
import { useModelsStore } from "@state";
import { recordToArray } from "@utils";
import { saveAs } from "file-saver";
import { omit } from "lodash";
import moment from "moment/moment";
import { useCallback } from "react";
import { sortPathCollections } from "@utils/project/pathCollections";

type ModelData = Omit<Model, "mesh3DBase" | "references" | "parentId"> & ModelLayers;
type ProjectData = { models: ModelData[] };

export function useImportExportProject(project: Project) {
  const { models, pathCollections, columns, sections, updateModel } = useModelsStore();
  const { updateBackendModel, updateBackendModelLayers } = useBackendModel();
  const handleImport = useCallback(
    async (file: File) => {
      const fileContent = await file.text();
      const data = JSON.parse(fileContent) as ProjectData;

      for (const { zoneOrder, unit, attributes, name, pathCollections, ...layers } of data.models) {
        const currentModel = models.find((model) => model.name === name);
        if (!currentModel) {
          continue;
        }
        const model = await updateBackendModel(project.id, currentModel.id, { unit, attributes, name });
        const sortedCols = sortPathCollections(pathCollections, zoneOrder);
        const backendLayers = await updateBackendModelLayers(
          project.id,
          { ...model, attributes: model.attributes ?? attributes },
          { pathCollections: sortedCols, ...layers },
        );
        const newZoneOrder: Array<[string, number]> = [];
        for (const pc of backendLayers.pathCollections) {
          if (pc.usage === "zone") {
            newZoneOrder.push([pc.id, newZoneOrder.length]);
          }
        }
        await updateBackendModel(project.id, currentModel.id, { zoneOrder: newZoneOrder });
        const scale = Units[unit];
        await currentModel.mesh3DBase.setScale(scale, scale, scale);
        updateModel(model.id, { scale, unit, attributes, name, zoneOrder: newZoneOrder, ...backendLayers });
      }
    },
    [project, models, updateModel],
  );

  const handleExport = useCallback(async () => {
    console.log(models);
    const data: ProjectData = {
      models: models
        .filter((model) => !model.parentId)
        .map((model) => omit(model, "mesh3DBase", "references", "parentId"))
        .map((model) => ({
          ...model,
          columnCurves: columns[model.id].columnCurves,
          commentThreads: [],
          pathCollections: recordToArray(pathCollections[model.id].collections),
          sectionCurves: sections[model.id].sectionCurves,
        })),
    };
    const blob = new Blob([JSON.stringify(data)], { type: "application/json" });
    saveAs(blob, `${project.name} - ${moment().format("yyyy-MM-DD")}.json`, { autoBom: true });
  }, [project, models, pathCollections, columns, sections]);

  return { handleImport, handleExport };
}
