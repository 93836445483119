import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function SchematicViewIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" height="5" width="5" {...props}>
      <path
        d="M4 16.6965L16.6 3.8857M1.98182 13.973L13.7636 1.97297M14.9091 10.4054L18.1818 13.6486M6.23636 1.81081L10.1636 5.7027M3.45455 3.75676L7.70909 7.97297M1.65455 6.67568L5.41818 10.4054M10.1636 15.2703L13.2727 18.3514M12.4545 12.8378L16.2182 16.4054M7.05455 18.3514L18.3455 6.83784M18.6916 7.66013C19.2913 9.67301 19.0278 12.3597 17.8004 14.4914C16.573 16.6231 14.3818 18.1997 12.3399 18.6916C10.327 19.2913 7.64032 19.0278 5.50863 17.8005C3.37695 16.5731 1.80027 14.3818 1.30838 12.3399C0.708689 10.327 0.972168 7.64031 2.19956 5.50863C3.42695 3.37694 5.61825 1.80027 7.66013 1.30837C9.673 0.708692 12.3597 0.972169 14.4914 2.19955C16.6231 3.42695 18.1997 5.61824 18.6916 7.66013Z"
        stroke="currentColor"
        strokeWidth="1.5"
        fill="none"
      />
    </Icon>
  );
}
