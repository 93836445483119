export const glslLibrary: string = /*glsl*/ `
const float PI = 3.1415926535897;
const float PI2 = 6.28318530718;
const float SQRT2 = 1.41421356237;

struct Implicit {
	float Distance;
	vec3 Gradient;
	vec4 Color;
};

Implicit CreateImplicit() { return Implicit(0.0, vec3(0.0), vec4(0.0)); }
Implicit CreateImplicit(float iValue) { return Implicit(iValue, vec3(0.0), vec4(0.0)); }
Implicit CreateImplicit(float iValue, vec4 iColor) { return Implicit(iValue, vec3(0.0), iColor); }

Implicit Negate(Implicit iImplicit) {
	return Implicit(-iImplicit.Distance, -iImplicit.Gradient, iImplicit.Color);
}

Implicit Add(Implicit a, Implicit b) {
	return Implicit(a.Distance + b.Distance, a.Gradient + b.Gradient, (a.Color + b.Color) * 0.5);
}

Implicit Subtract(Implicit a, Implicit b)  {
	return Implicit(a.Distance - b.Distance, a.Gradient - b.Gradient, (a.Color + b.Color) * 0.5);
}

Implicit Add(float iT, Implicit iImplicit) {
	return Implicit(iT + iImplicit.Distance, iImplicit.Gradient, iImplicit.Color);
}
Implicit Add(Implicit iImplicit, float iT) { return Add(iT, iImplicit); }
Implicit Subtract(float iT, Implicit iImplicit) { return Add(iT, Negate(iImplicit)); }
Implicit Subtract(Implicit iImplicit, float iT) { return Add(-iT, iImplicit); }

Implicit Multiply(Implicit a, Implicit b) {
	return Implicit(a.Distance * b.Distance, a.Distance * b.Gradient + b.Distance * a.Gradient, (a.Color + b.Color) * 0.5);
}
Implicit Multiply(float iT, Implicit iImplicit) { return Implicit(iT * iImplicit.Distance, iT * iImplicit.Gradient, iImplicit.Color); }
Implicit Multiply(Implicit iImplicit, float iT) { return Multiply(iT, iImplicit); }

Implicit Square(Implicit iA) { return Multiply(iA, iA); }
Implicit Square(float iA) { 
    Implicit a = CreateImplicit(iA);
    return Multiply(a, a); 
}

Implicit Divide(Implicit a, Implicit b) {
	return Implicit(a.Distance / b.Distance, (b.Distance * a.Gradient - a.Distance * b.Gradient) / (b.Distance * b.Distance), (a.Color + b.Color) * 0.5);
}
Implicit Divide(Implicit a, float b) { return Implicit(a.Distance / b, a.Gradient / b, a.Color); }

Implicit Min(Implicit a, Implicit b) 
{
	if (a.Distance <= b.Distance)
		return a;
	
	return b;
}

Implicit Max(Implicit a, Implicit b) {
	if (a.Distance >= b.Distance)
		return a;
	
	return b;
}

Implicit Max(float a, Implicit b) {
	if (a >= b.Distance)
		return Implicit(a, b.Gradient, b.Color);
	
	return b;
}


float mix11(float a, float b, float t) {
    return mix(a, b, t * 0.5 + 0.5);
}

Implicit Exp(Implicit iImplicit)
{
	float exp = exp(iImplicit.Distance);
	return Implicit(exp, exp * iImplicit.Gradient, iImplicit.Color);
}

Implicit Log(Implicit iImplicit)
{
	return Implicit(log(iImplicit.Distance), iImplicit.Gradient / iImplicit.Distance, iImplicit.Color);
}

Implicit Sqrt(Implicit iImplicit)
{
	float sqrt = sqrt(iImplicit.Distance);
	return Implicit(sqrt, iImplicit.Gradient / (2.0 * sqrt), iImplicit.Color);
}

Implicit Abs(Implicit iImplicit)
{
	return Implicit(abs(iImplicit.Distance), sign(iImplicit.Distance) * iImplicit.Gradient, iImplicit.Color);
}

Implicit Mod(Implicit iImplicit, float iM)
{
	return Implicit(mod(iImplicit.Distance, iM), iImplicit.Gradient, iImplicit.Color);
}

Implicit Atan(Implicit iY, Implicit iX)
{
    float x = iX.Distance;
    float y = iY.Distance;
    float ir2 = 1.0 / (x*x + y*y);
	return Implicit(atan(y, x), vec3(-y * ir2, x * ir2, 0.0), (iX.Color + iY.Color) * 0.5);
}

Implicit Acos(Implicit iImplicit)
{
    float d = -1.0 / sqrt(1.0 - iImplicit.Distance * iImplicit.Distance);
	return Implicit(acos(iImplicit.Distance), iImplicit.Gradient * d, iImplicit.Color);
}

Implicit Shell(Implicit iImplicit, float thickness, float bias) 
{
	thickness *= 0.5;
	return Subtract(Abs(Add(iImplicit, bias * thickness)), thickness);
}

Implicit EuclideanNorm(Implicit a, Implicit b) {
    return Sqrt(Add(Multiply(a, a), Multiply(b, b)));
}
Implicit EuclideanNorm(Implicit a, Implicit b, Implicit c) {
    return Sqrt(Add(Add(Multiply(a, a), Multiply(b, b)), Multiply(c, c)));
}

Implicit CityblockNorm(Implicit a, Implicit b) {
    return Multiply(Add(Abs(a), Abs(b)), SQRT2);
} 

// Booleans
// https://mercury.sexy/hg_sdf/
Implicit IntersectionEuclidean(Implicit a, Implicit b, float radius) {
    Implicit maxab = Max(a, b);
    Implicit r = CreateImplicit(radius, maxab.Color);
    
    Implicit ua = Implicit(Max(Add(a, r), CreateImplicit()).Distance, a.Gradient, a.Color);
    Implicit ub = Implicit(Max(Add(b, r), CreateImplicit()).Distance, b.Gradient, b.Color);
    
	Implicit op = Add(Min(Negate(r), maxab), EuclideanNorm(ua, ub));
    
    if (maxab.Distance <= 0.0)
        op.Gradient = maxab.Gradient;
        
    if (min(a.Distance, b.Distance) > 0.)
        op.Color = mix(a.Color, b.Color, 0.5 + 0.5 * (b.Distance - a.Distance)/(a.Distance + b.Distance));
        
    return op;
}

// https://iquilezles.org/articles/smin/
Implicit IntersectionExponential(Implicit a, Implicit b, float radius) {
//    float res = exp2( -a/k ) + exp2( -b/k );
//    return -k*log2( res );

    a = Exp(Divide(a, radius));
    b = Exp(Divide(b, radius));
    Implicit res = Add(a, b);
    
    return Multiply(Log(res), radius);
}

// https://mercury.sexy/hg_sdf/
Implicit UnionEuclidean(Implicit a, Implicit b, float radius) {
    Implicit ab = Min(a, b);
    Implicit r = CreateImplicit(radius, ab.Color);
    
    Implicit ua = Max(Subtract(r, a), CreateImplicit(0.0, a.Color));
    Implicit ub = Max(Subtract(r, b), CreateImplicit(0.0, b.Color));
    
	Implicit op = Subtract(Max(r, ab), EuclideanNorm(ua, ub));
    
    if (ab.Distance > 0.0)
        op.Gradient = ab.Gradient;
        
    return op;
}

// https://mercury.sexy/hg_sdf/
Implicit UnionEuclidean(Implicit a, Implicit b, Implicit c, float radius) {
    Implicit zero = CreateImplicit(0.0);
    Implicit r = CreateImplicit(radius);
    Implicit ua = Max(Subtract(r, a), zero);
    Implicit ub = Max(Subtract(r, b), zero);
    Implicit uc = Max(Subtract(r, c), zero);
    
    Implicit abc = Min(a, Min(b, c));

	Implicit op = Subtract(Max(r, abc), EuclideanNorm(ua, ub, uc));
    
    if (abc.Distance > 0.0)
        op.Gradient = abc.Gradient;
        
    return op;
}

Implicit UnionChamfer(Implicit iA, Implicit iB, float k)
{
    Implicit h = Multiply(Max(Subtract(CreateImplicit(k), Abs(Subtract(iA, iB))), CreateImplicit()), 1.0 / k);
    Implicit h2 = Multiply(h, 0.5);
    Implicit result = Subtract(Min(iA, iB), Multiply(h2, k * 0.5));
    float param = h2.Distance;
    result.Color = mix(iA.Color, iB.Color, iA.Distance < iB.Distance ? param : (1.0 - param));

    return result;
}

// Polynomial Smooth Min 2 from https://iquilezles.org/articles/smin/ and https://iquilezles.org/articles/distgradfunctions2d/
Implicit UnionSmoothMedial(Implicit a, Implicit b, float k) 
{
    float h = max(k-abs(a.Distance-b.Distance),0.0);
    float m = 0.25*h*h/k;
    float n = 0.50 * h/k;
    float dist = min(a.Distance,  b.Distance) - m; 
                 
    float param = (a.Distance < b.Distance) ? n : 1.0 - n;
    vec3 grad = mix(a.Gradient, b.Gradient, param);
    vec4 color = mix(a.Color, b.Color, param);


    return Implicit(dist, grad, color);
}

Implicit UnionSmooth(Implicit a, Implicit b, float k){
    a.Distance -= k;
    b.Distance -= k;

 //   if (min(a.Distance, b.Distance) >= 0.)
 //       return (Min(a, b));

    return Add(UnionSmoothMedial(a, b, abs(a.Distance + b.Distance) * abs(1.-dot(a.Gradient, b.Gradient))), k);
}


Implicit IntersectionSmoothMedial(Implicit iA, Implicit iB, float k){
    return Negate(UnionSmoothMedial(Negate(iA), Negate(iB), k));
}


Implicit IntersectionSmooth(Implicit iA, Implicit iB, float k){
    return Negate(UnionSmooth(Negate(iA), Negate(iB), k));
}



// R0 fro, https://www.cambridge.org/core/journals/acta-numerica/article/abs/semianalytic-geometry-with-rfunctions/3F5E061C35CA6A712BE338FE4AD1DB7B
Implicit UnionRvachev(Implicit iA, Implicit iB, float k)
{
    Implicit result = Subtract(Add(iA, iB), Sqrt(Add(Square(iA), Square(iB))));
  //  float param = 0.5;
  //  result.Color = mix(iA.Color, iB.Color, iA.Distance < iB.Distance ? param : (1.0 - param));

    return result;
}

Implicit IntersectionRvachev(Implicit iA, Implicit iB, float k) {
    return Negate(UnionRvachev(Negate(iA), Negate(iB), k));
}


// Primitives

Implicit Plane(vec3 p, vec3 origin, vec3 normal, vec4 color) {
    vec3 grad = normalize(normal);
    float v = dot(p - origin, grad);
    return Implicit(v, grad, color);
}

Implicit Plane(vec2 p, vec2 origin, vec2 normal, vec4 color) {
    return Plane(vec3(p, 0.0), vec3(origin, 0.0), vec3(normal, 0.0), color);
}


Implicit Circle(vec2 p, vec2 center, float iRadius, vec4 color) {
	vec2 centered = p - center;
    float len = length(centered);
	float length = len - iRadius;
	return Implicit(length, vec3(centered / len, 0.0), color);
}
 
mat2 Rotate2(float theta) {
    float c = cos(theta);
    float s = sin(theta);
    return mat2(
        vec2(c, -s),
        vec2(s, c)
    );
}

Implicit HalfSpace(vec2 p, vec2 start, vec2 end, vec4 color, out float param, out float len) {
    vec2 span = end - start; 
    len = length(span);
    vec2 dir = span / len;
    param = dot(dir, p - start);
    return Plane(p, start, vec2(dir.y, -dir.x), color);
}

Implicit LineSegment(vec2 p, vec2 start, vec2 end, vec4 color) {
    vec2 span = end - start; 
    float length = length(span);
    vec2 dir = span / length;
    Implicit plane = Abs(Plane(p, start, vec2(dir.y, -dir.x), color));
    vec2 center = (start + end) * 0.5;
    Implicit bounds = Subtract(Abs(Plane(p, center, dir, color)), length * 0.5);
    return IntersectionEuclidean(plane, bounds, 0.0);
}

Implicit LineSegment(vec2 p, vec2 start, vec2 end, vec4 color, out float param) {
    vec2 span = end - start; 
    float length = length(span);
    vec2 dir = span / length;
    vec2 center = (start + end) * 0.5;
    Implicit spanPlane = Plane(p, center, dir, color);
    param = (spanPlane.Distance / length + 0.5) * length;
    Implicit linePlane = Abs(Plane(p, start, vec2(dir.y, -dir.x), spanPlane.Color));
    Implicit bounds = Subtract(Abs(spanPlane), length * 0.5);
    return IntersectionEuclidean(linePlane, bounds, 0.0);
}

Implicit LineSegment(vec2 p, vec2 start, vec2 end, vec4 startColor, vec4 endColor, out float param) {
    vec2 span = end - start; 
    float length = length(span);
    vec2 dir = span / length;
    vec2 center = (start + end) * 0.5;
    Implicit spanPlane = Plane(p, center, dir, startColor);
    param = spanPlane.Distance / length + 0.5;
    spanPlane.Color = mix(startColor, endColor, param);
    param *= length;
    Implicit linePlane = Abs(Plane(p, start, vec2(dir.y, -dir.x), spanPlane.Color));
    Implicit bounds = Subtract(Abs(spanPlane), length * 0.5);
    return IntersectionEuclidean(linePlane, bounds, 0.0);
}

Implicit LineSegment(vec2 p, vec2 start, vec2 end, vec4 startColor, vec4 endColor, float startWidth, float endWidth) {
    float param;
    Implicit segment = LineSegment(p, start, end, startColor, endColor, param);
    return Subtract(segment, mix(startWidth, endWidth, param));
}

Implicit Arc(vec2 p, vec2 center, float radius, float startParam, float endParam, vec4 color) {
    Implicit circle = Circle(p, center, radius, color);

    Implicit startPlane = Plane(p, center, vec2(-sin(startParam), cos(startParam)), color);
    Implicit endPlane = Plane(p, center, vec2(sin(endParam), -cos(endParam)), color);

    Implicit bounds = Max(startPlane, endPlane);
    return IntersectionEuclidean(Abs(circle), bounds, 0.0);
}

Implicit ArcHalfSpace(vec2 p, vec2 center, float radius, float startParam, float endParam, vec4 color, out float param) {
    Implicit circle = Circle(p, center, radius, color);

    vec2 toCenter = p - center;
    param = atan(toCenter.y, toCenter.x) - startParam;

    float avgParam = 0.5 * (startParam + endParam);
    Implicit bounds = Plane(p, center, vec2(-cos(avgParam), sin(avgParam)), color);
    return Min(circle, bounds); 
}

Implicit ArcThreePointHalfSpace(vec2 p, vec2 p1, vec2 p2, vec2 p3, vec4 color, out float param, out float radius, out float span) {
    // Calculate circumcenter using determinant method
    float d = 2.0 * (p1.x*(p2.y - p3.y) + p2.x*(p3.y - p1.y) + p3.x*(p1.y - p2.y));
    
    vec2 center = vec2(
        ((p1.x*p1.x + p1.y*p1.y) * (p2.y - p3.y) + 
         (p2.x*p2.x + p2.y*p2.y) * (p3.y - p1.y) + 
         (p3.x*p3.x + p3.y*p3.y) * (p1.y - p2.y)) / d,
         
        ((p1.x*p1.x + p1.y*p1.y) * (p3.x - p2.x) + 
         (p2.x*p2.x + p2.y*p2.y) * (p1.x - p3.x) + 
         (p3.x*p3.x + p3.y*p3.y) * (p2.x - p1.x)) / d
    );
    
    radius = length(p1 - center);
    
    float startParam = atan(p1.y - center.y, p1.x - center.x);
    float endParam = atan(p3.y - center.y, p3.x - center.x);
    
    if(endParam < startParam) {
        endParam += 2.0 * PI;
    }
    if(endParam - startParam > PI) {
        endParam -= 2.0 * PI;
    }
    
    span = abs(endParam - startParam);
    return ArcHalfSpace(p, center, radius, startParam, endParam, color, param);
}

Implicit RectangleCentered(vec2 p, vec2 center, vec2 size, vec4 color) {
	vec2 centered = p - center;
    
	vec2 b = size * 0.5;
	vec2 d = abs(centered)-b;
	float dist = length(max(d, vec2(0.0))) + min(max(d.x, d.y), 0.0);

	vec2 grad = d.x > d.y ? vec2(1.0, 0.0) : vec2 (0.0, 1.0);
	if (d.x > 0. && d.y > 0.)
		grad = d / length(d);

	grad *= -sign(centered);

	return Implicit(dist, vec3(grad, 0.0), color);
}

Implicit Rectangle(vec2 p, vec2 min, vec2 max, vec4 color) {
    vec2 center = (min + max) * 0.5;
    vec2 size = max - min;
    return RectangleCentered(p, center, size, color);
}

Implicit RectangleCenterRotated(vec2 p, vec2 center, vec2 size, float angle, vec4 color) {
	vec2 centered = p - center;
    mat2 rot = Rotate2(-angle);
    centered = rot * centered;
    
	vec2 b = size * 0.5;
	vec2 d = abs(centered)-b;
	float dist = length(max(d, vec2(0.0))) + min(max(d.x, d.y), 0.0);

	vec2 grad = d.x > d.y ? vec2(1.0, 0.0) : vec2 (0.0, 1.0);
	if (d.x > 0. && d.y > 0.)
		grad = d / length(d);

	grad *= -sign(centered);

	return Implicit(dist, vec3(grad * rot, 0.0), color);
}



Implicit RectangleCenterRotatedExp(vec2 p, vec2 center, vec2 size, float angle, vec4 color)
{
	vec2 centered = p - center;
    mat2 rot = Rotate2(-angle);
    centered = rot * centered;
    size = size * 0.5;
    Implicit xPlane = Subtract(Abs(Implicit(centered.x, vec3(1, 0, 0), color)), size.x);
    Implicit yPlane = Subtract(Abs(Implicit(centered.y, vec3(0, 1, 0), color)), size.y);


    
	return IntersectionExponential(xPlane, yPlane, 20.0);
}

Implicit RectangleUGFSDFCenterRotated(vec2 p, vec2 center, float size, float angle, vec4 color)
{
	vec2 centered = p - center;
    mat2 rot = Rotate2(-angle);
 //   centered = rot * centered;
    size *= 0.5;
    
    Implicit x = Plane(centered, vec2(0.), rot * vec2(-1., 0.), color);
    Implicit y = Plane(centered, vec2(0.), rot * vec2(0., -1.), color);
    Implicit cornerA = Subtract(Max(x, y), size);
    Implicit cornerB = Subtract(Max(Negate(x), Negate(y)), size);
   
	return IntersectionEuclidean(cornerA, cornerB, 0.);
}

Implicit TriangleWaveEvenPositive(Implicit param, float period)
{
	float halfPeriod = 0.5 * period;
    float wave = mod(param.Distance, period) - halfPeriod;
	float dist = halfPeriod - abs(wave);
	vec3 grad = -sign(wave) * param.Gradient;
	return Implicit(dist, grad, param.Color);
}

float TriangleWaveEvenPositive(float param, float period)
{
	float halfPeriod = 0.5 * period;
    float wave = mod(param, period) - halfPeriod;
	return halfPeriod - abs(wave);
}
/////////////////////

Implicit nothing = Implicit(1.0e6, vec3(0), vec4(0));


const vec4 colorWarm = vec4(1., 0., 0., 1);
const vec4 colorCool = vec4(0., 0., 1., 1);

const vec4 colorBlack = vec4(0, 0, 0, 1);
const vec4 colorWhite = vec4(1);

const vec4 colorRed = vec4(1.0, 0.0, 0.0, 1.0);
const vec4 colorGreen = vec4(0.0, 1.0, 0.0, 1.0);
const vec4 colorBlue = vec4(0.0, 0.0, 1.0, 1.0);

const vec3 xDir = vec3(1, 0, 0);    
const vec3 yDir = vec3(0, 1, 0);
const vec3 zero = vec3(0);

const float bandWidth = 125.0;
const float falloff = 150.0;
const float widthThin = 3.0;
const float widthThick = 6.0;

// https://en.wikipedia.org/wiki/Alpha_compositing
Implicit Over(Implicit a, Implicit b) {
    float alpha0 = a.Color.a + b.Color.a * (1.0 - a.Color.a);
    vec4 color = (a.Color * a.Color.a + b.Color * b.Color.a * (1.0 - a.Color.a)) / alpha0;
    
    return Implicit(
        a.Distance < b.Distance ? a.Distance : b.Distance,
        a.Distance < b.Distance ? a.Gradient : b.Gradient,
        color
    );
}


// vec4 strokeImplicit(Implicit a, float width, vec4 base) {
//     vec4 color = vec4(a.Color.rgb, a.Color.a);
    
//     float interp = clamp(width * 0.5 - abs(a.Distance) / length(a.Gradient), 0.0, 1.);
//     return mix(base, color, color.a * interp);
    
//     return base;
// }

// vec4 drawImplicit(Implicit a, vec4 base) {
//     vec4 color = a.Distance > 0.0 ? colorWarm : colorCool;
//     vec4 opColor = mix(base, color, 0.1);
//     Implicit wave = TriangleWaveEvenPositive(a, bandWidth, a.Color);  

//     wave.Color.a = max(0.2, 1.0 - abs(a.Distance) / falloff);
//     opColor = strokeImplicit(wave, widthThin, opColor);
//     opColor = strokeImplicit(a, widthThick, opColor);
    
//     return opColor;
// }

// vec4 colorImplicit(Implicit a, vec4 base) {
//     vec4 opColor = mix(base, a.Color, 0.1);
//     Implicit wave = TriangleWaveEvenPositive(a, bandWidth, a.Color);  

//     wave.Color.a = max(0.2, 1.0 - abs(a.Distance) / falloff);
//     opColor = strokeImplicit(wave, widthThin, opColor);
//     opColor = strokeImplicit(a, widthThick, opColor);
    
//     return opColor;
// }


// vec4 colorDerivative(Implicit a, vec4 base) {
//     vec4 opColor = mix(base, mix(colorCool, colorWarm, -a.Distance), 0.1);
//     Implicit wave = TriangleWaveEvenPositive(a, 0.1, a.Color);  

//     opColor = strokeImplicit(wave, widthThin, opColor);
    
//     return opColor;
// }

// vec4 drawLine(Implicit a, vec4 opColor) {
//     a.Color.a = 0.75;
//     return strokeImplicit(a, 2.0, opColor);
// }

`;
