import { Accordion, Button, Container, HStack, Input, Switch, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { Dropdown, DropdownItem, IconButton, spacing, useSemanticTokens, useThemeTokens } from "@design-system";
import { ConfigSelector } from "@fragments/project/post-processor/ConfigSelector";
import { useCalibrationCurves, useModelUpdate, usePostprocessorConfig, useTranslation } from "@hooks";
import { Project as ProjectData, ProjectKnitStructure, Unit, Units } from "@models/backend";
import { Model } from "@models/project";
import { useModelsStore } from "@state";
import { recordToArray, withLoadingAndErrorHandling } from "@utils";
import { useCallback, useMemo, useState } from "react";
import { PostprocessorConfig } from "../post-processor/PostprocessorConfig";
import { StructureLabel } from "./Common";
import { RecycleIcon } from "./icons";
import { SidebarPropertiesSection } from "./SidebarPropertiesSection";
import { NumberInput } from "@design-system";

type ModelPropertiesProps = {
  modelId: Model["id"];
  project: ProjectData;
} & StyleProps;

export function SidebarModelProperties({ modelId, project, ...props }: ModelPropertiesProps) {
  const { t } = useTranslation("projectProperties.propertiesSidebar.tools");
  const { model, changeAttributes, changeUnit } = useModelUpdate({ modelId, projectId: project.id });
  const { knitStructures, machines, postprocessorDocuments, setKnitMeshDirty, yarnTokens } = useModelsStore((s) => s);
  const { collections } = useModelsStore(({ pathCollections }) => pathCollections[model.id] ?? { collections: {} });
  const arrayCollections = useMemo(() => recordToArray(collections), [collections]);
  const { generateGridCurves } = useCalibrationCurves({ project, model });
  const { handleConfigSelection, currentConfig, defaultConfig } = usePostprocessorConfig({
    model,
    project,
    postprocessorDocuments,
  });

  const semanticTokens = useSemanticTokens();
  const textClassicSecondary = semanticTokens.text.classic.secondary;
  const textClassicPrimary = semanticTokens.text.classic.primary;
  const borderColor = semanticTokens.border.border;

  const { border } = useThemeTokens();
  const unitOptions = Object.keys(Units) as Unit[];

  const defaultStructure = {
    ...knitStructures.find((structure) => structure.isDefault == true),
    yarnTokenIds: [],
  } as ProjectKnitStructure;
  const selectedStructure = model?.attributes?.knitStructure ?? defaultStructure;
  const isStitchDensityDefault = !model?.attributes?.stitchDensity;

  const modelCourse = model?.attributes?.stitchDensity?.course ?? selectedStructure?.courseDensity ?? 0;
  const modelWale = model?.attributes?.stitchDensity?.wale ?? selectedStructure?.waleDensity ?? 0;

  const modelSections = model?.attributes?.sections?.count;
  const modelColumns = model?.attributes?.columns?.count;

  const bitmapSettings = model?.attributes?.bitmapSettings;
  const includeCalibrationGrid = bitmapSettings?.includeCalibrationGrid;
  const flipBitmap = bitmapSettings?.flip;

  const [sectionsCount, setSectionsCount] = useState<string>(modelSections?.toString() ?? "10");
  const [columnsCount, setColumnsCount] = useState<string>(modelColumns?.toString() ?? "10");

  const [isLoadingGridCurves, setIsLoadingGridCurves] = useState(false);
  const [, setLoadGridCurvesError] = useState<Error | null>(null);

  // TODO: how will we handle translation of knit structure options?
  const setKnitStructure = useCallback(
    async (newStructure: ProjectKnitStructure) => {
      if (selectedStructure?.id === newStructure?.id) {
        return;
      }
      await changeAttributes({
        knitStructure: newStructure,
      });
      setKnitMeshDirty(true);
    },
    [changeAttributes],
  );

  const setKnitStructureTokenId = useCallback(
    async (index: number, tokenId: string) => {
      if (selectedStructure?.yarnTokenIds?.length && selectedStructure?.yarnTokenIds[index] === tokenId) {
        return;
      }

      const newStructure = { ...selectedStructure };
      if (newStructure.yarnTokenIds) {
        newStructure.yarnTokenIds[index] = tokenId;
      } else {
        newStructure.yarnTokenIds = [tokenId];
      }

      await changeAttributes({
        knitStructure: newStructure,
      });
      setKnitMeshDirty(false);
    },
    [changeAttributes, selectedStructure],
  );

  const setFlipBitmap = useCallback(
    async (flip: boolean) => {
      await changeAttributes({
        bitmapSettings: {
          ...bitmapSettings,
          flip,
        },
      });
      setKnitMeshDirty(true);
    },
    [changeAttributes],
  );

  const setIncludeCalibrationGrid = useCallback(
    async (includeCalibrationGrid: boolean) => {
      await changeAttributes({
        bitmapSettings: { ...bitmapSettings, includeCalibrationGrid },
      });
      setKnitMeshDirty(true);
    },
    [changeAttributes],
  );

  const saveCourse = useCallback(
    async (parsedCourse: number) => {
      if (parsedCourse === modelCourse) {
        return;
      }
      await changeAttributes({ stitchDensity: { course: parsedCourse } });
      setKnitMeshDirty(true);
    },
    [modelCourse, changeAttributes, setKnitMeshDirty],
  );

  const saveWale = useCallback(
    async (parsedWale: number) => {
      if (parsedWale === modelWale) {
        return;
      }
      await changeAttributes({ stitchDensity: { wale: parsedWale } });
      setKnitMeshDirty(true);
    },
    [modelWale, changeAttributes, setKnitMeshDirty],
  );

  const saveSectionsCount = useCallback(async () => {
    const count = Math.max(0, parseInt(sectionsCount) || 0);
    if (count == modelSections) {
      return;
    }
    setSectionsCount(count.toString());
    await changeAttributes({
      sections: {
        ...model?.attributes?.sections,
        count,
      },
    });
    await generateSectionCurves();
    setKnitMeshDirty(true);
  }, [sectionsCount, changeAttributes, model?.attributes?.sections]);

  const computeAndSetGridCurves = async (columnsCount: string, sectionsCount: string) => {
    const guideSource = arrayCollections.find((pathCollection) => pathCollection.usage === "guide");

    try {
      await withLoadingAndErrorHandling(setIsLoadingGridCurves, setLoadGridCurvesError, async () => {
        if (guideSource) {
          return await generateGridCurves(guideSource, parseInt(sectionsCount), parseInt(columnsCount));
        }
      });
    } catch (error) {
      console.warn("error generating grid curves", {
        guideSource,
        columns: columnsCount,
        sections: sectionsCount,
        error,
      });
    }
  };

  const generateColumnCurves = () => computeAndSetGridCurves(columnsCount, "0");
  const generateSectionCurves = () => computeAndSetGridCurves("0", sectionsCount);

  const saveColumnsCount = useCallback(async () => {
    const count = Math.max(0, parseInt(columnsCount) || 0);
    if (count == modelColumns) {
      return;
    }
    setColumnsCount(count.toString());
    await changeAttributes({
      columns: {
        ...model?.attributes?.columns,
        count,
      },
    });
    await generateSectionCurves();
    // note: columns don't impact the processing aside from serving as visual aids for calibration
    // same is not currently true for sections which impact goring distribution
    if (includeCalibrationGrid) {
      setKnitMeshDirty(true);
    }
  }, [columnsCount, changeAttributes, model?.attributes?.columns]);

  const { isOpen: isConfigModalOpen, onOpen: openConfigModal, onClose: closeConfigModal } = useDisclosure();

  if (!model) {
    return null;
  }

  return (
    <Container variant="classic" borderTopWidth={border.width} {...props} height="full">
      <Accordion
        variant="outline"
        borderTop="unset"
        allowMultiple
        defaultIndex={[0, 1, 2, 3]}
        paddingX={spacing.space["200"]}
        w="full"
        borderColor={borderColor}
      >
        <SidebarPropertiesSection borderTop="unset" title={t("meshSettings.label")}>
          <HStack justifyContent="space-between" w="full">
            <Text color={textClassicSecondary} variant="2xs-regular" width="50%">
              {t("meshSettings.units.label")}
            </Text>
            <Dropdown
              variant="classic-dropdown"
              label={model.unit}
              buttonStyleProps={{
                size: "sm",
                width: "50%",
                paddingX: spacing.space["300"],
                height: spacing.space["600"],
                gap: spacing.space["200"],
                paddingRight: spacing.space["100"],
              }}
              menuListStyleProps={{
                paddingY: spacing.space["100"],
              }}
            >
              {unitOptions.map((unit) => (
                <DropdownItem
                  key={unit}
                  label={t(`meshSettings.units.${unit}.label`)}
                  value={unit}
                  selectedValue={model.unit}
                  setSelectedValue={async (selectedUnit) => {
                    await changeUnit(selectedUnit);
                  }}
                />
              ))}
            </Dropdown>
          </HStack>

          <HStack justifyContent="space-between" w="100%">
            <Text color={textClassicSecondary} variant="2xs-regular" width="50%">
              {t("meshSettings.sections.label")}
            </Text>
            <Input
              size="sm"
              width="50%"
              height={spacing.space["600"]}
              type="number"
              min="0"
              placeholder="10"
              isDisabled={isLoadingGridCurves}
              value={sectionsCount}
              onChange={(e) => setSectionsCount(e.target.value)}
              onBlur={saveSectionsCount}
            />
            <IconButton
              onClick={generateSectionCurves}
              hideLabel
              isDisabled={isLoadingGridCurves}
              size="xs"
              variant="outline"
              width={spacing.space["800"]}
              leftIcon={<RecycleIcon width={spacing.space["400"]} />}
              label={t("meshSettings.sections.regenerate.label")}
              tooltipPlacement="top"
            />
          </HStack>

          <HStack justifyContent="space-between" w="100%">
            <Text color={textClassicSecondary} variant="2xs-regular" width="50%">
              {t("meshSettings.columns.label")}
            </Text>
            <Input
              size="sm"
              width="50%"
              height={spacing.space["600"]}
              type="number"
              min="0"
              placeholder="10"
              isDisabled={isLoadingGridCurves}
              value={columnsCount}
              onChange={(e) => setColumnsCount(e.target.value)}
              onBlur={saveColumnsCount}
            />
            <IconButton
              onClick={generateColumnCurves}
              hideLabel
              isDisabled={isLoadingGridCurves}
              size="xs"
              variant="outline"
              width={spacing.space["800"]}
              leftIcon={<RecycleIcon width={spacing.space["400"]} />}
              label={t("meshSettings.columns.regenerate.label")}
              tooltipPlacement="top"
            />
          </HStack>

          <HStack justifyContent="space-between" w="100%">
            <Text color={textClassicSecondary} variant="2xs-regular" width="50%">
              {t("meshSettings.medialAxis.label")}
            </Text>
            <Switch
              isChecked={!!model?.attributes?.meshSettings?.medialAxis}
              marginLeft="auto"
              size="sm"
              onChange={async (e) => {
                changeAttributes({ meshSettings: { medialAxis: e.target.checked } });
                setKnitMeshDirty(true);
              }}
            />
          </HStack>
        </SidebarPropertiesSection>

        <SidebarPropertiesSection title={t("meshSettings.knitStructure.label")}>
          <HStack justifyContent="space-between" w="full">
            <Dropdown
              variant="classic-dropdown"
              labelVariant="2xs-regular"
              label={<StructureLabel structure={selectedStructure} />}
              buttonStyleProps={{
                size: "sm",
                width: "100%",
                height: spacing.space["600"],
              }}
              menuListStyleProps={{
                paddingY: spacing.space["100"],
                maxHeight: "200px",
                overflowY: "auto",
                maxWidth: "200px",
              }}
            >
              {knitStructures.map((structure) => (
                <DropdownItem
                  variant="2xs-regular"
                  key={structure.id}
                  content={<StructureLabel structure={structure} />}
                  value={structure.id}
                  selectedValue={selectedStructure?.id}
                  setSelectedValue={() => setKnitStructure({ ...structure, yarnTokenIds: [] })}
                />
              ))}
            </Dropdown>
          </HStack>

          {selectedStructure && selectedStructure.yarnTokenCount > 0 && (
            <>
              <Text color={textClassicSecondary} variant="2xs-regular" width="50%">
                {t("meshSettings.knitStructure.yarnAssignments.label")}
              </Text>
              {Array.from({ length: selectedStructure.yarnTokenCount }, (_, i) => (
                <HStack key={i} justifyContent="space-between" w="full">
                  <Dropdown
                    variant="classic-dropdown"
                    labelVariant="2xs-regular"
                    label={
                      selectedStructure.yarnTokenIds &&
                      yarnTokens?.find((token) => token?.id == selectedStructure.yarnTokenIds[i])?.name
                    }
                    buttonStyleProps={{
                      size: "sm",
                      width: "100%",
                      height: spacing.space["600"],
                    }}
                    menuListStyleProps={{
                      paddingY: spacing.space["100"],
                      maxHeight: "200px",
                      overflowY: "auto",
                      maxWidth: "200px",
                    }}
                  >
                    {yarnTokens?.map((token) => (
                      <DropdownItem
                        key={token?.id}
                        variant="2xs-regular"
                        content={token?.name}
                        value={token?.id}
                        selectedValue={selectedStructure.yarnTokenIds && selectedStructure.yarnTokenIds[i]}
                        setSelectedValue={() => token && setKnitStructureTokenId(i, token?.id)}
                      />
                    ))}
                  </Dropdown>
                </HStack>
              ))}
            </>
          )}
        </SidebarPropertiesSection>

        <SidebarPropertiesSection title={t("stitchDensity.label")}>
          <HStack justifyContent="space-between" w="100%">
            <Text color={textClassicSecondary} variant="2xs-regular">
              {t("stitchDensity.default.knitStructure.label")}
            </Text>
            <Switch
              isChecked={isStitchDensityDefault}
              marginLeft="auto"
              size="sm"
              onChange={async (e) => {
                if (e.target.checked) {
                  await changeAttributes({ stitchDensity: null });
                  setKnitMeshDirty(true);
                } else {
                  const course = selectedStructure?.courseDensity ?? 0;
                  const wale = selectedStructure?.waleDensity ?? 0;
                  await changeAttributes({ stitchDensity: { course, wale } });
                }
              }}
            />
          </HStack>

          <HStack justifyContent="space-between" w="100%">
            <Text color={textClassicSecondary} variant="2xs-regular" width="50%">
              {t("stitchDensity.course.label")}
            </Text>
            <NumberInput
              disabled={isStitchDensityDefault}
              placeholder="0"
              width="50%"
              value={
                isStitchDensityDefault ? selectedStructure?.courseDensity?.toFixed(3) || "" : modelCourse.toFixed(3)
              }
              onCommit={async (parsedCourse) => {
                await saveCourse(parsedCourse);
              }}
            />
          </HStack>

          <HStack justifyContent="space-between" w="100%">
            <Text color={textClassicSecondary} variant="2xs-regular" width="50%">
              {t("stitchDensity.wale.label")}
            </Text>
            <NumberInput
              disabled={isStitchDensityDefault}
              placeholder="0"
              width="50%"
              value={isStitchDensityDefault ? selectedStructure?.waleDensity?.toFixed(3) || "" : modelWale.toFixed(3)}
              onCommit={async (parsedWale) => {
                await saveWale(parsedWale);
              }}
            />
          </HStack>
        </SidebarPropertiesSection>

        <SidebarPropertiesSection title={t("bitmapSettings.label")}>
          <HStack justifyContent="space-between" w="full">
            <Text color={textClassicSecondary} variant="2xs-regular">
              {t("bitmapSettings.flipBitmap.label")}
            </Text>
            <Switch
              isChecked={flipBitmap}
              marginLeft="auto"
              size="sm"
              onChange={(e) => setFlipBitmap(e.target.checked)}
            />
          </HStack>
          <HStack justifyContent="space-between" w="full">
            <Text color={textClassicSecondary} variant="2xs-regular">
              {t("bitmapSettings.calibrationGrid.label")}
            </Text>
            <Switch
              isChecked={includeCalibrationGrid}
              marginLeft="auto"
              size="sm"
              onChange={(e) => setIncludeCalibrationGrid(e.target.checked)}
            />
          </HStack>

          <VStack spacing={spacing.space["100"]} align="stretch" w="full">
            <Text color={textClassicSecondary} variant="2xs-regular">
              {t("bitmapSettings.configDropdownLabel.label")}
            </Text>
            <ConfigSelector
              selectedConfig={currentConfig ?? defaultConfig}
              postprocessorDocuments={postprocessorDocuments}
              onSelect={handleConfigSelection}
              isDisabled={false}
              maxWidth={"250px"}
            />
          </VStack>

          <Button variant="outline" size="xs" className="w-[224px] h-6 px-[8px] py-[2px]" onClick={openConfigModal}>
            <Text color={textClassicPrimary} variant="2xs-regular">
              {t("bitmapSettings.postprocessorButton.label")}
            </Text>
          </Button>

          <PostprocessorConfig
            isOpen={isConfigModalOpen}
            onClose={closeConfigModal}
            postprocessorDocuments={postprocessorDocuments}
            machines={machines}
            project={project}
            model={{
              id: model.id,
              attributes: model.attributes,
            }}
          />
        </SidebarPropertiesSection>
      </Accordion>
    </Container>
  );
}
