import { Box, Portal } from "@chakra-ui/react";
import { ColorPickerPopup, ResizableDataGrid, useSemanticTokens } from "@design-system";
import { useRowContextMenu, YarnRowContextMenu } from "@fragments";
import { Yarn } from "@models/backend";
import { omit } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { RowRendererProps, YarnLibraryProps } from "../yarn-types.ts";
import { getRowColor, useColumns } from "../YarnLibraryColumns.tsx";

export function YarnsView({ yarns, actions: { createYarn, updateYarn, deleteYarn } }: YarnLibraryProps) {
  const semanticTokens = useSemanticTokens();
  const containerRef = useRef<HTMLDivElement>(null);
  const { contextMenuPosition, selectedRowIndex, isContextMenuOpen, handleRowContextMenu, handleCloseContextMenu } =
    useRowContextMenu();

  const [openColorPickerRow, setOpenColorPickerRow] = useState<number | null>(null);
  const [colorPickerPos, setColorPickerPos] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

  useEffect(() => {
    if (!containerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {});
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const handleAddRow = useCallback(
    () =>
      createYarn({
        nameColor: "White",
        material: "",
        manufacturer: "",
        count: "",
        countType: "Denier",
        thickness: undefined,
        fiberSize: undefined,
        twistPerCm: undefined,
        colorValue: "#FFFFFF",
      }),
    [createYarn],
  );

  const handleDuplicateRow = useCallback(async () => {
    if (selectedRowIndex !== null && yarns[selectedRowIndex]) {
      await createYarn(omit(yarns[selectedRowIndex], ["id", "createdAt", "updatedAt"]));
    }
  }, [yarns, selectedRowIndex, createYarn]);

  const handleDeleteRow = useCallback(async () => {
    if (selectedRowIndex !== null && yarns[selectedRowIndex]) {
      await deleteYarn(yarns[selectedRowIndex].id);
    }
  }, [yarns, selectedRowIndex, deleteYarn]);

  const updateYarnField = useCallback(
    (id: Yarn["id"], field: keyof Omit<Yarn, "id" | "createdAt" | "updatedAt">, value: string) => {
      const yarn: Partial<Yarn> = {};

      if (field === "thickness" || field === "fiberSize" || field === "twistPerCm") {
        yarn[field] = value ? parseFloat(value) : undefined;
      } else if (field !== "member") {
        yarn[field] = value;
      }

      return updateYarn(id, yarn);
    },
    [yarns, updateYarn],
  );

  const createCellUpdateHandler =
    (id: Yarn["id"], field: keyof Omit<Yarn, "id" | "createdAt" | "updatedAt">) => (value: string) =>
      updateYarnField(id, field, value);

  const openColorPickerForRow = (rowIndex: number, target: HTMLElement) => {
    const rect = target.getBoundingClientRect();
    const dropdownHeight = 300;
    const spaceBelow = window.innerHeight - rect.bottom;
    const spaceAbove = rect.top;
    let top: number;

    if (spaceBelow < dropdownHeight && spaceAbove > spaceBelow) {
      top = rect.top - dropdownHeight;
    } else {
      top = rect.bottom;
    }

    const left = rect.left;
    setColorPickerPos({ top: top + window.scrollY, left: left + window.scrollX });
    setOpenColorPickerRow(rowIndex);
  };

  const closeColorPicker = () => {
    setOpenColorPickerRow(null);
  };

  const columns = useColumns({
    yarns,
    createCellUpdateHandler,
    openColorPickerForRow,
  });

  const RowRenderer = ({ row, children }: RowRendererProps) => (
    <Box onContextMenu={(e) => handleRowContextMenu(e, row.index)} style={{ cursor: "default" }}>
      {children}
    </Box>
  );

  return (
    <Box ref={containerRef} width="100%" height="100%" position="relative" bg={semanticTokens.surface.classic.primary}>
      <ResizableDataGrid columns={columns} data={yarns} onAddRow={handleAddRow} rowRenderer={RowRenderer} />
      <YarnRowContextMenu
        isOpen={isContextMenuOpen}
        onClose={handleCloseContextMenu}
        position={contextMenuPosition}
        onDelete={handleDeleteRow}
        onDuplicate={handleDuplicateRow}
      />
      {openColorPickerRow !== null && (
        <Portal>
          <Box
            position="absolute"
            top={`${colorPickerPos.top}px`}
            left={`${colorPickerPos.left}px`}
            zIndex={1500}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
          >
            <ColorPickerPopup
              color={getRowColor({ original: yarns[openColorPickerRow] })}
              setColor={(colorValue) => updateYarnField(yarns[openColorPickerRow].id, "colorValue", colorValue)}
            />
          </Box>
          <Box position="fixed" top={0} left={0} width="100vw" height="100vh" onClick={closeColorPicker} />
        </Portal>
      )}
    </Box>
  );
}
