import { ColumnCurve, Model, PathCollection, SectionCurve } from "@models/project";
import { CommentThreadState } from "@state";
import { arrayToRecord, computeZonesFromPathCollections, findGoringCurves } from "@utils";
import { getV3dApi } from "@utils/project/initV3dApi";
import { Geometry, Mesh3DBase, ModelHandle, Vector3 } from "@variant-tech/pattern-derivation";
import save from "file-saver";
import { File3dm } from "rhino3dm";
import {
  BufferAttribute,
  BufferGeometry,
  DoubleSide,
  Group,
  Mesh,
  MeshStandardMaterial,
  Object3D,
  Scene,
  Texture,
} from "three";
import { GLTFExporter } from "three/addons/exporters/GLTFExporter.js";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";

function arrayBufferToBlob(buffer: ArrayBuffer) {
  return new Blob([buffer], { type: "application/octet-stream" });
}

function stringToBlob(text: string) {
  return new Blob([text], { type: "text/plain" });
}

function getLoader(ext: string) {
  switch (ext) {
    case "obj":
      return new OBJLoader();
    case "stl":
      return new STLLoader();
    case "gltf":
      return new GLTFLoader();
    case "glb":
      return new GLTFLoader();
    default:
      throw new Error(`Unsupported file extension: ${ext}`);
  }
}

export async function loadBasicMesh(url: string, ext: string) {
  const loader = getLoader(ext);

  return loader
    .loadAsync(url)
    .then((obj) => {
      return obj;
    })
    .catch((err) => {
      console.log("error loading model:", { err, url });
      return null;
    });
}

function nextPower(n: number) {
  const power = Math.ceil(Math.log2(n));
  return 2 ** power;
}

export function loadImageData(bmpUrl: string): Promise<Texture> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        reject(new Error("Could not create context"));
        return;
      }
      const p2dim = nextPower(Math.max(image.width, image.height));
      ctx.canvas.width = p2dim;
      ctx.canvas.height = p2dim;
      ctx.save();
      ctx.translate(0, 0);
      ctx.scale(1, 1);
      ctx.drawImage(image, 0, p2dim - image.height);
      const texture = new Texture(canvas);
      texture.needsUpdate = true; // Important to update the texture after drawing
      ctx.restore();
      canvas.remove();
      resolve(texture);
    };
    image.src = bmpUrl;
  });
}

export async function loadKnitMesh(objUrl: string, bmpUrl: string, dataMapUrl: string, neighborMapUrl: string) {
  const group = await loadBasicMesh(objUrl, "obj");
  if (!group) {
    throw new Error("No mesh was loaded.");
  }
  const bmpTexture = await loadImageData(bmpUrl);
  const dataMapTexture = await loadImageData(dataMapUrl);
  const neighborMapTexture = await loadImageData(neighborMapUrl);
  const mesh = (group as Group).children[0] as Mesh;

  return { mesh, bmpTexture, dataMapTexture, neighborMapTexture };
}

export async function getGeoPositionsArray(url: string, ext: string) {
  const object = await loadBasicMesh(url, ext);
  const group = new Group();

  // if model is an STL, we can return the geometry directly
  if (ext === "stl") {
    return (object as BufferGeometry).attributes.position.array;
  }

  // whereas for GLTF/GLB and OBJ we need to add it to a Group which we can then traverse
  if (ext === "gltf" || ext === "glb") {
    group.add((object as GLTF).scene);
  } // ... OBJ
  else {
    group.add(object as Object3D);
  }

  let positionsArray: ArrayLike<number> = [];

  // and then return the geometry from the first mesh we find as BufferGeometry
  group.traverse((child: Object3D) => {
    if ((child as unknown) instanceof Mesh) {
      let childGeometry = (child as Mesh).geometry as BufferGeometry;
      if (childGeometry.index) {
        childGeometry = childGeometry.toNonIndexed();
      }
      positionsArray = childGeometry.attributes.position.array;
    }
  });
  return positionsArray;
}

export async function generateMesh(model: ModelHandle) {
  return getMeshFromGeometry(await getV3dApi().generateMesh(model));
}

export function getMeshFromGeometry({ vertices, faces }: Geometry) {
  const bufferGeometry = new BufferGeometry();

  bufferGeometry.setIndex(Array.from(faces));
  bufferGeometry.setAttribute("position", new BufferAttribute(vertices, 3));
  bufferGeometry.computeVertexNormals();

  return new Mesh(bufferGeometry, new MeshStandardMaterial({ color: "lightgray", side: DoubleSide }));
}

export function getDummyMeshFromGeometry() {
  const bufferGeometry = new BufferGeometry();

  const vertices = new Float32Array([0, 0, 0, 0, 1, 0, 1, 0, 0]);
  const faces = new Uint32Array([0, 1, 2]);

  bufferGeometry.setIndex(Array.from(faces));
  bufferGeometry.setAttribute("position", new BufferAttribute(vertices, 3));
  bufferGeometry.computeVertexNormals();

  return new Mesh(bufferGeometry, new MeshStandardMaterial({ color: "lightgray", side: DoubleSide }));
}

export async function exportMeshToGLB(mesh: Mesh, name = "scene", binary = true, debug = false): Promise<File> {
  const exportScene = new Scene().add(mesh);

  const gltfExporter = new GLTFExporter();

  const options = {
    trs: false,
    onlyVisible: true,
    binary,
    maxTextureSize: 4096,
  };

  return new Promise<File>((resolve, reject) => {
    gltfExporter.parse(
      exportScene,
      function (result) {
        let blob;
        let filename;
        if (result instanceof ArrayBuffer) {
          blob = arrayBufferToBlob(result);
          filename = `${name}.glb`;
        } else {
          const output = JSON.stringify(result, null, 2);
          blob = stringToBlob(output);
          filename = `${name}.gltf`;
        }
        const file = new File([blob], filename, { type: blob.type });
        if (debug) {
          save(file, filename);
        }
        resolve(file);
      },
      reject,
      options,
    );
  });
}

export async function initMesh(
  bufferedPositions: ArrayLike<number>,
  {
    flipNormal,
  }: {
    flipNormal: boolean;
  },
): Promise<Mesh3DBase> {
  return (await getV3dApi().createModel(bufferedPositions as Float32Array, { flipNormal })) as Mesh3DBase;
}

export async function importMesh(
  path: string,
  fileName: string,
  options: {
    flipNormal: boolean;
  },
): Promise<Mesh3DBase> {
  const ext = fileName.split(".").pop() as string;
  const bufferedPositions = await getGeoPositionsArray(path, ext);
  return await initMesh(bufferedPositions, options);
}

export async function scaleMesh(
  model: Model,
  {
    scale,
    pathCollections,
    columnCurves,
    sectionCurves,
    commentThreads,
  }: {
    scale: number;
    pathCollections: PathCollection[];
    columnCurves: ColumnCurve[];
    sectionCurves: SectionCurve[];
    commentThreads: CommentThreadState[];
  },
): Promise<{
  pathCollections: PathCollection[];
  columnCurves: ColumnCurve[];
  sectionCurves: SectionCurve[];
  commentThreads: CommentThreadState[];
}> {
  const points = [
    ...pathCollections.map((p) => ({ id: p.id, points: p.points })),
    ...columnCurves.map((c) => ({ id: c.id, points: [c.point] })),
    ...sectionCurves.map((s) => ({ id: s.id, points: [s.point] })),
    ...commentThreads.map((c) => ({ id: c.id, points: [c.position] })),
  ];

  const result: Record<
    string,
    {
      id: string;
      points: Vector3[];
    }
  > = arrayToRecord(
    await getV3dApi().scaleModel(model.mesh3DBase, { scale: [scale, scale, scale], points }),
    (r) => r.id,
  );

  return {
    commentThreads: commentThreads.map((c) => ({ ...c, data: { ...c.data, position: result[c.id].points[0] } })),
    columnCurves: columnCurves.map((c) => ({ ...c, point: result[c.id].points[0] })),
    pathCollections: pathCollections.map((p) => ({ ...p, points: result[p.id].points })),
    sectionCurves: sectionCurves.map((s) => ({ ...s, point: result[s.id].points[0] })),
  };
}

export async function getMeshPayload(
  model: Model,
  guideSource: PathCollection,
  pathCollections: PathCollection[],
  columnAnchors: Vector3[],
  sectionAnchors: Vector3[],
  stitchDensity: {
    wale: number;
    course: number;
  },
) {
  const guideCurve = {
    points: guideSource.points,
    controlVectors: guideSource.controlVectors,
  };
  const zones = computeZonesFromPathCollections(pathCollections, model);
  const goring = findGoringCurves(pathCollections);
  return await getV3dApi().getMeshPayload(model.mesh3DBase, {
    guideCurve,
    zones,
    goring,
    columnAnchors,
    sectionAnchors,
    stitchDensity,
    medialAxis: model.attributes?.meshSettings?.medialAxis ?? false,
  });
}

export function load3dmFile(file: File): Promise<File3dm> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = function (evt) {
      if (evt.target?.result) {
        const array = new Uint8Array(evt.target.result as ArrayBuffer);
        // @ts-expect-error: Incorrect type definition in module.
        const file3dm = window.rhino!.File3dm.fromByteArray(array);
        resolve(file3dm);
      }
    };
    reader.onerror = function () {
      reject(new Error(`Could not read ${file.name}`));
    };
  });
}
