import { VStack } from "@chakra-ui/react";
import { Popover, PopoverTrigger, spacing, useSemanticTokens } from "@design-system";
import { AddComment, CommentPopup, ThreadCallout, ThreadComment } from "@fragments";
import { CommentThread } from "@models/backend";
import { useModelsStore } from "@state";

export function Thread({
  thread,
  onAddComment,
  onDeleteComment,
  onEditComment,
}: {
  thread: CommentThread;
  onAddComment: (content: string) => void;
  onDeleteComment: (id: string) => void;
  onEditComment: (id: string, content: string) => void;
}) {
  const { getCommentThread, setCommentThread } = useModelsStore();
  const threadId = thread.id;
  const state = getCommentThread(threadId);
  const semanticTokens = useSemanticTokens();

  if (!state?.projection) {
    return null;
  }

  return (
    <CommentPopup
      position={state.projection}
      key={threadId}
      opacity={!state.occluded || state.visibility === "expanded" ? 1 : 0.5}
    >
      <PopoverTrigger
        p={0}
        mt="-2.5rem"
        width="2.5rem"
        height="2.5rem"
        content={<ThreadCallout state={state} thread={thread} width="2.5rem" height="2.5rem" />}
        isOpen={state.visibility === "expanded"}
        onOpen={() => setCommentThread({ ...state, visibility: "expanded" })}
        onClose={() => setCommentThread({ ...state, visibility: "minimized" })}
        variant="transparent"
      >
        <Popover
          closable={true}
          onClose={() => setCommentThread({ ...state, visibility: "minimized" })}
          p={spacing.space[400]}
          pt={0}
          w="20rem"
          background={semanticTokens.surface.classic["primary-opacity"]}
          gap={spacing.space[400]}
        >
          <VStack gap={spacing.space[400]} maxHeight={"min(25vh, 20rem)"} overflowY="auto">
            {thread.comments?.map((comment) => (
              <ThreadComment comment={comment} onEdit={onEditComment} onDelete={onDeleteComment} />
            ))}
          </VStack>
          <AddComment onAddComment={onAddComment} />
        </Popover>
      </PopoverTrigger>
    </CommentPopup>
  );
}
