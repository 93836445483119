import { Accordion, Container, HStack, Text } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { spacing, useSemanticTokens, useThemeTokens } from "@design-system";
import { useTranslation } from "@hooks";
import { Project as ProjectData } from "@models/backend";
import { SidebarPropertiesSection } from "./SidebarPropertiesSection";
import { useModelsStore } from "@state/models";
import { useShallow } from "zustand/react/shallow";
import { toPrecision } from "@utils/project";

interface ColumnPropertiesProps extends StyleProps {
  project: ProjectData;
  modelId: string;
  columnId: string;
}

export function SidebarColumnProperties({ modelId, columnId, ...props }: ColumnPropertiesProps) {
  const { t: to } = useTranslation("projectProperties.propertiesSidebar.tools");
  const semanticTokens = useSemanticTokens();
  const textClassicSecondary = semanticTokens.text.classic.secondary;
  const { border } = useThemeTokens();
  const { columns, models, columnsInfo } = useModelsStore(
    useShallow(({ columns, models, columnsInfo }) => ({
      columns,
      models,
      columnsInfo,
    })),
  );
  const textClassicPrimary = semanticTokens.text.classic.primary;
  const model = models.find((m) => m.id === modelId);
  const columnAnchors = columns[modelId]?.columnCurves || [];
  const column = columnAnchors.find((column: { id: string }) => column.id === columnId);

  if (!column || !model) {
    return null;
  }
  const columnInfo = columnsInfo[column.id];
  return (
    <Container variant="classic" borderTopWidth={border.width} {...props}>
      <Accordion variant="outline" allowMultiple defaultIndex={[0]} paddingX={spacing.space["200"]} w="full">
        {columnInfo ? (
          <SidebarPropertiesSection borderTop="unset" title={to("curveInfo.label")}>
            <HStack justifyContent="space-between" w={"100%"}>
              <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
                {to("curveInfo.total")}
              </Text>
              <Text color={textClassicPrimary} variant="2xs-regular" w="50%">
                {toPrecision(columnInfo.length ?? 0.0, 3)}
              </Text>
            </HStack>
          </SidebarPropertiesSection>
        ) : null}
      </Accordion>
    </Container>
  );
}
