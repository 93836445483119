export const schematicFragShader = /* glsl */ `
  precision highp float;
  varying vec2 vUv;
  varying float vCameraDistance;

  uniform sampler2D bmpTexture;
  uniform sampler2D dataMapTexture;
  uniform sampler2D neighborMapTexture;
  uniform sampler2D knitCellTexture;
  uniform vec4 colorBackground;
  uniform float cameraNear;
  uniform float cameraFar;

  float aaDist = 0.02;
  
  uniform float p2dim;
  uniform int displayMode;
  uniform bool displayEdges;
  uniform bool useColorFromStructure;
  uniform vec4 zoneColors[NUM_STRUCTURE_COLORS];

  uniform float animation;

  uniform float legHalfWidth;
  uniform float legHalfPadding;
  uniform float loopHalfWidth;
  uniform float loopHalfPaddingX;
  uniform float loopHalfPaddingY;
  uniform float loopHalfBend;
  uniform float loopGoringOffset;
  uniform float loopGoringAAScalar;
  uniform float farSideScale;
  uniform float farSideDarkening;
  uniform float distBlur;
  uniform bool transparency;

  const float floatHalfWidth = 0.10;
  const float floatBend = 0.05;

  struct KnitData {
    int knitType;
    int yarn0;
    int yarn1;
    int yarn2;
    int yarn3;
    int yarn4;
  };

  vec2 legStart, legEnd, halfBend, loopStart, loopEnd, loopMid;
  vec4 attenuation;
  void initializeGlobals() {
    legStart = vec2(legHalfWidth + legHalfPadding, legHalfWidth + legHalfPadding + 0.5);
    legEnd = vec2(0.5, 2.0) - legStart;

    halfBend = vec2(0.0, loopHalfBend);
    loopStart = vec2(loopHalfWidth + loopHalfPaddingX, loopHalfPaddingY) + halfBend;
    loopEnd = vec2(1.0 - (loopHalfWidth + loopHalfPaddingX), loopHalfPaddingY) + halfBend;
    loopMid = (loopStart + loopEnd) * 0.5 - halfBend;

    attenuation = vec4(vec3(farSideDarkening), 1.0);
  }

  struct YarnStyle {
    vec4 color;
    float thickness;
    float wavelength;
    int styleType;
    int decorationType;
    float decorationSize;
    vec4 decorationColor;
  };

  uniform YarnStyle yarns[NUM_YARNS];

  vec4 drawFill(Implicit a, vec4 color) {      
    float clamped = aaDist - clamp(a.Distance / length(a.Gradient), -aaDist, aaDist);
    clamped /= 2.0 * aaDist;
    return mix(color, a.Color, clamped);
  }

  struct Yarn {
    Implicit implicit;
    float radius;
    float height;
    float param;
  };

  const float legHeight = 1.0;
  const float loopHeight = 0.0;

  Yarn Min(Yarn a, Yarn b) {
    if (a.implicit.Distance - a.radius < b.implicit.Distance - b.radius) {
      return Yarn(a.implicit, a.radius, a.height, a.param);
    }

    return Yarn(b.implicit, b.radius, b.height, b.param);
  }

  Yarn Max(Yarn a, Yarn b) {
    if (a.implicit.Distance - a.radius > b.implicit.Distance - b.radius) {
      return Yarn(a.implicit, a.radius, a.height, a.param);
    }

    return Yarn(b.implicit, b.radius, b.height, b.param);
  }

  Yarn Union(Yarn a, Yarn b) {
    if (abs(a.implicit.Distance) - a.radius < abs(b.implicit.Distance) - b.radius) {
      return Yarn(a.implicit, a.radius, a.height, a.param);
    }

    return Yarn(b.implicit, b.radius, b.height, b.param);
  }

  // https://en.wikipedia.org/wiki/Alpha_compositing
  Yarn Over(Yarn a, Yarn b) {
      // float alpha0 = a.implicit.Color.a + b.implicit.Color.a * (1.0 - a.implicit.Color.a);
      // vec4 color = (a.implicit.Color * a.implicit.Color.a + b.implicit.Color * b.implicit.Color.a * (1.0 - a.implicit.Color.a)) / alpha0;
      vec4 color = (a.implicit.Color * a.implicit.Color.a + b.implicit.Color * (1.0 - a.implicit.Color.a)) ;
      
      bool isA = a.implicit.Distance < 0.0;
      Implicit body = Implicit(
          isA ? a.implicit.Distance : b.implicit.Distance,
          isA ? a.implicit.Gradient : b.implicit.Gradient,
          color
      );

      return Yarn(
          body,
          isA ? a.radius : b.radius,
          isA ? a.height : b.height,
          isA ? a.param : b.param
      );
  }

  Yarn Composite(Yarn a, Yarn b, bool isBack) {
    if (abs(a.implicit.Distance) > a.radius - aaDist || abs(b.implicit.Distance) > b.radius - aaDist )
      return Union(a, b);

    if (isBack == (a.height > b.height)) 
      return Yarn(a.implicit, a.radius, a.height, a.param);

    return Yarn(b.implicit, b.radius, b.height, b.param);

    // if (isBack == (a.height > b.height)) 
    //   return Over(a, b);

    // return Over(b, a);
  }  
  


  Yarn ApplyDepth(Yarn yarn, bool isBack) {
    float heightRatio = (yarn.height - loopHeight) / (legHeight - loopHeight);
    heightRatio = yarn.height + 0.5 + (0.5 * heightRatio * (isBack ? 1.0 : -1.0));

    yarn.radius *= mix(farSideScale, 1.0, heightRatio);
    yarn.implicit.Color = mix(yarn.implicit.Color * attenuation, yarn.implicit.Color, heightRatio);
    return yarn;
  }

  vec4 ApplyShadow(Implicit body, float dist, vec4 color) {
    float shadowRamp = clamp(-body.Distance / dist, 0.0, 1.0);
    body.Color *= mix(colorBlack, colorWhite, sqrt(shadowRamp));
    body.Color.a *= mix(1.0, 0.0, body.Distance / aaDist);
    return drawFill(body, color);
  }

  Yarn StyleYarn(Yarn yarn, YarnStyle style) {
    vec4 color = colorBlack; //colorBackground;
    color.a = 0.0;
    yarn.radius *= style.thickness;

    vec2 p = vec2(mod(yarn.param + animation * style.wavelength, style.wavelength), yarn.implicit.Distance);
    vec3 paramGrad = vec3(yarn.implicit.Gradient.y, -yarn.implicit.Gradient.x, yarn.implicit.Gradient.z);
    Implicit paramBody = Implicit(p.x, paramGrad, style.color);

    Implicit yarnBody = Implicit(p.y, vec3(0.0, 1.0, 0.0), style.color);
    Implicit yarnSimple = Subtract(Abs(yarnBody), yarn.radius);

    Yarn threadYarn;
    float amplitude = yarn.radius * 0.5;
    float wPos = PI2 * p.x / style.wavelength;
    float yOffset = amplitude * sin(wPos);
    Implicit yarnWavy = Implicit(p.y - yOffset, vec3(0.0, 1.0, 0.0), style.color);
    threadYarn = Yarn(
      yarnWavy, 
      yarn.height - amplitude * cos(wPos),
      amplitude, 
      p.x
    );

    switch(style.styleType) {
      case 1:  // sinusoidal
        yarnBody = Subtract(Abs(yarnWavy), amplitude + aaDist);
        break;

      case 2:  // double sinusoidal
        float wOffset = wPos + PI;
        yOffset = amplitude * sin(wOffset);
        yarnWavy = Implicit(p.y - yOffset, vec3(0.0, 1.0, 0.0), style.color);

        threadYarn = Union(threadYarn, Yarn(
          yarnWavy, 
          yarn.height - amplitude * cos(wOffset),
          amplitude, 
          p.x
        ));

        yarnBody = threadYarn.implicit;
        yarnBody = Subtract(Abs(yarnBody), amplitude + aaDist);
        break;

      default:  // case 0: simple
        yarnBody = yarnSimple;
        break;
    }

    color = ApplyShadow(yarnBody, yarn.radius, color);
    // color.a = style.color.a;

#if 0 // check SDF to curve    
    if (yarn.implicit.Distance < 0.0) 
      color *= attenuation;
#endif

    // Yarn decoration
    Implicit decoration;
    float decoSize = style.decorationSize * yarn.radius / style.thickness;

#if 0 // visualize parameterization
    decoSize = 0.04 * yarn.param;
    style.decorationType = 1;
#endif

    switch(style.decorationType) {
      case 1:  // circles
        decoration = Subtract(EuclideanNorm(Negate(TriangleWaveEvenPositive(paramBody, style.wavelength)), yarn.implicit), decoSize);
        break;

      case 2: // diamonds
        decoration = Subtract(CityblockNorm(TriangleWaveEvenPositive(paramBody, style.wavelength), yarn.implicit), decoSize);
        break;

      case 3: // square
        decoration = Subtract(Max(TriangleWaveEvenPositive(paramBody, style.wavelength), Abs(yarn.implicit)), decoSize);
        break;

      case 4: // inner line
        decoration = Subtract(Abs(Subtract(yarn.implicit, style.wavelength * 0.5 * yarn.radius)), decoSize);
        break;

      case 5: // stretched diamonds
        decoration = Multiply(Subtract(Abs(yarn.implicit), TriangleWaveEvenPositive(paramBody.Distance, style.wavelength) * 2.0 * decoSize), 0.5);
        break;

      case 6: // arrows
        float modP = mod(paramBody.Distance, style.wavelength);
        decoration = Multiply(Max(modP - style.wavelength, Subtract(Abs(yarn.implicit), modP * 2.0 * decoSize)), 0.5);
        break;

      default:  // 0 - no decoration
        yarn.implicit.Color = color;
        return yarn;
    }

    decoration.Color = style.decorationColor;
    yarn.implicit.Color = ApplyShadow(decoration, decoSize * 0.5, color);
    return yarn;
  }

  Yarn knitLoop(vec2 p, YarnStyle style, bool isFlipped) {
    vec2 pWave = vec2(0.5 - abs(mod(p.x, 1.0) - 0.5), p.y);  // positive triangle wave
    float param, loopRadius, loopSpan;
  
    // loop arcs
    Implicit loopBody = ArcThreePointHalfSpace(pWave, loopEnd, loopMid, loopStart, style.color, param, loopRadius, loopSpan);
    Yarn loopYarn = Yarn(Negate(loopBody), loopHalfWidth, loopHeight, param);
    loopYarn.param = -(loopSpan * 0.5 + loopYarn.param) * loopRadius;
    float loopLength = loopSpan * 0.5 * loopRadius;

    // connectors
    float connectorLength;
    Implicit connectorBody = HalfSpace(pWave, loopStart, legStart, style.color, param, connectorLength);
    float paramNorm = clamp(param / connectorLength, 0.0, 1.0);
    Yarn connectorYarn = Yarn(connectorBody, mix(loopHalfWidth, legHalfWidth, paramNorm), mix(loopHeight, legHeight, paramNorm), param);
    connectorYarn.param += loopLength;

    // legs
    float legLength;
    Implicit legBody = HalfSpace(pWave, legStart, legEnd, style.color, param, legLength);
    legLength *= 0.5;  // leg is draw symmetric across seam
    Yarn legYarn = Yarn(legBody, legHalfWidth, legHeight, param);
    legYarn.param += loopLength + connectorLength;

    // merged
    Yarn merged = Min(connectorYarn, Min(legYarn, loopYarn));
    float totalLength = loopLength + connectorLength + legLength;
    float roundLength = ceil(totalLength / style.wavelength) * style.wavelength;
    merged.param *= roundLength / totalLength;

    merged.param = isFlipped ? roundLength - merged.param : merged.param;
    return merged;
  }

  Yarn knitGoringLeg(vec2 p, vec2 mid, vec2 end, YarnStyle style, bool isLower, bool isShort) {
    float legParam, legLength;
    Implicit legBody = HalfSpace(p, mid, end, style.color, legParam, legLength);

    Yarn legYarn = Yarn(legBody, legHalfWidth, legHeight, legParam);
    float roundLength = ceil(legLength * 2.0 / style.wavelength) * style.wavelength * 0.5;  // we have two of them, so we can have an odd or even number total
    legYarn.param *= roundLength / legLength;

    legYarn.param = isLower == isShort ? roundLength - legYarn.param : roundLength + legYarn.param;  // make one section
    return legYarn;
  }

  Yarn knitGoring(vec2 p, YarnStyle style, bool isLower, bool isReversed, bool isBack) {
    // goring legs
    vec2 offsetMid = (legStart + legEnd) * 0.5;
    vec2 offsetEndLong = offsetMid - vec2(0.0, 1.0 - offsetMid.x);
    vec2 offsetEndShort = offsetMid - vec2(0.0, offsetMid.x);

    vec2 pLeg = p;
    if (isLower) 
      pLeg.y = 1.0 - pLeg.y;

    vec2 pMirror = vec2(1.0 - pLeg.x, pLeg.y);
    vec2 pLong = isReversed ? pLeg : pMirror;
    vec2 pShort = isReversed ? pMirror : pLeg;

    Yarn legLongYarn = knitGoringLeg(pLong, offsetMid, offsetEndLong, style, isLower, false);
    Yarn legShortYarn = knitGoringLeg(pShort, offsetMid, offsetEndShort, style, isLower, true);
    Yarn legYarn = Union(legLongYarn, legShortYarn);

    if (isReversed)
      legYarn.implicit = Negate(legYarn.implicit);

    // goring loop
    p = isReversed ? (vec2(0.5, 1.0) - vec2(-p.x, p.y)) : (vec2(1.5, 1.0) - p);  // zero on appropriate edge

    vec2 xOffset = isLower ? vec2(loopGoringOffset, loopGoringOffset * 1.41) : vec2(loopGoringOffset, 0.0);
    vec2 endH = loopMid + xOffset;
    vec2 endL = vec2(endH.x, isLower ? 1.0 : 0.0);

    float horizParam, horizLength;
    Implicit horizBody = HalfSpace(p, loopMid, endH, style.color, horizParam, horizLength);
    Yarn horizYarn = Yarn(horizBody, loopHalfWidth, loopHeight, horizParam);

    float vertParam, vertLength;
    Implicit vertBody = HalfSpace(p, endH, endL, style.color, vertParam, vertLength);
    Yarn vertYarn = Yarn(vertBody, loopHalfWidth, loopHeight, vertParam);
    vertYarn.param += horizLength;

    Yarn loopYarn;
    if (isLower)
      loopYarn = Max(horizYarn, vertYarn);
    else {
      loopYarn = Min(horizYarn, vertYarn);
      loopYarn.implicit = Negate(loopYarn.implicit);
    }

    float totalLength = horizLength + vertLength;
    float roundLength = ceil(totalLength / style.wavelength) * style.wavelength;
    loopYarn.param *= roundLength / totalLength;

    loopYarn.param = isLower ? loopYarn.param : roundLength - loopYarn.param;

    legYarn = StyleYarn(legYarn, style);
    loopYarn = StyleYarn(loopYarn, style);
    return Composite(ApplyDepth(legYarn, isBack), ApplyDepth(loopYarn, isBack), isBack);
  }

  Yarn knitFloat(vec2 p, YarnStyle style, bool isReturn) {
    float floatParam, floatLength, floatRadius, floatSpan;
    vec2 start = vec2(0.5, 1.0) - loopMid;
    vec2 end = start + vec2(1.0, 0.0);
    Implicit floatBody = HalfSpace(p, start, end, style.color, floatParam, floatLength);

    // vec2 arcMid = 0.5 * (start + end) + vec2(0.0, floatBend);
    // Implicit floatBody = ArcThreePointHalfSpace(p, end, arcMid, start, style.color, floatParam, floatRadius, floatSpan);
    // floatParam *= floatRadius;

    if (isReturn)
      floatBody = Negate(floatBody);

    Yarn floatYarn = Yarn(floatBody, floatHalfWidth, loopHeight, floatParam);
    float roundLength = ceil(floatLength / style.wavelength) * style.wavelength;  
    floatYarn.param *= roundLength / floatLength;

    float interp = (floatYarn.param / roundLength - 0.5) * 2.0;
    floatYarn.height -= interp * interp;

    floatYarn.param = isReturn ? roundLength - floatYarn.param : floatYarn.param;

    return floatYarn;
  }

  Yarn yarnLoop(vec2 p, vec2 pGlobal, bool isBack, bool isReturn, bool isGoring, bool isGoringLower, bool isGoringReversed, YarnStyle style, bool isBackB, YarnStyle styleB) {
    // if (styleB.color == colorBlack) // hack to hide extra half edge
    //   styleB.color = style.color;

    bool flipParity = p.x < 0.5 == isReturn;

    Yarn yarnComposite, yarnB;
    if (!isGoring) {
      yarnComposite = knitLoop(p, style, flipParity);
      yarnB = knitLoop(vec2(0.5, 1.0) - p, styleB, flipParity);

      if (isReturn) {
        yarnB.implicit = Negate(yarnB.implicit);
        yarnComposite.implicit = Negate(yarnComposite.implicit);
      } 

      yarnComposite = StyleYarn(yarnComposite, style);
      yarnB = StyleYarn(yarnB, styleB);

      // When front back switches vertically, blend the heights 
      if (isBack != isBackB) {
        float interp = p.y;
        float height = mix(legHeight * (isBack ? 1.0/3.0 : 3.0), yarnB.height, interp);  // TODO make more intuitive
        yarnB = Yarn(yarnB.implicit, yarnB.radius, height, yarnB.param);
        // yarnB.implicit.Color = mix(colorRed, colorGreen, interp);
      }

      yarnComposite = Composite(ApplyDepth(yarnComposite, isBack), ApplyDepth(yarnB, isBack), isBack);

    } else {
      if (isGoringLower)
        yarnComposite = knitGoring(p, styleB, isGoringLower, isGoringReversed, isBack);
      else
        yarnComposite = knitGoring(p, styleB, isGoringLower, isGoringReversed, isBack);

      if (isGoringReversed)
        yarnComposite.implicit = Negate(yarnComposite.implicit);
    }

    return yarnComposite;
  }

  Yarn yarnFloat(vec2 p, bool isBack, bool isReturn, bool isGoring, bool isGoringLower, bool isGoringReversed, YarnStyle style) {
    Yarn yarn = knitFloat(p, style, isReturn);
    yarn = StyleYarn(yarn, style);
    return ApplyDepth(yarn, isBack);
  }

  bool IsBack(int knitType) {
    switch (knitType) {
      case 0: // front
        return false;
        
      case 1: // back
        return true;
    }

    return false;
  }


  const int NO_FLOAT = 15;
  vec4 drawKnitCell(vec2 p, vec2 pGlobal, KnitData knitData, KnitData knitDataBelow, bool isBack, bool isReturn, bool isGoring, bool isGoringLower, bool isGoringReversed, vec4 colorFace, vec4 colorBelow) {
    isBack = gl_FrontFacing != IsBack(knitData.knitType);
    bool isBackB = gl_FrontFacing != IsBack(knitDataBelow.knitType);

    YarnStyle style0 = yarns[knitData.yarn0];
    YarnStyle styleB0 = yarns[knitDataBelow.yarn0];
    
    if (useColorFromStructure) {
      style0.color = colorFace; 
      style0.thickness = 1.0; 
      style0.styleType = 0; 
      style0.decorationType = 0; 
      knitData.yarn1 = NO_FLOAT; // disable float
      styleB0.color = colorBelow;
      styleB0.thickness = 1.0; 
      styleB0.styleType = 0; 
      styleB0.decorationType = 0; 
    }

    Yarn yarnComposite = yarnLoop(p, pGlobal, isBack, isReturn, isGoring, isGoringLower, isGoringReversed, style0, isBackB, styleB0);
    
    if (!isGoring && knitData.yarn1 != NO_FLOAT) {
      Yarn float0 = yarnFloat(p, isBack, isReturn, isGoring, isGoringLower, isGoringReversed, yarns[knitDataBelow.yarn1]);

      yarnComposite = Composite(yarnComposite, float0, isBack);
    }

    if (transparency) {
      return mix(yarnComposite.implicit.Color, style0.color, clamp(aaDist, 0.0, distBlur));
    }
    
    return yarnComposite.implicit.Color;
  }

  ivec2 decodeTwoIntegers(vec3 color) {
    int r = int(color.r * 255.0);
    int g = int(color.g * 255.0);
    int b = int(color.b * 255.0);

    int a = (r << 4) | (g >> 4);
    int b_val = ((g & 0xF) << 8) | b;

    return ivec2(a, b_val);
  }

  // Function to decode the packed RGB values from a texture
  KnitData decodeKnitData(vec4 encodedData) {
    KnitData data;

    int r = int(encodedData.r * 255.0);
    int g = int(encodedData.g * 255.0);
    int b = int(encodedData.b * 255.0);

    data.knitType = (r >> 4) & 0xF; // Extract 4 bits for KnitCellType
    data.yarn0 = r & 0xF;           // Extract 4 bits for Yarn0
    data.yarn1 = (g >> 4) & 0xF;     // Extract 4 bits for Yarn1
    data.yarn2 = g & 0xF;           // Extract 4 bits for Yarn2
    data.yarn3 = (b >> 4) & 0xF;     // Extract 4 bits for Yarn3
    data.yarn4 = b & 0xF;           // Extract 4 bits for Yarn4

    return data;
  }
  
  ivec3 decodeRGB(vec3 encodedRGB) {
    int r = int(encodedRGB.r * 255.0);  // Convert normalized [0,1] to [0,255]
    int g = int(encodedRGB.g * 255.0);

    bool isGoringLeft = (r & 2) != 0;  // Extract bit 1
    bool isGoringRight = (r & 1) != 0;  // Extract bit 0
    int zone = g; // Extract high 4 bits of G, shift to range [1,16]

    return ivec3(isGoringLeft ? 1 : 0, isGoringRight ? 1 : 0, zone);
  }

  // naming this as upper goring since the definition if 'isGoringLower'
  // in the shader refers to the second row of the goring where the
  // yarn would turn back in into the next row, whereas in the the kernel
  // this would be referred to as the upper goring
  ivec3 decodeUpperGoring(vec3 encodedRGB) {
    int b = int(encodedRGB.b * 255.0);  // Convert normalized [0,1] to [0,255]
    bool isUpperGoring = b != 0; 
    return ivec3(0, 0, isUpperGoring ? 1 : 0);
  }

  void main() {
    initializeGlobals();

    vec2 pGlobal = vUv * p2dim;
    vec2 p = fract(pGlobal);

    vec2 tiledUv = floor(pGlobal) + vec2(0.5);

    vec4 encodedColor = texture2D(neighborMapTexture, tiledUv / p2dim);
    ivec2 values = decodeTwoIntegers(encodedColor.rgb);
    int a = values.x;
    int b = values.y;
    vec2 tiledUvBelow = vec2(a, b) + vec2(0.5, 0.5);
    
    vec4 encodedColorData = texture2D(dataMapTexture, tiledUv / p2dim); 
    vec4 encodedColorDataBelow = texture2D(dataMapTexture, tiledUvBelow / p2dim);

    // decode the RGB values to get the boolean values and zone
    ivec3 colorData = decodeRGB(encodedColorData.rgb);
    ivec3 colorDataBelow = decodeRGB(encodedColorDataBelow.rgb);

    vec2 tiledUvFaceLeft = floor(pGlobal) + vec2(-0.5, 0.5); 
    vec4 colorFaceLeft = texture2D(neighborMapTexture, tiledUvFaceLeft / p2dim);  

    vec2 tiledUvFaceRight = floor(pGlobal) + vec2(1.5, 0.5); 
    vec4 colorFaceRight = texture2D(neighborMapTexture, tiledUvFaceRight / p2dim);

    float depth = clamp(vCameraDistance / 100.0, 0.0, 1.0);
    aaDist = clamp(depth * 5.0 - 0.25, 0.01, 1.0);

    bool isGoring = colorData.x == 1 || colorData.y == 1;
    bool isGoringLower = decodeUpperGoring(encodedColorData.rgb).z == 1;
    bool isGoringReversed = colorData.y == 1;

    int zoneIndex = colorData.z;
    int zoneIndexBelow = colorDataBelow.z;

    // decode the knitCellTexture to get deduce the knitCell type and yarn
    vec4 encodedKnitCell = texture2D(knitCellTexture, tiledUv / p2dim);
    KnitData knitData = decodeKnitData(encodedKnitCell);

    vec4 encodedKnitCellBelow = texture2D(knitCellTexture, tiledUvBelow / p2dim);
    KnitData knitDataBelow = decodeKnitData(encodedKnitCellBelow);

    if (isGoring) {  // The upper goring has skewed UVs due to the shared vertex. 
      // aaDist *= loopGoringAAScalar;
      if (isGoringLower && isGoringReversed)  
        p.y -= p.x;        
      else if (!isGoringLower && !isGoringReversed)  
        p.y -= p.x - 1.0;             
    }

    vec4 colorFace = zoneColors[zoneIndex - 1];
    vec4 colorBelow = zoneColors[zoneIndexBelow - 1];


    switch (displayMode) {
      case 0:
        gl_FragColor = colorFace;
        break;

      case 1:
        gl_FragColor = drawKnitCell(p, pGlobal, knitData, knitDataBelow, gl_FrontFacing, mod(pGlobal.y, 2.0) < 1.0, isGoring, isGoringLower, isGoringReversed, colorFace, colorBelow);
        if (transparency && gl_FragColor.a < 0.5) discard; // don't draw black background within a tolerance
        break;

      default:
        break;
    }

    if (displayEdges) {
      aaDist = depth * 0.5;
      vec4 colorMult = mix(colorFace, colorBlack, 0.25);
      float blend = clamp(1.0 - depth * 3.0, 0.0, 1.0);

#if 0 // draw UV grid for debugging      
      Implicit boxDust = Subtract(-aaDist, Rectangle(mod(p, 0.25), vec2(0.05), vec2(0.2), vec4(p.x, 0.0, p.y, 1.0)));
      gl_FragColor = drawFill(boxDust, colorBlack);

      // if (isGoringLower)
      //   gl_FragColor.r *= 0.5;

      // if (isGoringReversed)
      //   gl_FragColor.g *= 0.5;
#endif

      Implicit box = Subtract(-aaDist, Rectangle(p, vec2(0.0), vec2(1.0), mix(colorFace, colorMult, blend)));
      gl_FragColor = drawFill(box, gl_FragColor);
    }

  }
`;
