import { useAuth } from "@auth";
import { Box, Flex, useColorMode, VStack } from "@chakra-ui/react";
import {
  AdminHeader,
  AdminViews,
  ErrorScreen,
  HomeSidebar,
  Loading,
  ProjectsContainer,
  RouteProps as SidebarRouteProps,
  YarnHeader,
} from "@fragments";
import { YarnsContainer } from "@fragments/yarn-library/container";
import { useActiveRoute } from "@hooks";
import { ReactNode } from "react";
import { Navigate, Route, Routes, useNavigation } from "react-router-dom";

type RouteProps = SidebarRouteProps & {
  header?: ReactNode;
  showHeader?: boolean;
  toolbar?: ReactNode;
  isScrollable?: boolean;
};

//https://www.figma.com/file/IrSHApht4p7tXsNxouLqAf/LOOP-UI?type=design&node-id=10584-565598&mode=design&t=4Gb3lECOQT2jyM6o-0
export default function Home() {
  const colorMode = useColorMode().colorMode;
  const loopWebBaseUri = import.meta.env.VITE_LOOP_WEB_BASE_URI ?? import.meta.env.VITE_LOOP_BASE_URI;
  const { user, accessToken } = useAuth();

  const global = [
    {
      key: "updates",
      path: "/updates",
      element: (
        <iframe
          src={`${loopWebBaseUri}release-notes?color-mode=${colorMode}&access-token=${accessToken}`}
          style={{ height: "100vh", width: "100%", border: "none" }}
        />
      ),
      showHeader: false,
      isScrollable: true,
    },
    {
      key: "help",
      path: "/help",
      element: (
        <iframe
          src={`${loopWebBaseUri}docs?color-mode=${colorMode}&access-token=${accessToken}`}
          style={{ height: "100vh", width: "100%", border: "none" }}
        />
      ),
      showHeader: false,
      isScrollable: true,
    },
  ];

  const organizations =
    user?.organizations?.map((membership) => {
      const prefix = `/organizations/${membership.organizationId}`;

      return {
        organization: membership.organization,
        sidebarRoutes: [
          {
            key: "projects",
            path: `${prefix}/projects`,
            element: <ProjectsContainer organization={membership} />,
            showHeader: false,
            isScrollable: true,
          },
          {
            key: "yarns",
            path: `${prefix}/yarns`,
            element: <YarnsContainer organization={membership.organization} />,
            header: <YarnHeader />,
            isScrollable: false,
          },
          ...(membership.memberRole === "admin"
            ? [
                {
                  key: "admin",
                  path: `${prefix}/admin`,
                  element: <AdminViews organization={membership.organization} routePrefix={`${prefix}/admin`} />,
                  header: <AdminHeader organization={membership.organization} />,
                  isScrollable: true,
                },
              ]
            : []),
        ],
        subRoutes: [
          ...(membership.memberRole === "admin"
            ? [
                {
                  key: "organizationMembers",
                  path: `${prefix}/admin/members`,
                  element: <AdminViews organization={membership.organization} routePrefix={`${prefix}/admin`} />,
                  header: <AdminHeader organization={membership.organization} />,
                  isScrollable: true,
                },
              ]
            : []),
        ],
      };
    }) ?? [];

  const sidebarRoutes = [
    ...global,
    ...(organizations?.map((o) => o.sidebarRoutes).reduce((accumulator, value) => accumulator.concat(value), []) ?? []),
  ];

  const activeRoute: RouteProps = useActiveRoute(sidebarRoutes) || sidebarRoutes[0];
  const routes = [
    {
      key: "projects",
      path: "/projects",
      element: <Navigate to={`/organizations/${user.organizations?.[0]?.organizationId}/projects`} />,
      isScrollable: true,
    },
    ...sidebarRoutes,
    ...(organizations?.map((o) => o.subRoutes).reduce((accumulator, value) => accumulator.concat(value), []) ?? []),
    {
      key: "404",
      path: "*",
      element: <ErrorScreen error={{ name: "Error", message: "Not Found" }} showHeader={false} />,
      isScrollable: true,
    },
  ];

  const { state } = useNavigation();

  const isScrollable = activeRoute?.isScrollable !== false;

  return (
    <Flex>
      <HomeSidebar routes={{ global, organizations }} activeRoute={activeRoute} />
      <VStack gap={0} width="full" maxHeight="100vh" minHeight="100vh" overflowY={isScrollable ? "auto" : "hidden"}>
        {state === "loading" ? (
          <Loading />
        ) : (
          <Box
            minW="full"
            height="100%"
            display="flex"
            flexDirection="column"
            overflow={isScrollable ? "visible" : "hidden"}
          >
            {activeRoute.header && activeRoute.showHeader !== false && (
              <Box position={!isScrollable ? "sticky" : "relative"} top={0} zIndex={10} width="100%" bg="inherit">
                {activeRoute.header}
              </Box>
            )}
            <Box flex="1" overflow={isScrollable ? "visible" : "hidden"}>
              <Routes>
                {routes.map(({ key, ...route }) => (
                  <Route key={key} {...route} />
                ))}
              </Routes>
            </Box>
          </Box>
        )}
      </VStack>
    </Flex>
  );
}
