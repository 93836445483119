import { Colorway, YarnToken, YarnTokenValue } from "@models/backend";
import { useModelsStore } from "@state";
import backendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { useCallback } from "react";
import { useCurrentProject } from "@fragments/project/container/useCurrentProject";
import { useYarnLibrary } from ".";

interface UseColorwaysProps {
  projectId?: string;
}

export function useColorways({ projectId }: UseColorwaysProps = {}) {
  const { colorways, yarnTokens, setColorways, setColorway, setYarnTokens, setYarnToken } = useModelsStore((state) => ({
    colorways: state.colorways,
    yarnTokens: state.yarnTokens,
    selectedColorwayId: state.selectedColorwayId,
    setColorways: state.setColorways,
    setColorway: state.setColorway,
    setYarnTokens: state.setYarnTokens,
    setYarnToken: state.setYarnToken,
    setSelectedColorwayId: state.setSelectedColorwayId,
  }));

  const { headers } = useAuth();

  const { project } = useCurrentProject();

  const { yarns } = useYarnLibrary(project.organizationId);

  const createColorway = useCallback(
    async (colorwayData: Omit<Colorway, "id" | "yarnTokens">): Promise<Colorway | undefined> => {
      if (!projectId) return undefined;

      const colorway = await backendApi.createColorway({
        headers,
        params: { projectId },
        body: colorwayData,
      });

      setColorways([...colorways, colorway]);
      return colorway;
    },
    [projectId, headers, colorways, setColorways],
  );

  const updateColorway = useCallback(
    async (id: string, colorwayData: Partial<Omit<Colorway, "id" | "yarnTokens">>): Promise<Colorway | undefined> => {
      if (!projectId) return undefined;

      const colorway = await backendApi.updateColorway({
        headers,
        params: { projectId, id },
        body: colorwayData,
      });

      setColorway(colorway);
      return colorway;
    },
    [projectId, headers, setColorway],
  );

  const deleteColorway = useCallback(
    async (id: string): Promise<boolean> => {
      if (!projectId) return false;

      await backendApi.deleteColorway({
        headers,
        params: { projectId, id },
      });

      setColorways(colorways.filter((c) => c.id !== id));
      return true;
    },
    [projectId, headers, colorways, setColorways],
  );

  const updateColorways = useCallback(
    async (colorwaysData: Colorway[]): Promise<Colorway[] | undefined> => {
      if (!projectId) return undefined;

      const updatedColorways = await backendApi.updateColorways({
        headers,
        params: { projectId },
        body: { colorways: colorwaysData },
      });

      setColorways(updatedColorways);
      return updatedColorways;
    },
    [projectId, headers, setColorways],
  );

  const createYarnToken = useCallback(
    async (yarnTokenData: Omit<YarnToken, "id">): Promise<YarnToken | undefined> => {
      if (!projectId) return undefined;

      if (yarns.length < 1) {
        console.log("no yarns in db");
        return undefined;
      }

      const yarnToken = await backendApi.createYarnToken({
        headers,
        params: { projectId },
        body: yarnTokenData,
      });

      setYarnTokens([...yarnTokens, yarnToken]);

      const yarnTokenValue = {
        id: yarnToken.id,
        yarnId: yarns[0].id,
        numberOfEnds: 18,
      };

      colorways.map((colorway) => {
        const updatedYarnTokens = [...colorway.yarnTokens, yarnTokenValue];

        setColorway({
          ...colorway,
          yarnTokens: updatedYarnTokens,
        });
      });

      return yarnToken;
    },
    [projectId, headers, yarns, yarnTokens, setYarnTokens],
  );

  const updateYarnToken = useCallback(
    async (id: string, yarnTokenData: Partial<Omit<YarnToken, "id">>): Promise<YarnToken | undefined> => {
      if (!projectId) return undefined;

      const yarnToken = await backendApi.updateYarnToken({
        headers,
        params: { projectId, id },
        body: yarnTokenData,
      });

      setYarnToken(yarnToken);
      return yarnToken;
    },
    [projectId, headers, setYarnToken],
  );

  const deleteYarnToken = useCallback(
    async (id: string): Promise<boolean> => {
      if (!projectId) return false;

      await backendApi.deleteYarnToken({
        headers,
        params: { projectId, id },
      });

      setYarnTokens(yarnTokens.filter((y) => y.id !== id));

      colorways.map((colorway) => {
        const updatedYarnTokens = colorway.yarnTokens.filter((yt) => yt.id !== id);

        setColorway({
          ...colorway,
          yarnTokens: updatedYarnTokens,
        });
      });

      return true;
    },
    [projectId, colorways, headers, yarnTokens, setYarnTokens],
  );

  const updateYarnTokens = useCallback(
    async (yarnTokensData: YarnToken[]): Promise<YarnToken[] | undefined> => {
      if (!projectId) return undefined;

      const updatedYarnTokens = await backendApi.updateYarnTokens({
        headers,
        params: { projectId },
        body: { yarnTokens: yarnTokensData },
      });

      setYarnTokens(updatedYarnTokens);
      return updatedYarnTokens;
    },
    [projectId, headers, setYarnTokens],
  );

  const updateColorwayYarnToken = useCallback(
    async (
      colorwayId: string,
      yarnTokenId: string,
      data: Partial<Omit<YarnTokenValue, "id">>,
    ): Promise<YarnTokenValue | undefined> => {
      if (!projectId) return undefined;

      const yarnTokenValue = await backendApi.updateColorwayYarnToken({
        headers,
        params: { projectId, colorwayId, yarnTokenId },
        body: data,
      });

      const updatedColorway = colorways.find((c) => c.id === colorwayId);
      if (updatedColorway) {
        const updatedYarnTokens = updatedColorway.yarnTokens.map((yt) =>
          yt.id === yarnTokenId ? { ...yt, ...yarnTokenValue } : yt,
        );

        if (!updatedYarnTokens.find((yt) => yt.id === yarnTokenId)) {
          updatedYarnTokens.push(yarnTokenValue);
        }

        setColorway({
          ...updatedColorway,
          yarnTokens: updatedYarnTokens,
        });
      }

      return yarnTokenValue;
    },
    [projectId, headers, colorways, setColorway],
  );

  return {
    colorways,
    yarnTokens,

    createColorway,
    updateColorway,
    deleteColorway,
    updateColorways,

    createYarnToken,
    updateYarnToken,
    deleteYarnToken,
    updateYarnTokens,

    updateColorwayYarnToken,
  };
}
