import { ModelsStore, SelectedObject } from "../types";
import { SetState } from "zustand";

export const errorsSlice = (set: SetState<ModelsStore>) => ({
  errorObjects: [],
  addErrorObject: (errorObject: SelectedObject, message: string) =>
    set(({ errorObjects }) => ({
      errorObjects: [
        ...errorObjects.filter(([{ type, id }]) => type != errorObject.type || id != errorObject.id),
        [errorObject, message],
      ],
    })),
  removeErrorObject: (errorObject: SelectedObject) =>
    set(({ errorObjects }) => ({
      errorObjects: errorObjects.filter(([{ type, id }]) => type != errorObject.type || id != errorObject.id),
    })),
  clearErrorObjects: () => set(() => ({ errorObjects: [] })),
});
