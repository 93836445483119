import { ReactNode } from "react";
import { SimpleGrid, SimpleGridProps } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
  TableOptions,
  RowData,
} from "@tanstack/react-table";

declare module "@tanstack/react-table" {
  interface ColumnMeta<TData extends RowData, TValue> {
    flex?: number;
    __phantom__?: [TData, TValue];
  }
}

export type DataGridProps<Data> = {
  columns: ColumnDef<Data>[];
  data: Data[];
  fullTextSearch?: string;
  render?: (row: Row<Data>) => ReactNode;
  sorting?: SortingState;
  tableOptions?: Partial<TableOptions<Data>>;
} & Pick<SimpleGridProps, "minChildWidth" | "spacing" | "spacingX" | "spacingY"> &
  StyleProps;

export function DataGrid<Data extends object>({
  columns,
  data,
  fullTextSearch: globalFilter,
  render,
  sorting,
  tableOptions = {},
  ...gridProps
}: DataGridProps<Data>) {
  // Create the table instance
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      globalFilter,
      sorting,
    },
    ...tableOptions,
  });

  // Simple grid rendering
  return (
    <SimpleGrid {...gridProps} overflowX="hidden">
      {render && table.getRowModel().rows.map((row) => render(row))}
    </SimpleGrid>
  );
}
