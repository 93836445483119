import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { CommentThreadState } from "@state";
import { pick } from "lodash";
import { useCallback } from "react";

export function useBackendCommentThread() {
  const { headers } = useAuth();

  const updateBackendCommentThreads = useCallback(
    async (projectId: string, commentThreads: CommentThreadState[]) => {
      const result = await Promise.all(
        commentThreads.map((c) =>
          BackendApi.updateThread({
            headers,
            params: { projectId, threadId: c.id },
            body: pick(c.data, ["position", "state"]),
          }),
        ),
      );

      return result.map((data) => ({
        ...(commentThreads.find((c) => c.id) ?? { occluded: false, position: data.position }),
        id: data.id,
        data,
      }));
    },
    [headers],
  );

  return { updateBackendCommentThreads };
}
