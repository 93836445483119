import { Vector4 } from "three";

interface Yarn {
  name: string;
  color: Vector4;
  thickness: number; // [0-1] of max allowed
  wavelength: number; // relative to cell size, TODO convert to mm
  styleType: number; // int { simple = 0, zig-zag = 1, double-zig = 2}
  decorationType: number; // int { none = 0, circles = 1, diamonds = 2, square = 3, offset_line = 4, stretched_diamonds = 5, arrows = 6}
  decorationSize: number; // [0-1] of max allowed
  decorationColor: Vector4;
}
export interface YarnUniform {
  color: Vector4;
  thickness: number;
  wavelength: number;
  styleType: number;
  decorationType: number;
  decorationSize: number;
  decorationColor: Vector4;
}

interface ColorwayYarn {
  yarn: Yarn;
  ends: number;
}

interface Colorway {
  name: string;
  colorwayYarns: ColorwayYarn[];
}

const yarns: Yarn[] = [
  {
    name: "Blue no dots",
    color: new Vector4(0.2, 0.4, 0.8, 1.0),
    thickness: 1.0,
    wavelength: 0.2,
    styleType: 0,
    decorationType: 0,
    decorationSize: 0.4,
    decorationColor: new Vector4(1.0, 1.0, 1.0, 1.0),
  },
  {
    name: "Blue with white dots",
    color: new Vector4(0.2, 0.4, 0.8, 1.0),
    thickness: 1.0,
    wavelength: 0.2,
    styleType: 0,
    decorationType: 1,
    decorationSize: 0.4,
    decorationColor: new Vector4(1.0, 1.0, 1.0, 1.0),
  },
  {
    name: "Purple twisted",
    color: new Vector4(0.4, 0.2, 0.8, 1.0),
    thickness: 1.0,
    wavelength: 0.5,
    styleType: 2,
    decorationType: 0,
    decorationSize: 0.5,
    decorationColor: new Vector4(0.4, 0.2, 0.8, 1.0),
  },
  {
    name: "Red diamonds",
    color: new Vector4(0.8, 0.2, 0.4, 1.0),
    thickness: 0.8,
    wavelength: 0.6,
    styleType: 0,
    decorationType: 5,
    decorationSize: 1.0,
    decorationColor: new Vector4(0.2, 0.05, 0.1, 1.0),
  },
  {
    name: "Green arrows",
    color: new Vector4(0.1, 0.4, 0.2, 1.0),
    thickness: 0.25,
    wavelength: 0.5,
    styleType: 0,
    decorationType: 6,
    decorationSize: 1.0,
    decorationColor: new Vector4(0.2, 0.8, 0.4, 1.0),
  },
  {
    name: "Dark Blue with diamond",
    color: new Vector4(0.1, 0.2, 0.4, 1.0),
    thickness: 1.0,
    wavelength: 0.2,
    styleType: 1,
    decorationType: 0,
    decorationSize: 0.8,
    decorationColor: new Vector4(0.2, 0.4, 0.8, 1.0),
  },
  {
    name: "Purple liney",
    color: new Vector4(0.4, 0.2, 0.8, 1.0),
    thickness: 1.0,
    wavelength: 0.33,
    styleType: 0,
    decorationType: 4,
    decorationSize: 0.2,
    decorationColor: new Vector4(0.8, 0.2, 0.8, 1.0),
  },
];

const colorwayYarns: ColorwayYarn[] = [
  { yarn: yarns[0], ends: 1 },
  { yarn: yarns[1], ends: 1 },
  { yarn: yarns[2], ends: 1 },
  { yarn: yarns[3], ends: 1 },
  { yarn: yarns[4], ends: 1 },
  { yarn: yarns[5], ends: 1 },
  { yarn: yarns[6], ends: 1 },
];

const colorways: Colorway[] = [{ name: "colorway01", colorwayYarns: colorwayYarns }];

export function loadColorways() {
  const yarnUniforms: YarnUniform[] = colorways[0].colorwayYarns.map((colorwayYarn) => {
    const { yarn } = colorwayYarn;
    return {
      color: yarn.color,
      thickness: yarn.thickness,
      wavelength: yarn.wavelength,
      styleType: yarn.styleType,
      decorationType: yarn.decorationType,
      decorationSize: yarn.decorationSize,
      decorationColor: yarn.decorationColor,
    };
  });

  return yarnUniforms;
}
