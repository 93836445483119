import { useCallback } from "react";
import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { loadKnitMesh } from "@utils/project/mesh";
import { useModelsStore } from "@state/models";
import { Process as BackendProcess } from "@models/backend";

export function useProcessLoader() {
  const { headers } = useAuth();
  const { setKnitMeshDirty, setProcess, models } = useModelsStore();

  const getLastProcess = useCallback(
    async (projectId: string) => {
      return (
        await BackendApi.getProcesses({
          headers,
          params: { projectId },
        })
      )[0];
    },
    [headers],
  );

  const setKnitMesh = useCallback(
    async (process: BackendProcess) => {
      if (!process.output?.objUrl) {
        console.warn("Process does not have an obj file");
        return;
      }
      if (!process.output?.bmpUrl) {
        console.warn("Process does not have an bmp file");
        return;
      }
      if (!process.output?.dataMapUrl) {
        console.warn("Process does not have a neighbor bmp file");
        return;
      }
      if (!process.output?.neighborMapUrl) {
        console.warn("Process does not have a neighbor bmp file");
        return;
      }

      const { mesh, bmpTexture, dataMapTexture, neighborMapTexture } = await loadKnitMesh(
        process.output.objUrl,
        process.output.bmpUrl,
        process.output.dataMapUrl,
        process.output.neighborMapUrl,
      );
      setKnitMeshDirty(false);
      setProcess({
        mesh,
        bmpTexture,
        dataMapTexture,
        neighborMapTexture,
        ...process,
      });
    },
    [setProcess, loadKnitMesh, models],
  );

  const loadLastProcess = useCallback(
    async (projectId: string) => {
      const process = await getLastProcess(projectId);
      if (process != null) {
        try {
          await setKnitMesh(process);
        } catch (error) {
          console.error("Failed to set knit mesh:", error);
        }
      }
    },
    [setKnitMesh, getLastProcess],
  );
  return { loadLastProcess, setKnitMesh };
}
