import React, { useState, useCallback, useRef, useEffect, useMemo } from "react";
import { Box, Text } from "@chakra-ui/react";
import { DraggableResizablePanel } from "@design-system/components/DraggableResizablePanel";
import { ResizableDataGrid } from "@design-system/components/ResizableDataGrid";
import { useSemanticTokens, spacing } from "@design-system";
import { EditableInput, EditableInputHandle } from "@design-system/components/EditableInput";
import { YarnRowContextMenu } from "../yarn-library/YarnRowContextMenu";
import { useRowContextMenu } from "../yarn-library/useRowContextMenu";
import { ColumnDef } from "@tanstack/react-table";
import { useColorways } from "@hooks";
import { useCurrentProject } from "@fragments/project/container/useCurrentProject";
import { useYarnLibrary } from "@hooks/project/useYarnLibrary";
import { Yarn } from "@models/backend";
import { ColorwayCell } from "./ColorwaysCell";
import { useHeaderContextMenu } from "./useHeaderContextMenu";
import { ColorwayHeaderContextMenu } from "./ColorwayHeaderContextMenu";

interface ColorwayOption {
  id: string;
  material: string;
  colorValue: string;
  yarn: Yarn;
}

interface ColorwayData {
  id: string;
  yarnToken: string;
  colorways: Record<
    string,
    {
      option: ColorwayOption;
      ends: string;
    }
  >;
}

interface ColorwaysPanelProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ColorwaysPanel: React.FC<ColorwaysPanelProps> = ({ isOpen, onClose }) => {
  const semanticTokens = useSemanticTokens();
  const { contextMenuPosition, selectedRowIndex, isContextMenuOpen, handleRowContextMenu, handleCloseContextMenu } =
    useRowContextMenu();
  const {
    headerContextMenuPosition,
    selectedColumnId,
    isHeaderContextMenuOpen,
    handleHeaderContextMenu,
    handleCloseContextMenu: handleCloseHeaderContextMenu,
  } = useHeaderContextMenu();
  const { project } = useCurrentProject();
  const { yarns } = useYarnLibrary(project.organizationId);
  const {
    colorways,
    yarnTokens,
    createColorway,
    createYarnToken,
    updateYarnToken,
    updateColorway,
    updateColorwayYarnToken,
    deleteYarnToken,
    deleteColorway,
  } = useColorways({ projectId: project.id });

  const editableInputRefs = useRef<EditableInputHandle[]>([]);
  const headerInputRefs = useRef<Record<string, EditableInputHandle>>({});
  const colorwayDataInitialized = useRef<boolean>(false);
  const [colorwayColumns, setColorwayColumns] = useState<string[]>([]);
  const [colorwayData, setColorwayData] = useState<ColorwayData[]>([]);

  const colorwayOptions = useMemo(() => {
    if (!yarns?.length) return [];
    return yarns.map((yarn) => ({
      id: yarn.id,
      material: yarn.material || "No material",
      colorValue: yarn.colorValue || "#CCCCCC",
      yarn,
    }));
  }, [yarns]);

  useEffect(() => {
    if (colorways?.length && colorwayColumns.length === 0 && !colorwayDataInitialized.current) {
      setColorwayColumns(colorways.map((c) => c.id));
    }
  }, [colorways, colorwayColumns.length, colorwayDataInitialized]);

  useEffect(() => {
    if (
      yarnTokens?.length &&
      colorwayOptions.length > 0 &&
      colorwayColumns.length > 0 &&
      !colorwayDataInitialized.current
    ) {
      const data = yarnTokens.map((token) => {
        const colorwaysMap: Record<string, { option: ColorwayOption; ends: string }> = {};

        colorwayColumns.forEach((colorwayId) => {
          const colorway = colorways?.find((c) => c.id === colorwayId);
          const tokenValue = colorway?.yarnTokens?.find((yt) => yt.id === token.id);
          const selectedOption = tokenValue?.yarnId
            ? colorwayOptions.find((opt) => opt.id === tokenValue.yarnId)
            : colorwayOptions[0];

          colorwaysMap[colorwayId] = {
            option: selectedOption!,
            ends: tokenValue?.numberOfEnds?.toString() || "1",
          };
        });

        return {
          id: token.id,
          yarnToken: token.name,
          colorways: colorwaysMap,
        };
      });

      setColorwayData(data);
      colorwayDataInitialized.current = true;
    }
  }, [colorwayColumns, yarnTokens, colorwayOptions, colorways]);

  const gridProps = {
    rowHeight: 38,
    headerHeight: 32,
    densePadding: true,
    cellPadding: spacing.space[50],
  };

  const setEditableInputRef = useCallback((index: number, ref: EditableInputHandle | null) => {
    if (ref) {
      editableInputRefs.current[index] = ref;
    }
  }, []);

  const setHeaderInputRef = useCallback((colorwayId: string, ref: EditableInputHandle | null) => {
    if (ref) {
      headerInputRefs.current[colorwayId] = ref;
    }
  }, []);

  const handleAddColorwayColumn = useCallback(async () => {
    if (!project.id) return;

    colorwayDataInitialized.current = true;

    const newColorway = await createColorway({
      name: `Colorway ${colorwayColumns.length + 1}`,
    });

    if (newColorway) {
      setColorwayColumns((prev) => [...prev, newColorway.id]);

      if (yarnTokens?.length) {
        const colorwayUpdates = yarnTokens.map((token) =>
          updateColorwayYarnToken(newColorway.id, token.id, {
            yarnId: colorwayOptions[0].id,
            numberOfEnds: 11,
          }),
        );

        await Promise.all(colorwayUpdates);

        setColorwayData((prev) => {
          return prev.map((item) => ({
            ...item,
            colorways: {
              ...item.colorways,
              [newColorway.id]: {
                option: colorwayOptions[0],
                ends: "1",
              },
            },
          }));
        });
      }
    }
  }, [project.id, colorwayColumns, createColorway, yarnTokens, colorwayOptions, updateColorwayYarnToken]);

  const addNewRow = useCallback(async () => {
    if (!project.id) return;

    colorwayDataInitialized.current = true;

    if (colorwayColumns.length === 0) {
      const newColorway = await createColorway({ name: "Colorway 1" });
      if (newColorway) {
        setColorwayColumns([newColorway.id]);
      } else {
        return;
      }
    }

    const newToken = await createYarnToken({ name: "New yarn token" });
    if (newToken) {
      const colorwaysMap: Record<string, { option: ColorwayOption; ends: string }> = {};

      for (const colorwayId of colorwayColumns) {
        colorwaysMap[colorwayId] = {
          option: colorwayOptions[0],
          ends: "1",
        };

        await updateColorwayYarnToken(colorwayId, newToken.id, {
          yarnId: colorwayOptions[0].id,
          numberOfEnds: 1,
        });
      }

      setColorwayData((prev) => [
        ...prev,
        {
          id: newToken.id,
          yarnToken: newToken.name,
          colorways: colorwaysMap,
        },
      ]);
    }
  }, [project.id, colorwayColumns, createColorway, createYarnToken, colorwayOptions, updateColorwayYarnToken]);

  const handleUpdate = useCallback(
    (id: string, field: keyof ColorwayData) => (value: string | Record<string, ColorwayData>) => {
      setColorwayData((prev) => prev.map((item) => (item.id === id ? { ...item, [field]: value } : item)));
      if (field === "yarnToken") {
        const token = yarnTokens.find((t) => t.id === id);
        if (token) {
          updateYarnToken(id, { name: value as string });
        }
      }
    },
    [yarnTokens, updateYarnToken],
  );

  const handleColorwayUpdate = useCallback(
    (id: string, colorwayId: string) => (value: string) => {
      const selectedOption = colorwayOptions.find((option) => option.id === value);
      if (!selectedOption) return;

      setColorwayData((prev) => {
        return prev.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              colorways: {
                ...item.colorways,
                [colorwayId]: {
                  ...item.colorways[colorwayId],
                  option: selectedOption,
                },
              },
            };
          }
          return item;
        });
      });

      updateColorwayYarnToken(colorwayId, id, { yarnId: value });
    },
    [colorwayOptions, updateColorwayYarnToken],
  );

  const handleEndsUpdate = useCallback(
    (id: string, colorwayId: string) => (value: string) => {
      setColorwayData((prev) => {
        return prev.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              colorways: {
                ...item.colorways,
                [colorwayId]: {
                  ...item.colorways[colorwayId],
                  ends: value,
                },
              },
            };
          }
          return item;
        });
      });

      updateColorwayYarnToken(colorwayId, id, { numberOfEnds: parseInt(value, 10) });
    },
    [updateColorwayYarnToken],
  );

  const handleDuplicateRow = useCallback(async () => {
    if (selectedRowIndex === null || !project.id || colorwayColumns.length === 0) return;
    const rowToClone = colorwayData[selectedRowIndex];
    const newToken = await createYarnToken({ name: `${rowToClone.yarnToken} (copy)` });

    if (newToken) {
      for (const colorwayId of colorwayColumns) {
        const colorwayData = rowToClone.colorways[colorwayId];
        await updateColorwayYarnToken(colorwayId, newToken.id, {
          yarnId: colorwayData.option.id,
          numberOfEnds: parseInt(colorwayData.ends, 10),
        });
      }

      setColorwayData((prev) => {
        const newRow = {
          id: newToken.id,
          yarnToken: newToken.name,
          colorways: { ...rowToClone.colorways },
        };
        const newArr = [...prev];
        newArr.splice(selectedRowIndex + 1, 0, newRow);
        return newArr;
      });
    }
  }, [selectedRowIndex, project.id, colorwayColumns, colorwayData, createYarnToken, updateColorwayYarnToken]);

  const handleDeleteRow = useCallback(async () => {
    if (selectedRowIndex === null) return;
    const tokenToDelete = colorwayData[selectedRowIndex];
    await deleteYarnToken(tokenToDelete.id);
    setColorwayData((prev) => prev.filter((_, index) => index !== selectedRowIndex));
  }, [selectedRowIndex, colorwayData, deleteYarnToken]);

  const handleRenameRow = useCallback(() => {
    if (selectedRowIndex !== null) {
      const inputRef = editableInputRefs.current[selectedRowIndex];
      if (inputRef) {
        inputRef.startEditing();
      }
    }
  }, [selectedRowIndex]);

  const handleColorwayHeaderUpdate = useCallback(
    (colorwayId: string) => (value: string) => {
      const colorway = colorways?.find((c) => c.id === colorwayId);
      if (colorway && value) {
        updateColorway(colorwayId, { name: value });
      }
    },
    [colorways, updateColorway],
  );

  const handleDeleteColumn = useCallback(async () => {
    if (!selectedColumnId || !project.id) return;

    await deleteColorway(selectedColumnId);

    setColorwayColumns((prev) => prev.filter((id) => id !== selectedColumnId));
    setColorwayData((prev) => {
      return prev.map((item) => {
        const updatedColorways = { ...item.colorways };
        delete updatedColorways[selectedColumnId];
        return {
          ...item,
          colorways: updatedColorways,
        };
      });
    });
  }, [selectedColumnId, project.id, deleteColorway]);

  const handleRenameColumn = useCallback(() => {
    if (selectedColumnId && headerInputRefs.current[selectedColumnId]) {
      headerInputRefs.current[selectedColumnId].startEditing();
    }
  }, [selectedColumnId]);

  const handleDuplicateColumn = useCallback(async () => {
    if (selectedColumnId === null || !project.id) return;

    const colorwayToDuplicate = colorways?.find((c) => c.id === selectedColumnId);
    if (!colorwayToDuplicate) return;

    const newColorway = await createColorway({
      name: `${colorwayToDuplicate.name || "Colorway"}`,
    });

    if (newColorway) {
      setColorwayColumns((prev) => [...prev, newColorway.id]);

      if (yarnTokens?.length) {
        const colorwayUpdates = [];

        for (const row of colorwayData) {
          const originalColorwayData = row.colorways[selectedColumnId];
          if (originalColorwayData) {
            colorwayUpdates.push(
              updateColorwayYarnToken(newColorway.id, row.id, {
                yarnId: originalColorwayData.option.id,
                numberOfEnds: parseInt(originalColorwayData.ends, 10),
              }),
            );
          }
        }

        await Promise.all(colorwayUpdates);

        setColorwayData((prev) => {
          return prev.map((item) => {
            const originalColorwayData = item.colorways[selectedColumnId];

            return {
              ...item,
              colorways: {
                ...item.colorways,
                [newColorway.id]: originalColorwayData
                  ? {
                      option: originalColorwayData.option,
                      ends: originalColorwayData.ends,
                    }
                  : {
                      option: colorwayOptions[0],
                      ends: "1",
                    },
              },
            };
          });
        });
      }
    }
  }, [selectedColumnId, project.id, colorways, colorwayData, colorwayOptions, createColorway, updateColorwayYarnToken]);

  const columns = useMemo(() => {
    const columnDefs: ColumnDef<ColorwayData>[] = [
      {
        accessorKey: "yarnToken",
        header: () => (
          <Text
            color={semanticTokens.text.classic.primary}
            variant="2xs-regular"
            pl={spacing.space[100]}
            textAlign="left"
          >
            Yarn token
          </Text>
        ),
        minSize: 110,
        size: 120,
        meta: { flex: 1 },
        cell: ({ row }) => {
          const value = row.original.yarnToken;
          const rowIndex = row.index;
          const handleCellContextMenu = (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            handleRowContextMenu(e, rowIndex);
          };
          return (
            <Box
              width="100%"
              height="100%"
              onContextMenu={handleCellContextMenu}
              px={0}
              py={0}
              className="yarn-token-cell"
            >
              <EditableInput
                ref={(ref) => setEditableInputRef(rowIndex, ref)}
                initialValue={value}
                textColor={semanticTokens.text.classic.primary}
                textVariant="xs-regular"
                onChange={handleUpdate(row.original.id, "yarnToken")}
                fillContainer={true}
                pl={spacing.space[50]}
              />
            </Box>
          );
        },
      },
    ];

    colorwayColumns.forEach((colorwayId) => {
      const colorway = colorways?.find((c) => c.id === colorwayId);
      columnDefs.push({
        accessorKey: `colorway_${colorwayId}`,
        header: () => (
          <Box width="100%" px={spacing.space[100]} onContextMenu={(e) => handleHeaderContextMenu(e, colorwayId)}>
            <EditableInput
              ref={(ref) => setHeaderInputRef(colorwayId, ref)}
              initialValue={colorway?.name || "Colorway"}
              textColor={semanticTokens.text.classic.primary}
              textVariant="2xs-regular"
              onChange={handleColorwayHeaderUpdate(colorwayId)}
              fillContainer={true}
            />
          </Box>
        ),
        minSize: 240,
        size: 280,
        meta: { flex: 1.8 },
        cell: ({ row }) => {
          const rowData = row.original.colorways[colorwayId];
          if (!rowData) return null;

          return (
            <ColorwayCell
              colorway={rowData.option}
              ends={rowData.ends}
              rowId={row.original.id}
              colorwayId={colorwayId}
              colorwayOptions={colorwayOptions}
              handleColorwayUpdate={handleColorwayUpdate}
              handleEndsUpdate={handleEndsUpdate}
            />
          );
        },
      });
    });

    return columnDefs;
  }, [
    colorwayColumns,
    colorways,
    semanticTokens,
    spacing,
    handleUpdate,
    colorwayOptions,
    handleColorwayUpdate,
    handleEndsUpdate,
    handleRowContextMenu,
    setEditableInputRef,
    setHeaderInputRef,
    handleColorwayHeaderUpdate,
    handleHeaderContextMenu,
  ]);

  return (
    <DraggableResizablePanel
      isOpen={isOpen}
      onClose={onClose}
      title="Colorways"
      initialSize={{ width: 450, height: 300 }}
      closeButtonSize="xs"
      headerTextColor={semanticTokens.text.classic.primary}
      closeIconColor={semanticTokens.icons.primary}
    >
      <Box height="100%" width="100%" p={spacing.space[100]}>
        <ResizableDataGrid
          columns={columns}
          data={colorwayData}
          onAddRow={addNewRow}
          onAddColumn={handleAddColorwayColumn}
          hideScrollbars={true}
          {...gridProps}
        />
        <YarnRowContextMenu
          isOpen={isContextMenuOpen}
          onClose={handleCloseContextMenu}
          position={contextMenuPosition}
          onDelete={handleDeleteRow}
          onDuplicate={handleDuplicateRow}
          onRename={handleRenameRow}
        />
        <ColorwayHeaderContextMenu
          isOpen={isHeaderContextMenuOpen}
          onClose={handleCloseHeaderContextMenu}
          position={headerContextMenuPosition}
          onDelete={handleDeleteColumn}
          onRename={handleRenameColumn}
          onDuplicate={handleDuplicateColumn}
        />
      </Box>
    </DraggableResizablePanel>
  );
};
