export * from "./collection";
export * from "./cursor";
export * from "./curve";
export * from "./grid-curves";
export * from "./mesh";
export * from "./pathCollections";
export * from "./tools";
export * from "./zone";
export * from "./shaders";
export * from "./glslLibrary";
export * from "./yarnAndColorwayLibProto";
export * from "./structureGenerator";

export function toPrecision(num: number, precision: number) {
  const numprec = Math.min(Math.floor(Math.log10(num)), 0);
  if (numprec + 1 + precision >= 100) {
    return "" + num;
  }
  if (numprec > 0) {
    return num.toPrecision(precision + 1 + numprec);
  }
  return num.toPrecision(precision + 1);
}
