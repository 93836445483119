import { useModelExporter } from "@hooks";
import { useProjectState } from "@state";
import { useCallback } from "react";
import { useProcessLoader } from "./useProcessLoader";

export function useUpdateKnitMesh() {
  const { renderModeSelection } = useProjectState(({ renderModeSelection }) => ({ renderModeSelection }));
  const { setKnitMesh } = useProcessLoader();
  const { exportModel, isExportModelDisabled } = useModelExporter({ refresh: true });

  const updateKnitMesh = useCallback(async () => {
    if (renderModeSelection !== "design" && !isExportModelDisabled) {
      const result = await exportModel(false);

      if (result) {
        setKnitMesh(result);
      }
    }
  }, [exportModel, isExportModelDisabled, renderModeSelection]);
  return {
    updateKnitMesh,
  };
}
