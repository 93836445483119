import { Project } from "@models/backend";
import { Model } from "@models/project";
import { useModelsStore } from "@state/models";
import { useProjectState } from "@state/project";
import { useEffect, useMemo } from "react";
import { usePath } from "@hooks";
import { Mesh } from "three";
import { PenTool } from "./PenTool";
import { CalibrationCurveTool } from "./CalibrationCurveTool";
import { recordToArray } from "@utils/project/collection";

export interface _3DToolsProps {
  project: Project;
  model: Model;
  mesh: Mesh;
}

export function _3DTools({ project, model, mesh }: _3DToolsProps) {
  const { collections, newCollection } = useModelsStore(
    ({ pathCollections }) => pathCollections[model.id] ?? { collections: {}, newCollection: null },
  );
  const { pushPath } = usePath(project, model, newCollection);
  const { _3DToolbarSelection } = useProjectState();

  const pathCollections = useMemo(() => recordToArray(collections), [collections]);
  const guideCurveCollection = pathCollections.find(({ usage, id }) => !!id && usage === "guide") ?? null;

  useEffect(() => {
    if (_3DToolbarSelection !== "pen") {
      const path = newCollection;
      if (path && path.points.length > 0) {
        pushPath(false);
      }
    }
  }, [_3DToolbarSelection]);

  return (
    <group>
      {_3DToolbarSelection === "pen" && <PenTool project={project} model={model} mesh={mesh} />}
      {(_3DToolbarSelection === "section" || _3DToolbarSelection === "column") && guideCurveCollection && (
        <CalibrationCurveTool
          project={project}
          model={model}
          mesh={mesh}
          guideSource={guideCurveCollection}
          pathCollections={pathCollections}
        />
      )}
    </group>
  );
}
