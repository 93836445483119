import { ToggleIconButton, ToggleIconDropdown, spacing } from "@design-system";
import { DesignViewIcon, KnitMeshViewIcon, SchematicViewIcon } from "./icons";
import { ReactElement, useState } from "react";
import { useTranslation } from "@hooks";
import { useProjectState, Project3DToolbarState } from "@state/project";
import { useSemanticTokens } from "@design-system/hooks/useSemanticTokens";
import { Box } from "@chakra-ui/react";

export function RenderMode({
  name,
  keys,
  icon,
  disabled,
  setIsOpen,
}: {
  name: Project3DToolbarState["renderModeSelection"];
  keys: string;
  icon: ReactElement;
  disabled?: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const { t } = useTranslation("project3D.toolbarLeft.renderModes");
  const { renderModeSelection, setRenderModeSelection } = useProjectState();
  const isSelected = renderModeSelection === name;

  return (
    <ToggleIconButton
      key={name}
      disabled={disabled}
      isSelected={isSelected}
      onToggle={() => {
        setRenderModeSelection(name);
        setIsOpen(false);
      }}
      label={t(name + ".label")}
      icon={icon}
      shortcut={{
        label: t(name + ".label"),
        keys: keys,
      }}
      placement={"left"}
      variant="inverse"
    />
  );
}

export function RenderModeDropdown() {
  const { t } = useTranslation("project3D.toolbarLeft.renderModes");
  const { renderModeSelection } = useProjectState();
  const [isOpen, setIsOpen] = useState(false);
  const semanticTokens = useSemanticTokens();

  const icons: Record<Project3DToolbarState["renderModeSelection"], JSX.Element> = {
    design: <DesignViewIcon />,
    knitmesh: <KnitMeshViewIcon />,
    schematic: <SchematicViewIcon />,
  };

  return (
    <ToggleIconDropdown
      isSelected={isOpen}
      isOpen={isOpen}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      onToggle={() => {
        setIsOpen(!isOpen);
      }}
      icon={icons[renderModeSelection]}
      label={t(renderModeSelection + ".label")}
      shortcut={{
        label: t(renderModeSelection + ".label"),
        keys: "`",
      }}
      variant="inverse"
      placement={"bottom-end"}
      menuListStyleProps={{
        minWidth: "160px",
        width: "auto",
        padding: spacing.space[100],
        bg: semanticTokens.surface.classic.secondary,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        border: "none",
        zIndex: 11,
      }}
      buttonStyleProps={{
        width: "44px",
        height: "44px",
        padding: 0,
        borderRadius: 0,
        _hover: { bg: "transparent" },
      }}
    >
      <Box width="100%">
        <RenderMode name="design" keys="1" icon={<DesignViewIcon />} setIsOpen={setIsOpen} />
        <RenderMode name="knitmesh" keys="2" icon={<KnitMeshViewIcon />} setIsOpen={setIsOpen} />
        <RenderMode name="schematic" keys="3" icon={<SchematicViewIcon />} setIsOpen={setIsOpen} />
      </Box>
    </ToggleIconDropdown>
  );
}
