import BackendApi from "@api/BackendApi.ts";
import { useBackendApi } from "@api/useBackendApi.ts";
import { useAuth } from "@auth";
import { useCurrentProject } from "@fragments/project/container/useCurrentProject.ts";
import { useModelsStore } from "@state";
import { OnClickParams } from "@utils";
import { Vector3 } from "@variant-tech/pattern-derivation";
import { useCallback, useEffect, useState } from "react";
import { Vector2Like } from "three";

export type CreateThreadPopupProps = {
  isOpen: boolean;
  onClose: () => void;
  position?: Vector2Like;
  onAddComment: (content: string) => Promise<void>;
};

export function useProjectComments() {
  const { headers } = useAuth();
  const { project } = useCurrentProject();
  const [addCommentParams, setAddCommentParams] = useState<OnClickParams | null>(null);
  const { getCommentThread, setCommentThread, addCommentThreads, clearCommentThreads, removeCommentThread } =
    useModelsStore();
  const response = useBackendApi(BackendApi.getThreads, { params: { projectId: project.id } }, [project]);
  useEffect(() => {
    clearCommentThreads();

    if (response?.result?.length) {
      addCommentThreads(
        response.result.map((data) => {
          const { id, position } = data;

          return {
            id,
            position,
            visibility: "minimized",
            occluded: false,
            data,
          };
        }),
      );
    }
  }, [response?.result, response?.error]);

  const createCommentThread = useCallback(
    async ({ layerId, position, content }: { layerId: string; position: Vector3; content: string }) =>
      await BackendApi.createThread({
        headers,
        params: { projectId: project.id },
        body: { content, position, layerId },
      }),
    [project],
  );

  const addCommentToThread = useCallback(
    async ({ threadId, content }: { threadId: string; content: string }) =>
      await BackendApi.addComment({
        headers,
        params: { projectId: project.id, threadId },
        body: { content },
      }),
    [project],
  );

  const deleteThreadComment = useCallback(
    async ({ threadId, commentId }: { threadId: string; commentId: string }) =>
      await BackendApi.deleteComment({
        headers,
        params: { projectId: project.id, threadId, commentId },
      }),
    [project],
  );

  const updateThreadComment = useCallback(
    async ({ threadId, commentId, update }: { threadId: string; commentId: string; update: { content: string } }) =>
      await BackendApi.updateComment({
        headers,
        params: { projectId: project.id, threadId, commentId },
        body: update,
      }),
    [project],
  );

  const onAddComment = useCallback(
    async (content: string) => {
      const params = addCommentParams;

      if (!params) {
        return;
      }

      const { id, point } = params;

      const data = await createCommentThread({
        layerId: id,
        position: [point.x, point.y, point.z],
        content,
      });

      setCommentThread({
        id: data.id,
        position: data.position,
        visibility: "expanded",
        occluded: false,
        data,
      });
      setAddCommentParams(null);
    },
    [project, createCommentThread, addCommentParams],
  );

  const onAddCommentToThread = useCallback(
    async (threadId: string, content: string) => {
      const state = getCommentThread(threadId);

      if (!state) {
        return;
      }

      const comment = await addCommentToThread({ threadId: threadId, content });
      state.data.comments.push(comment);
      setCommentThread({ ...state });
    },
    [project, addCommentToThread],
  );

  const onDeleteThreadComment = useCallback(
    async (threadId: string, commentId: string) => {
      const state = getCommentThread(threadId);

      if (!state) {
        console.log("Thread not found", threadId);
        return;
      }

      const data = await deleteThreadComment({ threadId, commentId });

      if (!data) {
        removeCommentThread(threadId);
      } else {
        state.data = data;
        setCommentThread({ ...state });
      }
    },
    [project, deleteThreadComment],
  );

  const onUpdateThreadComment = useCallback(
    async (threadId: string, commentId: string, update: { content: string }) => {
      const state = getCommentThread(threadId);

      if (!state) {
        console.log("Thread not found", threadId);
        return;
      }

      const comment = state.data.comments.find((comment) => comment.id === commentId);

      if (!comment) {
        console.log("Comment not found", threadId, commentId, state.data.comments);
        return;
      }

      const updated = await updateThreadComment({ threadId, commentId, update });

      comment.content = updated.content;
      comment.updatedAt = updated.updatedAt;

      setCommentThread({ ...state });
    },
    [project, updateThreadComment],
  );

  const triggerAddCommentPopup = useCallback(
    (params: OnClickParams) => {
      if (!addCommentParams) {
        setAddCommentParams({ ...params, y: params.y - 45 });
      }
    },
    [project],
  );

  return {
    addCommentPopupProps: {
      isOpen: !!addCommentParams,
      onClose: () => setAddCommentParams(null),
      position: addCommentParams ? { x: addCommentParams.x, y: addCommentParams.y } : undefined,
      onAddComment,
    },
    triggerAddCommentPopup,
    onAddCommentToThread,
    onDeleteThreadComment,
    onUpdateThreadComment,
  };
}
