import { PathCollection } from "@models/project";
import { Vector3 as VP } from "@variant-tech/pattern-derivation";

export enum PathActionType {
  RESET,
  ADD_LAST_POINT,
  INSERT_POINT,
  MOVE_POINT,
  REMOVE_POINTS,
  CHANGE_VECTOR,
  REMOVE_VECTOR,
  SET_STATE,
}

export type PathAction =
  | {
      type: PathActionType.RESET;
    }
  | {
      type: PathActionType.ADD_LAST_POINT;
      point: VP;
    }
  | {
      type: PathActionType.INSERT_POINT;
      index: number;
      point: VP;
    }
  | {
      type: PathActionType.MOVE_POINT;
      index: number;
      point: VP;
    }
  | {
      type: PathActionType.REMOVE_POINTS;
      indexes: Array<number>;
    }
  | {
      type: PathActionType.CHANGE_VECTOR;
      index: number;
      vector: VP;
    }
  | {
      type: PathActionType.REMOVE_VECTOR;
      index: number;
    }
  | {
      type: PathActionType.SET_STATE;
      value: Partial<PathCollection>;
    };

export const reducer = (state: PathCollection, action: PathAction): PathCollection => {
  switch (action.type) {
    case PathActionType.ADD_LAST_POINT: {
      const points = [...state.points, action.point];
      return {
        ...state,
        points,
        controlVectors: points.map((_, index) =>
          index === state.points.length
            ? [
                [0, 0, 0],
                [0, 0, 0],
              ]
            : (state.controlVectors?.[index] ?? [
                [0, 0, 0],
                [0, 0, 0],
              ]),
        ),
      };
    }
    case PathActionType.INSERT_POINT: {
      const points = [...state.points];
      points.splice(action.index + 1, 0, action.point);

      const controlVectors = points.map(
        (_, index) =>
          state.controlVectors?.[index] ?? [
            [0, 0, 0],
            [0, 0, 0],
          ],
      );
      controlVectors.splice(action.index + 1, 0, [
        [0, 0, 0],
        [0, 0, 0],
      ]);
      controlVectors.pop();

      console.log({ points, controlVectors });

      return {
        ...state,
        points,
        controlVectors,
      };
    }
    case PathActionType.MOVE_POINT: {
      return {
        ...state,
        points: state.points.map((elem, index) => (index === action.index ? action.point : elem)),
      };
    }
    case PathActionType.REMOVE_POINTS: {
      return {
        ...state,
        points: state.points.filter((_, index) => action.indexes.indexOf(index) == -1),
        controlVectors: state.controlVectors.filter((_, index) => action.indexes.indexOf(index) == -1),
      };
    }
    case PathActionType.RESET: {
      return {
        ...state,
        type: "Path",
        points: [],
        controlVectors: [],
        isLoop: false,
      };
    }
    case PathActionType.CHANGE_VECTOR: {
      const rVec = action.vector;
      const lVec: VP = [-rVec[0], -rVec[1], -rVec[2]];
      return {
        ...state,
        controlVectors: state.points.map((_, index) =>
          index === action.index
            ? [lVec, rVec]
            : (state.controlVectors?.[index] ?? [
                [0, 0, 0],
                [0, 0, 0],
              ]),
        ),
        type: "BezierPath",
      };
    }
    case PathActionType.REMOVE_VECTOR: {
      return reducer(state, { type: PathActionType.CHANGE_VECTOR, vector: [0, 0, 0], index: action.index });
    }
    case PathActionType.SET_STATE: {
      return {
        ...state,
        ...action.value,
      };
    }
  }
};
