import { resolveHexValueRgbaNormalized } from "@design-system";
import { useModelsStore, useYarnsStore } from "@state";
import { useCallback } from "react";
import { Vector4 } from "three";

interface UseColorwayYarnsProps {
  colorwayId: string;
}

export interface YarnUniform {
  color: Vector4;
  thickness: number;
  wavelength: number;
  styleType: number;
  decorationType: number;
  decorationSize: number;
  decorationColor: Vector4;
}

const fallbackYarnUniform: YarnUniform = {
  color: new Vector4(0.5, 0.5, 0.5, 1.0),
  thickness: 1.0,
  wavelength: 0.2,
  styleType: 0,
  decorationType: 0,
  decorationSize: 0.4,
  decorationColor: new Vector4(1.0, 1.0, 1.0, 1.0),
};

export function useColorwayYarns({ colorwayId }: UseColorwayYarnsProps) {
  const { colorways, yarnTokens, selectedColorwayId } = useModelsStore((state) => ({
    colorways: state.colorways,
    yarnTokens: state.yarnTokens,
    selectedColorwayId: state.selectedColorwayId,
  }));

  const { yarns } = useYarnsStore();

  const effectiveColorwayId = colorwayId || selectedColorwayId;

  const getYarnTokensInColorway = useCallback(() => {
    const colorway = colorways.find((colorway) => colorway.id === effectiveColorwayId);
    if (!colorway) {
      console.warn("Cannot find colorway with ID:", effectiveColorwayId);
      return [];
    }

    const yarnTokenIds = colorway?.yarnTokens.map((yarnToken) => yarnToken.id);
    return yarnTokenIds?.map((id) => yarnTokens.find((token) => token.id === id)) || [];
  }, [colorways, yarnTokens, effectiveColorwayId]);

  const getYarnsInColorway = useCallback(() => {
    const colorway = colorways.find((colorway) => colorway.id === effectiveColorwayId);
    if (!colorway) {
      console.warn("Cannot find colorway with ID:", effectiveColorwayId);
      return [];
    }

    if (Object.keys(yarns).length > 0 && colorway?.yarnTokens.length) {
      return colorway.yarnTokens.map((yarnToken) => yarns[yarnToken.yarnId] ?? Object.values(yarns)[0]);
    } else {
      return [];
    }
  }, [colorways, yarns, effectiveColorwayId]);

  const getYarnUniformsForColorway = useCallback(() => {
    const colorwayYarns = getYarnsInColorway();
    if (colorwayYarns.length < 1) {
      console.warn("No yarns found for colorway:", effectiveColorwayId, "- using fallback");
      return [fallbackYarnUniform];
    }

    const yarnUniforms: YarnUniform[] = colorwayYarns.map((yarn) => {
      const colorValue = yarn.colorValue
        ? resolveHexValueRgbaNormalized(yarn.colorValue)
        : resolveHexValueRgbaNormalized("#CCCCCC");
      return {
        color: new Vector4(colorValue[0], colorValue[1], colorValue[2], 1.0),
        thickness: 1.0,
        wavelength: 0.2,
        styleType: 0,
        decorationType: 0,
        decorationSize: 0.4,
        decorationColor: new Vector4(1.0, 1.0, 1.0, 1.0),
      };
    });

    return yarnUniforms;
  }, [getYarnsInColorway, effectiveColorwayId]);

  return {
    getYarnTokensInColorway,
    getYarnsInColorway,
    getYarnUniformsForColorway,
    effectiveColorwayId,
  };
}
