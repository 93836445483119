import { Leva } from "leva";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

function DebugPanel() {
  const [showDebugPanel, setShowDebugPanel] = useState(false);

  useHotkeys("ctrl+shift+alt+d", (e) => {
    e.preventDefault();
    setShowDebugPanel(!showDebugPanel);
  });

  return (
    <div style={{ position: "absolute", top: "80px", left: "40px", width: "200px" }}>
      <Leva hidden={!showDebugPanel} oneLineLabels fill flat />
    </div>
  );
}

export default DebugPanel;
