import { Box, StyleProps } from "@chakra-ui/react";
import { RenderModeDropdown } from "./RenderModeDropdown";
import { ColorwaysDropdown } from "./ColorwaysDropdown";
import { spacing } from "@design-system";
import { useSemanticTokens } from "@design-system/hooks/useSemanticTokens";

export function VisualizationSettingsToolbar(props: StyleProps) {
  const semanticTokens = useSemanticTokens();

  return (
    <Box position="absolute" top={spacing.space[100]} right={spacing.space[100]} zIndex="10" {...props}>
      <Box
        backgroundColor={semanticTokens.surface.classic.secondary}
        backdropFilter="blur(10px)"
        display="flex"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.2)"
        border={`1px solid ${semanticTokens.border.classic.primary}`}
        width="88px"
        height="44px"
        position="relative"
        borderRadius="0"
        sx={{
          "& button:hover": {
            backgroundColor: "transparent !important",
            boxShadow: "none !important",
          },
        }}
      >
        <RenderModeDropdown />
        <ColorwaysDropdown />
      </Box>
    </Box>
  );
}
