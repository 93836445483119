import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ColumnDef } from "@tanstack/react-table";
import { EditableInput, DropdownItem, spacing, useSemanticTokens, useThemeTokens, IconDropdown } from "@design-system";
import { Yarn } from "@models/backend";
import { column_config, count_placeholders } from "./yarn-types";
import moment from "moment";

interface GetColumnsProps {
  yarns: Yarn[];
  createCellUpdateHandler: (
    id: Yarn["id"],
    field: keyof Omit<Yarn, "id" | "createdAt" | "updatedAt">,
  ) => (value: string) => void;
  openColorPickerForRow: (rowIndex: number, target: HTMLElement) => void;
  semanticTokens: ReturnType<typeof useSemanticTokens>;
  border: ReturnType<typeof useThemeTokens>["border"];
}

export const getRowColor = (row: { original: Yarn }) => {
  if (row.original.colorValue) {
    return row.original.colorValue;
  }

  if (row.original.nameColor && row.original.nameColor.startsWith("#")) {
    return row.original.nameColor;
  }

  return "#FFFFFF";
};

export const getColumns = ({
  createCellUpdateHandler,
  openColorPickerForRow,
  semanticTokens,
  border,
}: GetColumnsProps): ColumnDef<Yarn>[] => {
  return [
    {
      accessorKey: "image",
      header: () => (
        <Text color={semanticTokens.text.classic.secondary} variant="2xs-regular">
          Image
        </Text>
      ),
      minSize: column_config[0].minWidth,
      enableResizing: true,
      meta: { flex: column_config[0].flex },
      cell: ({ row }) => {
        const backgroundColor = getRowColor(row);
        return (
          <Flex align="center" justify="flex-start" width="100%">
            <Box
              width={spacing.space[600]}
              height={spacing.space[600]}
              backgroundColor={backgroundColor}
              border={border.width}
              borderColor={semanticTokens.border.classic.primary}
              borderRadius="sm"
              cursor="pointer"
              onClick={(e) => {
                e.stopPropagation();
                openColorPickerForRow(row.index, e.currentTarget);
              }}
            />
          </Flex>
        );
      },
    },
    {
      accessorKey: "nameColor",
      header: () => (
        <Text color={semanticTokens.text.classic.secondary} variant="2xs-regular" paddingLeft="2px">
          Name Color
        </Text>
      ),
      minSize: column_config[1].minWidth,
      enableResizing: true,
      meta: { flex: column_config[1].flex },
      cell: ({ row }) => (
        <EditableInput
          initialValue={row.original.nameColor}
          isEditable={true}
          onChange={createCellUpdateHandler(row.original.id, "nameColor")}
          fillContainer={true}
        />
      ),
    },
    {
      accessorKey: "material",
      header: () => (
        <Text color={semanticTokens.text.classic.secondary} variant="2xs-regular" paddingLeft="2px">
          Material
        </Text>
      ),
      minSize: column_config[2].minWidth,
      enableResizing: true,
      meta: { flex: column_config[2].flex },
      cell: ({ row }) => (
        <EditableInput
          initialValue={row.original.material}
          isEditable={true}
          onChange={createCellUpdateHandler(row.original.id, "material")}
          fillContainer={true}
        />
      ),
    },
    {
      accessorKey: "manufacturer",
      header: () => (
        <Text color={semanticTokens.text.classic.secondary} variant="2xs-regular" paddingLeft="2px">
          Manufacturer
        </Text>
      ),
      minSize: column_config[3].minWidth,
      enableResizing: true,
      meta: { flex: column_config[3].flex },
      cell: ({ row }) => (
        <EditableInput
          initialValue={row.original.manufacturer}
          isEditable={true}
          onChange={createCellUpdateHandler(row.original.id, "manufacturer")}
          fillContainer={true}
        />
      ),
    },
    {
      accessorKey: "count",
      header: () => (
        <Text color={semanticTokens.text.classic.secondary} variant="2xs-regular">
          Count
        </Text>
      ),
      minSize: column_config[4].minWidth,
      enableResizing: true,
      meta: { flex: column_config[4].flex },
      cell: ({ row }) => (
        <EditableInput
          initialValue={row.original.count}
          isEditable={true}
          onChange={createCellUpdateHandler(row.original.id, "count")}
          fillContainer={true}
        />
      ),
    },

    {
      accessorKey: "countType",
      header: () => (
        <Text color={semanticTokens.text.classic.secondary} variant="2xs-regular" paddingLeft="4px">
          Count type
        </Text>
      ),
      minSize: column_config[5].minWidth,
      enableResizing: true,
      meta: { flex: column_config[5].flex },
      cell: ({ row }) => {
        const value = row.original.countType;
        const CustomIcon = () => (
          <Flex align="center" width="100%" justifyContent="flex-start">
            <Text fontSize="inherit" color={semanticTokens.text.classic.primary}>
              {value}
            </Text>
            <ChevronDownIcon boxSize={3} ml={1} color={semanticTokens.icons.primary} />
          </Flex>
        );
        return (
          <IconDropdown
            icon={<CustomIcon />}
            buttonStyleProps={{
              width: "100%",
              height: "100%",
              bg: "transparent",
              color: semanticTokens.text.primary,
              _hover: { bg: "transparent" },
              _active: { bg: "transparent" },
              px: "4px",
              py: 0,
              borderRadius: 0,
              justifyContent: "flex-start",
              minH: "unset",
              fontSize: "inherit",
              cursor: "pointer",
            }}
            menuListStyleProps={{
              width: "176px",
              minW: "176px",
              borderWidth: "1px",
              borderRadius: "4px",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.3)",
              py: 1,
              fontSize: "sm",
            }}
            label=""
          >
            {count_placeholders.map((option) => (
              <DropdownItem
                key={option}
                value={option}
                selectedValue={value}
                setSelectedValue={createCellUpdateHandler(row.original.id, "countType")}
                label={option}
              />
            ))}
          </IconDropdown>
        );
      },
    },
    {
      accessorKey: "thickness",
      header: () => (
        <Text color={semanticTokens.text.classic.secondary} variant="2xs-regular">
          Thickness (mm)
        </Text>
      ),
      minSize: column_config[6].minWidth,
      enableResizing: true,
      meta: { flex: column_config[6].flex },
      cell: ({ row }) => (
        <EditableInput
          initialValue={row.original.thickness === null ? "" : row.original.thickness}
          isEditable={true}
          onChange={createCellUpdateHandler(row.original.id, "thickness")}
          fillContainer={true}
        />
      ),
    },
    {
      accessorKey: "fiberSize",
      header: () => (
        <Text color={semanticTokens.text.classic.secondary} variant="2xs-regular">
          Fiber size (mm)
        </Text>
      ),
      minSize: column_config[7].minWidth,
      enableResizing: true,
      meta: { flex: column_config[7].flex },
      cell: ({ row }) => (
        <EditableInput
          initialValue={row.original.fiberSize === null ? "" : row.original.fiberSize}
          isEditable={true}
          onChange={createCellUpdateHandler(row.original.id, "fiberSize")}
          fillContainer={true}
        />
      ),
    },
    {
      accessorKey: "twistPerCm",
      header: () => (
        <Text color={semanticTokens.text.classic.secondary} variant="2xs-regular">
          Twist (mm)
        </Text>
      ),
      minSize: column_config[8].minWidth,
      maxSize: column_config[8].maxWidth,
      enableResizing: true,
      meta: { flex: column_config[8].flex },
      cell: ({ row }) => (
        <EditableInput
          initialValue={row.original.twistPerCm === null ? "" : row.original.twistPerCm}
          isEditable={true}
          onChange={createCellUpdateHandler(row.original.id, "twistPerCm")}
          fillContainer={true}
        />
      ),
    },
    {
      accessorKey: "lastEdited",
      header: () => (
        <Text color={semanticTokens.text.classic.secondary} variant="2xs-regular" paddingLeft="2px">
          Last edited
        </Text>
      ),
      minSize: 150,
      enableResizing: true,
      meta: { flex: 1 },
      cell: ({ row }) => {
        const editorName = row.original.member?.name;

        return (
          <Text
            color={semanticTokens.text.classic.secondary}
            variant="xs-regular"
            paddingLeft="2px"
            cursor="not-allowed"
            key={`editor-${row.original.id}-${row.original.member?.id}`}
          >
            {editorName || ""}
          </Text>
        );
      },
    },
    {
      accessorKey: "editedTime",
      header: () => (
        <Text color={semanticTokens.text.classic.secondary} variant="2xs-regular" paddingLeft="2px">
          Edited time
        </Text>
      ),
      minSize: 150,
      enableResizing: true,
      meta: { flex: 1 },
      cell: ({ row }) => {
        const updatedAt = row.original.updatedAt;
        let formattedTime = "";

        if (updatedAt) {
          formattedTime = moment(updatedAt).fromNow();
        }

        return (
          <Text
            color={semanticTokens.text.classic.secondary}
            variant="xs-regular"
            paddingLeft="2px"
            cursor="not-allowed"
            key={updatedAt?.toString()}
          >
            {formattedTime}
          </Text>
        );
      },
    },
  ];
};

export const useColumns = ({
  yarns,
  createCellUpdateHandler,
  openColorPickerForRow,
}: Omit<GetColumnsProps, "semanticTokens" | "border">) => {
  const semanticTokens = useSemanticTokens();
  const { border } = useThemeTokens();

  return React.useMemo(
    () =>
      getColumns({
        yarns,
        createCellUpdateHandler,
        openColorPickerForRow,
        semanticTokens,
        border,
      }),
    [yarns, createCellUpdateHandler, openColorPickerForRow, semanticTokens, border],
  );
};
