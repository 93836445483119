import React, { useState, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { Input, Text, Box, BoxProps, Flex } from "@chakra-ui/react";
import { spacing } from "@design-system";

export interface EditableInputProps extends Omit<BoxProps, "onChange"> {
  initialValue: string | number | undefined;
  textColor?: string;
  textVariant?: string;
  onChange?: (newValue: string) => void;
  isEditable?: boolean;
  fillContainer?: boolean;
  onContextMenu?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onlyAllowNumbers?: boolean;
  textPrefix?: string;
  textSuffix?: string;
  validateValue?: (value: string) => string;
  pl?: string | number;
}

export interface EditableInputHandle {
  startEditing: () => void;
}

export const EditableInput = forwardRef<EditableInputHandle, EditableInputProps>(
  (
    {
      initialValue,
      textColor,
      textVariant,
      onChange,
      isEditable = true,
      fillContainer = false,
      onContextMenu,
      onlyAllowNumbers = false,
      textPrefix = "",
      textSuffix = "",
      validateValue,
      pl = spacing.space[100],
      ...boxProps
    },
    ref,
  ) => {
    const stringValue = initialValue !== undefined ? String(initialValue) : "";

    const [isEditing, setIsEditing] = useState(false);
    const [newValue, setNewValue] = useState(stringValue);
    const inputRef = useRef<HTMLInputElement>(null);
    const textRef = useRef<HTMLParagraphElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
      ref,
      () => ({
        startEditing: () => {
          if (isEditable) {
            setIsEditing(true);

            if (textRef.current && !isEditing) {
              const dblClickEvent = new MouseEvent("dblclick", {
                bubbles: true,
                cancelable: true,
                view: window,
              });
              textRef.current.dispatchEvent(dblClickEvent);
            }
          }
        },
      }),
      [isEditable, isEditing],
    );

    useEffect(() => {
      if (!isEditing) {
        setNewValue(initialValue !== undefined ? String(initialValue) : "");
      }
    }, [initialValue, isEditing]);

    useEffect(() => {
      if (isEditing && inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, [isEditing]);

    useEffect(() => {
      if (!isEditing) return;

      const handleClickOutside = (event: MouseEvent) => {
        if (
          containerRef.current &&
          !containerRef.current.contains(event.target as Node) &&
          inputRef.current !== event.target
        ) {
          finishEditing();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isEditing]);

    const finishEditing = useCallback(() => {
      if (!isEditing) return;

      let finalValue = newValue;

      if (validateValue) {
        finalValue = validateValue(newValue);
      }

      if (finalValue !== stringValue) {
        setIsEditing(false);
        setNewValue(finalValue);
        onChange?.(finalValue);
      } else {
        setIsEditing(false);
      }
    }, [isEditing, newValue, stringValue, onChange, validateValue]);

    const cancelEditing = useCallback(() => {
      setNewValue(stringValue);
      setIsEditing(false);
    }, [stringValue]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation();

      if (e.key === "Enter") {
        finishEditing();
      } else if (e.key === "Escape") {
        cancelEditing();
      }
    };

    const handleDoubleClick = () => {
      if (isEditable) {
        setIsEditing(true);
      }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (onlyAllowNumbers) {
        if (/^[0-9]*$/.test(value)) {
          setNewValue(value);
        }
      } else {
        setNewValue(value);
      }
    };

    return (
      <Box
        ref={containerRef}
        height={fillContainer ? "100%" : "20px"}
        display="flex"
        alignItems="center"
        width="100%"
        overflow="hidden"
        onContextMenu={onContextMenu}
        {...boxProps}
      >
        {isEditing && isEditable ? (
          <Input
            ref={inputRef}
            value={newValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            size="sm"
            width="100%"
            height={fillContainer ? "90%" : "20px"}
            padding={spacing.space["50"]}
            paddingLeft={pl !== undefined ? pl : spacing.space["100"]}
            backgroundColor="#FFF"
            color="#242424"
            onContextMenu={onContextMenu}
            textAlign="left"
            _hover={{ backgroundColor: "#FFF" }}
            _focus={{
              boxShadow: "none",
              borderColor: "#F75F2D",
              backgroundColor: "#FFF",
            }}
            sx={{
              "&::selection": {
                backgroundColor: "rgba(247, 95, 45, 0.2)",
                color: "inherit",
              },
            }}
          />
        ) : (
          <Flex
            width="100%"
            height={fillContainer ? "100%" : "auto"}
            alignItems="center"
            padding={spacing.space["50"]}
            paddingLeft={pl !== undefined ? pl : spacing.space["100"]}
            cursor={isEditable ? "text" : "default"}
            onDoubleClick={handleDoubleClick}
            onContextMenu={onContextMenu}
            justifyContent="flex-start"
          >
            {textPrefix && (
              <Text variant={textVariant} color={textColor} flexShrink={0} mr={1}>
                {textPrefix}
              </Text>
            )}
            <Text ref={textRef} variant={textVariant} color={textColor} isTruncated flex="0 auto">
              {newValue}
            </Text>
            {textSuffix && (
              <Text variant={textVariant} color={textColor} flexShrink={0} ml={1}>
                {textSuffix}
              </Text>
            )}
          </Flex>
        )}
      </Box>
    );
  },
);
