import { YarnToken } from "@models/backend";
import { GetState, SetState } from "zustand";
import { YarnTokensState, ModelsStore } from "../types";

export const yarnTokensSlice = (set: SetState<ModelsStore>, get: GetState<ModelsStore>): YarnTokensState => ({
  yarnTokens: [],

  setYarnTokens: (yarnTokens: YarnToken[]) => {
    set({ yarnTokens: yarnTokens });
  },
  setYarnToken: (yarnToken) => {
    set({ yarnTokens: get().yarnTokens.map((y) => (y.id === yarnToken.id ? yarnToken : y)) });
  },
});
