import { useDisclosure } from "@chakra-ui/react";
import { MouseEvent, useState } from "react";

export function useRowContextMenu() {
  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number } | null>(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleRowContextMenu = (event: MouseEvent, rowIndex: number) => {
    event.preventDefault();

    setContextMenuPosition({ x: event.clientX, y: event.clientY });

    setSelectedRowIndex(rowIndex);

    onOpen();
  };

  const handleCloseContextMenu = () => {
    onClose();
    setContextMenuPosition(null);
  };

  return {
    contextMenuPosition,
    selectedRowIndex,
    isContextMenuOpen: isOpen,
    handleRowContextMenu,
    handleCloseContextMenu,
  };
}
