import BackendApi from "@api/BackendApi";
import { useBackendApi } from "@api/useBackendApi.ts";
import { useAuth } from "@auth";
import { Yarn } from "@models/backend";
import { useYarnsStore } from "@state";
import { recordToArray } from "@utils";
import { useCallback, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useYarnUndoRedo } from "./useYarnUndoRedo";

type YarnAttributes = Omit<Yarn, "id" | "createdAt" | "updatedAt">;

export function useYarnLibrary(organizationId: string) {
  const { headers, user } = useAuth();
  const { isLoading, error, result } = useBackendApi(BackendApi.getYarns, { params: { organizationId }, headers }, [
    organizationId,
  ]);

  const {
    yarns,
    setYarns: setYarnsInStore,
    updateYarn: updateYarnInStore,
    removeYarn: removeYarnFromStore,
  } = useYarnsStore();

  const { clear: clearUndoRedoState, pause, resume } = useYarnUndoRedo(organizationId);

  useEffect(() => {
    setYarnsInStore(result ?? []);
    clearUndoRedoState();
  }, [result, setYarnsInStore]);

  const updateYarnOnBackend = useCallback(
    async (id: string, body: Partial<YarnAttributes>, previous: Yarn) => {
      pause();

      try {
        const yarn = await BackendApi.updateYarn({ headers, params: { organizationId, id }, body });
        updateYarnInStore(yarn);
      } catch (e) {
        updateYarnInStore(previous);
        throw e;
      } finally {
        resume();
      }
    },
    [organizationId, headers],
  );

  const updateYarnOnBackendDebounced = useDebouncedCallback(updateYarnOnBackend, 1000);

  const createYarn = useCallback(
    async (body: YarnAttributes) => {
      const result = await BackendApi.createYarn({ params: { organizationId }, body, headers });
      updateYarnInStore(result);
      return result;
    },
    [organizationId, headers, updateYarnInStore],
  );

  const updateYarn = useCallback(
    async (id: Yarn["id"], body: Partial<YarnAttributes>) => {
      const yarn = yarns[id];

      if (!yarn) {
        throw new Error("yarn with id " + id + " not found");
      }

      const updatedYarn: Yarn = {
        ...yarn,
        ...body,
        updatedAt: new Date(),
        member: user,
      };

      updateYarnInStore(updatedYarn);
      updateYarnOnBackendDebounced(id, { ...body }, yarn);

      return updatedYarn;
    },
    [yarns, updateYarnInStore, updateYarnOnBackendDebounced, user],
  );

  const deleteYarn = useCallback(
    async (id: Yarn["id"]) => {
      const yarn = yarns[id];

      if (!yarn) {
        throw new Error("yarn with id " + id + " not found");
      }

      removeYarnFromStore(id);

      pause();
      BackendApi.deleteYarn({ headers, params: { organizationId, id } })
        .catch(() => updateYarnInStore(yarn))
        .finally(resume);

      return true;
    },
    [organizationId, headers, yarns, removeYarnFromStore, updateYarnInStore],
  );

  return {
    yarns: recordToArray(yarns),
    isLoading,
    error,
    createYarn,
    updateYarn,
    deleteYarn,
  };
}
