import { resolveHexValue, utility } from "@design-system";
import { Project, ProjectKnitStructure } from "@models/backend";
import { Model } from "@models/project";
import { useBounds } from "@react-three/drei";
import { useModelsStore } from "@state/models";
import { useProjectState } from "@state/project";
import { computeZoneKnitStructure, getMeshFromGeometry, OnClickParams } from "@utils";
import { centerCamera } from "@utils/project/camera";
import { recordToArray } from "@utils/project/collection";
import { getV3dApi } from "@utils/project/initV3dApi.ts";
import { sortPathCollections } from "@utils/project/pathCollections";
import { Mesh3DBase } from "@variant-tech/pattern-derivation";
import { useEffect, useMemo } from "react";
import { Color, Mesh, MeshPhysicalMaterial } from "three";
import { _3DTools } from "./_3DTools";
import KnitMeshViewer from "./KnitMeshViewer";
import { Part } from "./Part";
import { Path } from "./Path";
import { workspace3dTokens } from "./workspace-3d-tokens";
import { Zone } from "./Zone";

export interface ModelProps {
  project: Project;
  model: Model;
  onClick?: (params: OnClickParams) => void;
}

const generateMesh = async (mesh3DBase: Mesh3DBase, scale: number): Promise<Mesh> => {
  await mesh3DBase.setScale(scale, scale, scale);
  const { faces, vertices } = await getV3dApi().generateMesh(mesh3DBase);
  return getMeshFromGeometry({ vertices, faces });
};

function ModelViewer({ project, model, onClick }: ModelProps) {
  const bounds = useBounds();
  const { pathCollections: pathCollectionsSlice, selectedObjects, models, meshes, process, setMesh } = useModelsStore();
  useEffect(() => {
    [
      model,
      ...model.references
        .map(({ id: referenceId }) => models.find(({ id }) => id === referenceId))
        .filter((reference) => !!reference),
    ].map(({ id, mesh3DBase }) => generateMesh(mesh3DBase, model.scale).then((mesh) => setMesh(id, mesh)));
  }, [model.references, model.scale]);
  const mesh = meshes[model.id]?.mesh;

  const { collections } = pathCollectionsSlice[model.id] ?? { collections: {}, newCollection: null };

  const knitStructures = useModelsStore((state) => state.knitStructures);
  const selectedKnitStructure = useMemo(() => {
    const modelKnitStructure = model.attributes?.knitStructure;
    const defaultKnitStructure = knitStructures.find(({ isDefault }) => isDefault);
    return modelKnitStructure ?? ({ ...defaultKnitStructure, yarnTokenIds: [] } as ProjectKnitStructure);
  }, [model.attributes?.knitStructure, knitStructures]);

  const projectKnitStructures = useMemo(() => {
    const ordered = collections ? sortPathCollections(recordToArray(collections), model.zoneOrder) : [];
    const zones = ordered.filter((pathCollection) => pathCollection.usage === "zone");

    const projectKnitStructures = [selectedKnitStructure];
    zones.map((zone) => {
      const zoneKnitStructure = computeZoneKnitStructure(zone, model);
      if (zoneKnitStructure) {
        projectKnitStructures.push(zoneKnitStructure);
      }
    });
    return projectKnitStructures;
  }, [collections, selectedKnitStructure]);

  const { _3DToolbarSelection, renderModeSelection } = useProjectState();

  const { pathCollectionsViz, zonesViz } = useMemo(() => {
    if (!mesh) {
      return { pathCollectionsViz: [], zonesViz: [] };
    }
    const pathCollections = sortPathCollections(recordToArray(collections), model.zoneOrder);
    const zonesViz = pathCollections
      .filter(({ usage, id }) => id && usage === "zone")
      .map(({ id }, index) => <Zone key={id} model={model} index={index} pathCollectionId={id!} onClick={onClick} />);
    const pathCollectionsViz = pathCollections.map((collection) => {
      const isCurrentlySelected = selectedObjects.find(({ id }) => id === collection.id) !== undefined;

      return (
        <Path
          key={collection.id}
          project={project}
          model={model}
          mesh={mesh}
          collectionId={collection.id}
          collectionUsage={collection.usage}
          isSelectionAllowed={_3DToolbarSelection === "select" || _3DToolbarSelection === "pen"}
          isHoverAllowed={_3DToolbarSelection === "select" || isCurrentlySelected}
          highViz={renderModeSelection !== "design"}
          onClick={onClick}
        />
      );
    });
    return { zonesViz, pathCollectionsViz };
  }, [mesh, collections, model.zoneOrder]);

  useEffect(() => {
    if (mesh) {
      centerCamera(mesh, bounds);
    }
  }, [mesh, bounds]);

  const meshMaterial = useMemo(() => {
    return new MeshPhysicalMaterial({
      color: new Color(selectedKnitStructure?.color ?? utility.lightGray),
      ...workspace3dTokens.material,
    });
  }, [selectedKnitStructure?.color]);

  if (!mesh) {
    return null;
  }

  return (
    <group>
      {model.references
        .map((reference) => ({ reference, mesh: meshes[reference.id]?.mesh }))
        .filter(({ mesh }) => !!mesh)
        .map(({ mesh, reference }) => (
          <mesh
            key={reference.id}
            name={reference.name}
            geometry={mesh.geometry}
            material={
              new MeshPhysicalMaterial({
                color: new Color(resolveHexValue(reference.attributes.meshSettings?.color ?? utility.lightGray)),
                ...workspace3dTokens.material,
              })
            }
            renderOrder={-1}
            onClick={(e) => onClick?.({ ...e, id: reference.id })}
          />
        ))}
      <group>
        {mesh && (
          <mesh
            name="model"
            geometry={mesh.geometry}
            material={meshMaterial}
            visible={renderModeSelection === "design"}
            onClick={(e) => onClick?.({ ...e, id: model.id })}
          />
        )}
        {
          <KnitMeshViewer
            fallbackMesh={mesh}
            project={project}
            process={process}
            projectKnitStructures={projectKnitStructures}
            visible={renderModeSelection !== "design"}
          />
        }
        {<_3DTools project={project} model={model} mesh={mesh} />}
        {pathCollectionsViz}
        {renderModeSelection === "design" && zonesViz}
        <Part project={project} model={model} mesh={mesh} />
      </group>
    </group>
  );
}

export default ModelViewer;
