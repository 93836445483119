import { Project, ProjectKnitStructure } from "@models/backend";
import { useEffect, useMemo, useState } from "react";
import { Process } from "@models/project";
import { useUpdateKnitMesh } from "@hooks";
import { useModelsStore, useProjectState, useYarnsStore } from "@state";
import { DoubleSide, Mesh, ShaderMaterial, Texture, Vector4 } from "three";
import { schematicFragShader, glslLibrary, structureGenerator } from "@utils";
import { useThree } from "@react-three/fiber";
import { resolveHexValueRgbaNormalized, useSemanticTokens } from "@design-system";
import { SchematicShaderUniforms } from "./SchematicShaderUniforms";
import { useColorwayYarns } from "@hooks/project/useColorwayYarns";

export interface KnitMeshProps {
  fallbackMesh: Mesh;
  project: Project;
  process?: Process;
  projectKnitStructures: ProjectKnitStructure[];
  visible: boolean;
}

function KnitMeshViewer({ fallbackMesh, process, projectKnitStructures, visible }: KnitMeshProps) {
  const uniforms = SchematicShaderUniforms();

  const mesh = process?.mesh ?? fallbackMesh;
  const bmpTexture = process?.bmpTexture;
  const dataMapTexture = process?.dataMapTexture;
  const neighborMapTexture = process?.neighborMapTexture;

  const { updateKnitMesh } = useUpdateKnitMesh();

  const { camera } = useThree();

  const { colorways, knitMeshDirty, yarnTokens, selectedColorwayId } = useModelsStore((s) => s);

  const { yarns } = useYarnsStore();
  const { getYarnUniformsForColorway } = useColorwayYarns({
    colorwayId: selectedColorwayId || colorways[0]?.id,
  });

  const { renderModeSelection } = useProjectState((s) => s);

  const displayMode = renderModeSelection === "knitmesh" ? 0 : 1;
  const displayEdges = renderModeSelection === "knitmesh" ? true : false;

  const containerBg = resolveHexValueRgbaNormalized(useSemanticTokens().surface.secondary);

  const [knitCellTexture, setKnitCellTexture] = useState<Texture | undefined>();

  const [zoneKnitStructureColors, setZoneKnitStructureColors] = useState<Vector4[]>();

  const yarnUniforms = useMemo(() => {
    return getYarnUniformsForColorway();
  }, [selectedColorwayId, colorways, yarns, yarnTokens]);

  useEffect(() => {
    const update = async () => {
      await updateKnitMesh();
    };

    if (knitMeshDirty && visible) {
      update();
    }
  }, [knitMeshDirty, visible]);

  useEffect(() => {
    if (dataMapTexture) {
      const colorsNormalized = projectKnitStructures.map((structure) => {
        if (structure && structure.color) {
          const array = resolveHexValueRgbaNormalized(structure.color);
          return new Vector4(array[0], array[1], array[2], 1.0);
        } else {
          console.warn("knit structure with undefined color");
          // TODO: establish an "error" color for when e.g. something fails to load
          return new Vector4(1.0, 0.0, 1.0, 1.0);
        }
      });
      setZoneKnitStructureColors(colorsNormalized);

      const currentColorwayId = selectedColorwayId || colorways[0]?.id;
      const currentColorway = colorways.find((c) => c.id === currentColorwayId) || colorways[0];
      const texture = structureGenerator(dataMapTexture, projectKnitStructures, currentColorway);
      setKnitCellTexture(texture);
    } else {
      setKnitCellTexture(undefined);
    }
  }, [dataMapTexture, projectKnitStructures, yarnUniforms, selectedColorwayId, colorways]);

  const schematicShaderMaterial = useMemo(() => {
    if (!bmpTexture || !neighborMapTexture || !dataMapTexture || !zoneKnitStructureColors) return;
    const material = new ShaderMaterial({
      defines: {
        NUM_YARNS: yarnUniforms.length,
        NUM_STRUCTURE_COLORS: zoneKnitStructureColors?.length ?? 0,
      },
      vertexShader: /*glsl*/ `
        varying vec2 vUv;
        varying float vCameraDistance;
        void main() {
          vUv = uv;
          vec4 worldPosition = modelMatrix * vec4(position, 1.0);
          vCameraDistance = distance(worldPosition.xyz, cameraPosition);
          gl_Position = projectionMatrix * viewMatrix * worldPosition;
      }`,

      fragmentShader: glslLibrary + schematicFragShader,

      uniforms: {
        bmpTexture: { value: bmpTexture },
        dataMapTexture: { value: dataMapTexture },
        neighborMapTexture: { value: neighborMapTexture },
        knitCellTexture: { value: knitCellTexture },
        colorBackground: { value: containerBg },
        cameraNear: { value: camera.near },
        cameraFar: { value: camera.far },
        p2dim: { value: bmpTexture.image.width },
        displayMode: { value: displayMode },
        displayEdges: { value: displayEdges },
        // debug / finetuning uniforms follow
        animation: { value: uniforms.animation },
        legHalfWidth: { value: uniforms.legHalfWidth },
        legHalfPadding: { value: uniforms.legHalfPadding },
        loopHalfWidth: { value: uniforms.loopHalfWidth },
        loopHalfPaddingX: { value: uniforms.loopHalfPaddingX },
        loopHalfPaddingY: { value: uniforms.loopHalfPaddingY },
        loopHalfBend: { value: uniforms.loopHalfBend },
        loopGoringOffset: { value: uniforms.loopGoringOffset },
        loopGoringAAScalar: { value: uniforms.loopGoringAAScalar },
        farSideScale: { value: uniforms.farSideScale },
        farSideDarkening: { value: uniforms.farSideDarkening },
        distBlur: { value: uniforms.distBlur },
        transparency: { value: uniforms.transparency },
        useColorFromStructure: { value: uniforms.useColorFromStructure || renderModeSelection === "knitmesh" },
        zoneColors: { value: zoneKnitStructureColors },
        yarns: { value: yarnUniforms },
      },
      side: DoubleSide,
    });
    return material;
  }, [
    camera,
    bmpTexture,
    dataMapTexture,
    neighborMapTexture,
    knitCellTexture,
    renderModeSelection,
    uniforms,
    yarnUniforms,
    zoneKnitStructureColors,
  ]);

  const material = process ? schematicShaderMaterial : fallbackMesh.material;

  return <group>{mesh && <mesh name="model" geometry={mesh.geometry} material={material} visible={visible} />}</group>;
}

export default KnitMeshViewer;
