import { ModelsStore, PathInfo, SectionInfo, ColumnInfo } from "../types";
import { SetState } from "zustand";

export const infoSlice = (set: SetState<ModelsStore>) => ({
  pathsInfo: {},
  columnsInfo: {},
  sectionsInfo: {},
  setPathsInfo: (info: { [id: string]: PathInfo }) =>
    set(({ pathsInfo }) => ({
      pathsInfo: {
        ...pathsInfo,
        ...info,
      },
    })),
  setSectionsInfo: (info: { [id: string]: SectionInfo }) =>
    set(({ sectionsInfo }) => ({
      sectionsInfo: {
        ...sectionsInfo,
        ...info,
      },
    })),
  setColumnsInfo: (info: { [id: string]: ColumnInfo }) =>
    set(({ columnsInfo }) => ({
      columnsInfo: {
        ...columnsInfo,
        ...info,
      },
    })),
  clearInfo: () => set(() => ({ pathsInfo: {}, sectionsInfo: {}, columnsInfo: {} })),
});
