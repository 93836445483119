import { useCallback, useRef, useState, useEffect } from "react";
import { Box, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useSemanticTokens, spacing, IconDropdown, DropdownItem } from "@design-system";
import { EditableInput } from "@design-system/components/EditableInput";
import { Yarn } from "@models/backend";
import { useTranslation } from "@hooks";

interface ColorwayOption {
  id: string;
  material: string;
  colorValue: string;
  yarn: Yarn & {
    name?: string;
    nameColor?: string;
  };
}

interface ColorwayCellProps {
  colorway: ColorwayOption;
  ends: string;
  rowId: string;
  colorwayId: string;
  colorwayOptions: ColorwayOption[];
  handleColorwayUpdate: (id: string, colorwayId: string) => (value: string) => void;
  handleEndsUpdate: (id: string, colorwayId: string) => (value: string) => void;
}

const useStableDropdown = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handlePreventClose = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  return { isOpen, onOpen, onClose, handlePreventClose };
};

export const ColorwayCell: React.FC<ColorwayCellProps> = ({
  colorway,
  ends,
  rowId,
  colorwayId,
  colorwayOptions,
  handleColorwayUpdate,
  handleEndsUpdate,
}) => {
  const semanticTokens = useSemanticTokens();
  const { t } = useTranslation("project3D");
  const { isOpen, onOpen, onClose, handlePreventClose } = useStableDropdown();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [placement, setPlacement] = useState<"bottom-start" | "top-start">("bottom-start");
  const [currentEnds, setCurrentEnds] = useState(ends);
  const [endsSuffix, setEndsSuffix] = useState(
    ends === "1" ? t("colorways.ends.single") : t("colorways.ends.multiple"),
  );

  useEffect(() => {
    setCurrentEnds(ends);
    setEndsSuffix(ends === "1" ? t("colorways.ends.single") : t("colorways.ends.multiple"));
  }, [ends, t]);

  const handleOpen = useCallback(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const bottomSpace = viewportHeight - rect.bottom;

      if (bottomSpace < 200) {
        setPlacement("top-start");
      } else {
        setPlacement("bottom-start");
      }
    }
    onOpen();
  }, [onOpen]);

  const handleDropdownClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const preventCloseAndUpdate = (value: string) => {
    handleColorwayUpdate(rowId, colorwayId)(value);
    setTimeout(() => {
      if (dropdownRef.current) {
        dropdownRef.current.focus();
      }
    }, 50);
  };

  const handleEndValueChange = (value: string) => {
    setCurrentEnds(value);
    setEndsSuffix(value === "1" ? t("colorways.ends.single") : t("colorways.ends.multiple"));
    handleEndsUpdate(rowId, colorwayId)(value);
  };

  const ColorwayIcon = () => (
    <Flex align="center" width="100%" justifyContent="flex-start">
      <Box
        width={spacing.space[400]}
        height={spacing.space[400]}
        borderRadius="sm"
        backgroundColor={colorway?.colorValue || "#CCCCCC"}
        borderWidth={semanticTokens.border.border}
        borderColor={semanticTokens.border.classic.primary}
        mr={spacing.space[100]}
        flexShrink={0}
      />
      <Text
        fontSize="xs"
        color={semanticTokens.text.classic.primary}
        isTruncated
        flex="1"
        maxW="140px"
        textAlign="left"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {colorway?.yarn?.nameColor || colorway?.material || "Select yarn"}
      </Text>
      <ChevronDownIcon boxSize={3} ml={spacing.space[100]} color={semanticTokens.icons.primary} flexShrink={0} />
    </Flex>
  );

  return (
    <Flex align="center" width="100%" height="100%" ref={containerRef}>
      <Box width="190px" maxW="65%" mr={1} onClick={handleDropdownClick} pl={spacing.space[50]}>
        <IconDropdown
          icon={<ColorwayIcon />}
          isOpen={isOpen}
          onOpen={handleOpen}
          onClose={onClose}
          buttonStyleProps={{
            width: "100%",
            height: "100%",
            maxH: "20px",
            padding: spacing.space[100],
            bg: semanticTokens.surface.classic.secondary,
            color: semanticTokens.surface.classic.secondary,
            px: spacing.space[100],
            py: 0,
            borderRadius: 0,
            justifyContent: "flex-start",
            minH: "unset",
            fontSize: "inherit",
            cursor: "pointer",
          }}
          menuListStyleProps={{
            width: "200px",
            minW: "200px",
            borderWidth: "1px",
            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.3)",
            py: 0,
            fontSize: "sm",
            bg: semanticTokens.surface.classic.primary,
            maxHeight: "150px",
            overflowY: "auto",
            position: "relative",
            pointerEvents: "auto",
            zIndex: 10,
            variant: "2xs-regular",
          }}
          placement={placement}
          label=""
        >
          <Box
            ref={dropdownRef}
            onClick={handlePreventClose}
            onMouseDown={handlePreventClose}
            position="relative"
            tabIndex={-1}
            sx={{
              "&::-webkit-scrollbar": {
                width: "6px",
                backgroundColor: semanticTokens.surface.classic.primary,
              },
              "&::-webkit-scrollbar-track": {
                background: semanticTokens.surface.classic.primary,
              },
              "&::-webkit-scrollbar-thumb": {
                background: semanticTokens.surface.classic.primary,
                borderRadius: "3px",
              },
            }}
          >
            {colorwayOptions.map((option) => (
              <DropdownItem
                key={option.id}
                value={option.id}
                selectedValue={colorway?.yarn?.id}
                setSelectedValue={preventCloseAndUpdate}
                hideSelectedCheck={true}
                content={
                  <Flex
                    py={spacing.space[300]}
                    px={spacing.space[300]}
                    width="100%"
                    align="center"
                    _hover={{
                      bg: semanticTokens.surface.classic.secondary,
                    }}
                  >
                    <Box
                      width={spacing.space[400]}
                      height={spacing.space[400]}
                      borderRadius="sm"
                      backgroundColor={option.colorValue}
                      borderWidth="1px"
                      borderColor={semanticTokens.border.classic.primary}
                      mr={spacing.space[300]}
                      flexShrink={0}
                    />
                    <Flex direction="column" flex="1" maxW="200px" overflow="hidden">
                      <Text
                        color={semanticTokens.text.classic.primary}
                        fontSize="sm"
                        isTruncated
                        textAlign="left"
                        fontWeight="medium"
                      >
                        {option.yarn?.nameColor || ""}
                      </Text>
                      <Text
                        color={semanticTokens.text.classic.secondary}
                        variant="2xs-regular"
                        isTruncated
                        textAlign="left"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {option.material}
                      </Text>
                    </Flex>
                  </Flex>
                }
                styleProps={{
                  py: 0,
                  bg: "transparent",
                }}
              />
            ))}
          </Box>
        </IconDropdown>
      </Box>
      <Box flexGrow={1} pl={spacing.space[50]}>
        <EditableInput
          initialValue={currentEnds}
          textColor={semanticTokens.text.classic.primary}
          textVariant="2xs-regular"
          onChange={handleEndValueChange}
          bg={semanticTokens.surface.classic.secondary}
          fillContainer={true}
          onlyAllowNumbers={true}
          textSuffix={endsSuffix}
          height="20px"
          width="55px"
          validateValue={(value: string) => {
            const num = parseInt(value, 10);
            let validatedValue = value;

            if (isNaN(num) || num < 1) {
              validatedValue = "1";
              setTimeout(() => setEndsSuffix(t("colorways.ends.single")), 0);
            } else if (num > 30) {
              validatedValue = "30";
              setTimeout(() => setEndsSuffix(t("colorways.ends.multiple")), 0);
            }

            return validatedValue;
          }}
        />
      </Box>
    </Flex>
  );
};
