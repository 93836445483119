import { modelAttributes as defaultAttributes } from "@defaults";
import { Model } from "@models/project";
import { arrayToRecord, createPathCollection } from "@utils";
import { defaultsDeep, omit } from "lodash";
import { StoreApi } from "zustand";
import { ModelsStore, PathCollections, UpdateModelProps } from "../types";

export const modelsSlice = (set: StoreApi<ModelsStore>["setState"]) => ({
  models: [],
  addModel: async (model: Model) => {
    const modelWithAttributes = {
      ...model,
      attributes: defaultsDeep(model.attributes, defaultAttributes),
    };
    const newCollection = createPathCollection();
    set(({ models, pathCollections, sections, columns }: ModelsStore) => ({
      models: [...models, modelWithAttributes],
      pathCollections: {
        ...pathCollections,
        [model.id]: {
          newCollection,
          collections: {},
        },
      },
      sections: {
        ...sections,
        [model.id]: {
          sectionCurves: [],
        },
      },
      columns: {
        ...columns,
        [model.id]: {
          columnCurves: [],
        },
      },
      selectedObjects: [{ type: "model", id: model.id }],
    }));
  },
  addReferenceModel: (parentModelId: string, referenceModel: Model) => {
    set((state) => {
      const models = state.models.map((model) => {
        if (model.id === parentModelId) {
          const references = model.references ? [...model.references, referenceModel] : [referenceModel];
          return { ...model, references };
        } else {
          return model;
        }
      });

      return {
        models: [...models, referenceModel],
      };
    });
  },
  setModels: async (models: Model[]) => {
    const modelsWithAttributes = models.map(({ attributes, ...rest }) => ({
      ...rest,
      attributes: defaultsDeep(attributes, defaultAttributes),
    }));
    set(() => {
      const pathCollections: PathCollections = arrayToRecord(
        models,
        (model) => model.id,
        () => ({
          collections: {},
          newCollection: createPathCollection(),
        }),
      );
      return { models: modelsWithAttributes, pathCollections };
    });
  },
  updateModel: (id: string, update: UpdateModelProps) =>
    set(
      ({
        models: currentModels,
        commentThreads: currentCommentThreads,
        pathCollections: currentPathCollections,
        columns: currentColumns,
        sections: currentSections,
      }: ModelsStore) => {
        const index = currentModels.findIndex((model) => model.id === id);

        if (index === -1) {
          throw new Error(`updateModel: Model ${id} not found`);
        }

        const models = [...currentModels];
        const model = { ...currentModels[index], ...omit(update, ["pathCollections", "columns", "sections"]) };
        models[index] = model;

        if (model?.parentId) {
          const parentIndex = currentModels.findIndex(({ id }) => id === model.parentId);

          if (parentIndex !== -1) {
            const parent = models[parentIndex];

            models[parentIndex] = {
              ...parent,
              references: parent.references.map((reference) => (reference.id === id ? model : reference)),
            };
          }
        }

        // TODO: knitMeshDirty should be a param that we can use to control whether or not to reprocess
        // e.g., in the case of changed model yarn token assignments, it's not necessary to reprocess
        // and at the moment i'm just setting knitMeshDirty back to false after updateModel runs in
        // setKnitStructureTokenId in SidebarModelProperties
        const result: Partial<ModelsStore> = { models, knitMeshDirty: true };

        if (update.pathCollections) {
          result.pathCollections = {
            ...currentPathCollections,
            [model.id]: {
              ...currentPathCollections[model.id],
              collections: {
                ...currentPathCollections[model.id].collections,
                ...arrayToRecord(update.pathCollections, (p) => p.id),
              },
            },
          };
        }

        if (update.columnCurves) {
          result.columns = {
            ...currentColumns,
            [model.id]: {
              columnCurves: update.columnCurves,
            },
          };
        }

        if (update.sectionCurves) {
          result.sections = {
            ...currentSections,
            [model.id]: {
              sectionCurves: update.sectionCurves,
            },
          };
        }

        if (update.commentThreads) {
          result.commentThreads = {
            ...currentCommentThreads,
            ...arrayToRecord(update.commentThreads, (c) => c.id),
          };
        }

        return result;
      },
    ),
  removeReferenceModel: async (modelId: string) => {
    set(({ models }: ModelsStore) => {
      const model = models.find(({ id }) => id === modelId);

      if (!model?.parentId) {
        return { models };
      }

      const parent = models.find(({ id }) => id === model.parentId);

      if (parent) {
        parent.references = parent.references.filter(({ id }) => id !== modelId);
      }

      return { models: models.filter(({ id }) => id !== modelId) };
    });
  },
});
