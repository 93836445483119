import { useCallback, useState } from "react";
import { useAuth } from "@auth";
import { useModelsStore } from "@state/models";
import BackendApi from "@api/BackendApi";
import { withLoadingAndErrorHandling } from "@utils";
import { KnitStructure, Project as ProjectData } from "@models/backend";

export function useLoadProjectGlobals(project: ProjectData) {
  const { headers } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const setKnitStructures = useModelsStore((state) => state.setKnitStructures);
  const setMachines = useModelsStore((state) => state.setMachines);
  const setPostprocessorDocuments = useModelsStore((state) => state.setPostprocessorDocuments);

  function transformKnitStructure(structure: KnitStructure) {
    return {
      ...structure,
      courseDensity: structure.courseDensity ? Number(structure.courseDensity) : null,
      waleDensity: structure.waleDensity ? Number(structure.waleDensity) : null,
      yarnTokenCount: structure.yarnTokenCount ? Number(structure.yarnTokenCount) : 0,
    };
  }

  const fetchProjectGlobals = useCallback(async () => {
    return withLoadingAndErrorHandling(setIsLoading, setError, async () => {
      const [postProcessorDocumentsResponse, machinesResponse, knitStructuresResponse] = await Promise.all([
        BackendApi.getPostprocessorDocuments({
          headers,
          params: { organizationId: project.organizationId },
        }),
        BackendApi.getMachines({
          headers,
        }),
        BackendApi.getKnitStructures({
          headers,
        }),
      ]);

      const postprocessorDocuments = postProcessorDocumentsResponse.postprocessorDocuments;
      const machines = machinesResponse.result.machines;
      const transformedStructures = knitStructuresResponse.result.structures.map(transformKnitStructure);

      setPostprocessorDocuments(postprocessorDocuments);
      setMachines(machines);
      setKnitStructures(transformedStructures);

      return { postprocessorDocuments, machines, transformedStructures };
    });
  }, [headers, setPostprocessorDocuments]);

  return {
    fetchProjectGlobals,
    isLoading,
    error,
  };
}
