import { Box, Container } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { useSemanticTokens } from "@design-system";
import { SidebarModelReferenceProperties } from "@fragments/project/sidebars/SidebarModelReferenceProperties";
import { useModelName } from "@hooks";
import { Project as ProjectData } from "@models/backend";
import { useModelsStore } from "@state/models";
import { useShallow } from "zustand/react/shallow";
import { MeshIcon, PathIcon, ColumnIcon, SectionIcon, ZoneIcon } from "./icons";
import { SidebarHeader } from "./SidebarHeader";
import { SidebarLayers } from "./SidebarLayers";
import { SidebarModelProperties } from "./SidebarModelProperties";
import { SidebarPathCollectionProperties } from "./SidebarPathCollectionProperties";
import { SidebarSectionProperties } from "./SidebarSectionProperties";
import { SidebarColumnProperties } from "./SidebarColumnProperties";
import { SidebarPropertiesHeader } from "./SidebarPropertiesHeader";

interface PropertiesSidebarProps extends StyleProps {
  project: ProjectData;
}

export function Sidebar({ project, ...props }: PropertiesSidebarProps) {
  const {
    border: { border: borderColor },
    icons: { secondary: iconSecondary },
  } = useSemanticTokens();

  const {
    models,
    pathCollections,
    sections,
    columns,
    selectedModel,
    selectedPathCollection,
    selectedReference,
    selectedSection,
    selectedColumn,
  } = useModelsStore(
    useShallow(({ models, pathCollections, sections, columns, selectedObjects }) => {
      const selectedModel = selectedObjects.find(({ type }) => type === "model") as {
        type: "model";
        id: string;
      } | null;
      const selectedPathCollection = selectedObjects.find(({ type }) => type === "path") as {
        type: "path";
        id: string;
        modelId: string;
      } | null;
      const selectedReference = selectedObjects.find(({ type }) => type === "reference") as {
        type: "reference";
        id: string;
        modelId: string;
      } | null;
      const selectedSection = selectedObjects.find(({ type }) => type === "section") as {
        type: "section";
        id: string;
        modelId: string;
      } | null;
      const selectedColumn = selectedObjects.find(({ type }) => type === "column") as {
        type: "column";
        id: string;
        modelId: string;
      } | null;

      return {
        models,
        pathCollections,
        sections,
        columns,
        selectedModel,
        selectedPathCollection,
        selectedReference,
        selectedSection,
        selectedColumn,
      };
    }),
  );
  const selectedModelObject = selectedModel ? models.find((model) => model.id === selectedModel.id) : null;
  const selectedPathCollectionObject = selectedPathCollection
    ? pathCollections[selectedPathCollection.modelId]?.collections[selectedPathCollection.id]
    : null;
  const selectedReferenceObject = selectedReference ? models.find((model) => model.id === selectedReference.id) : null;
  const selectedSectionObject = selectedSection
    ? sections[selectedSection.modelId].sectionCurves.find((section) => section.id === selectedSection.id)
    : null;
  const selectedSectionIndex = selectedSection
    ? sections[selectedSection.modelId].sectionCurves.findIndex((section) => section.id === selectedSection.id)
    : -1;

  const selectedColumnObject = selectedColumn
    ? columns[selectedColumn.modelId].columnCurves.find((column) => column.id === selectedColumn.id)
    : null;
  const selectedColumnIndex = selectedColumn
    ? columns[selectedColumn.modelId].columnCurves.findIndex((column) => column.id === selectedColumn.id)
    : -1;
  const hasNoSelection =
    !selectedModel && !selectedPathCollection && !selectedReference && !selectedSection && !selectedColumn;

  const defaultModel = models?.[0] || null;

  const selectionOptions = [
    {
      type: "model",
      isSelected: !!selectedModelObject,
      name: selectedModelObject?.name,
      icon: <MeshIcon color={iconSecondary} boxSize="1.5rem" strokeWidth="1.5px" />,
      hasProperties: true,
    },
    {
      type: "pathCollection",
      isSelected: !!selectedPathCollectionObject,
      name: selectedPathCollectionObject?.name ?? "Path Collection",
      icon:
        selectedPathCollectionObject?.usage === "zone" && selectedPathCollectionObject.isLoop ? (
          <ZoneIcon color={iconSecondary} boxSize="1.5rem" strokeWidth="1.5px" />
        ) : (
          <PathIcon color={iconSecondary} boxSize="1.5rem" strokeWidth="1.5px" />
        ),
      hasProperties: selectedPathCollectionObject?.usage === "zone" || selectedPathCollectionObject?.usage === "guide",
    },
    {
      type: "section",
      isSelected: !!selectedSectionObject,
      name: "Section " + (selectedSectionIndex + 1),
      icon: <SectionIcon color={iconSecondary} boxSize="1.5rem" strokeWidth="1.5px" />,
      hasProperties: true,
    },
    {
      type: "column",
      isSelected: !!selectedColumnObject,
      name: "Column " + (selectedColumnIndex + 1),
      icon: <ColumnIcon color={iconSecondary} boxSize="1.5rem" strokeWidth="1.5px" />,
      hasProperties: true,
    },
    {
      type: "reference",
      isSelected: !!selectedReferenceObject,
      name: selectedReferenceObject?.name ?? "Reference",
      icon: <MeshIcon color={iconSecondary} boxSize="1.5rem" strokeWidth="1.5px" />,
      hasProperties: true,
    },
  ];

  const headerProps = selectionOptions.find((option) => option.isSelected);

  return (
    <Container borderLeftWidth={1} borderLeftColor={borderColor} variant="classic" {...props} overflowY="auto">
      <SidebarHeader height="3rem" title={useModelName(project, selectedModelObject)} data-test-id="project-title" />
      <SidebarLayers
        project={project}
        w="full"
        overflowY="auto"
        minHeight="12rem"
        height={headerProps || hasNoSelection ? "calc(100% - 31rem)" : undefined}
      />
      {headerProps && !hasNoSelection ? (
        <>
          <SidebarPropertiesHeader
            name={headerProps.name ?? ""}
            icon={headerProps.icon}
            borderTopWidth={1}
            height="3rem"
          />
          <Box overflowY="auto" maxHeight="25rem">
            {selectedModelObject && (
              <SidebarModelProperties
                key={selectedModelObject.id}
                project={project}
                borderColor={borderColor}
                modelId={selectedModelObject.id}
              />
            )}
            {selectedPathCollectionObject && selectedPathCollection && (
              <SidebarPathCollectionProperties
                key={selectedPathCollectionObject.id}
                project={project}
                modelId={selectedPathCollection.modelId}
                pathCollectionId={selectedPathCollectionObject.id!}
              />
            )}
            {selectedSectionObject && selectedSection && (
              <SidebarSectionProperties
                key={selectedSection.id}
                project={project}
                modelId={selectedSection.modelId}
                sectionId={selectedSection.id}
              />
            )}
            {selectedColumnObject && selectedColumn && (
              <SidebarColumnProperties
                key={selectedColumn.id}
                project={project}
                modelId={selectedColumn.modelId}
                columnId={selectedColumn.id}
              />
            )}
            {selectedReferenceObject && selectedReference && (
              <SidebarModelReferenceProperties key={selectedReferenceObject.id} reference={selectedReferenceObject} />
            )}
          </Box>
        </>
      ) : (
        <>
          {defaultModel && (
            <>
              <SidebarPropertiesHeader
                name={defaultModel.name}
                icon={<MeshIcon color={iconSecondary} boxSize="1.5rem" strokeWidth="1.5px" />}
                borderTopWidth={1}
                height="3rem"
              />
              <Box overflowY="auto" maxHeight="25rem">
                <SidebarModelProperties
                  key={defaultModel.id}
                  project={project}
                  borderColor={borderColor}
                  modelId={defaultModel.id}
                />
              </Box>
            </>
          )}
        </>
      )}
    </Container>
  );
}
