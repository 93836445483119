import { Container, Flex } from "@chakra-ui/react";
import { resolveHexValue, useSemanticTokens } from "@design-system";
import { ProjectComments, Sidebar, UploadModelModal, useUploadModel, Workspace3D } from "@fragments";
import { TopToolbar } from "@fragments/project/toolbars/TopToolbar.tsx";
import {
  useImportExportProject,
  useLoadProject,
  useLoadProjectGlobals,
  useProjectComments,
  useUndoRedo,
  useUpdateProjectThumbnail,
} from "@hooks";
import { CursorProvider } from "@hooks/CursorProvider";
import { usePathCollectionLoader } from "@hooks/project/usePathCollectionLoader";
import { usePointerState } from "@hooks/project/usePointerState";
import { CursorConsumer } from "@hooks/useCustomCursor";
import { Project as ProjectData } from "@models/backend";
import { Canvas } from "@react-three/fiber";
import { useModelsStore } from "@state/models";
import { useProjectState } from "@state/project";
import { useCallback, useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { ViewportDropzone } from "./ViewportDropzone";
import DebugPanel from "../workspace-3d/DebugPanel";
import { VisualizationSettingsToolbar } from "@fragments/project/toolbars";

export function ProjectContainer({ project }: { project: ProjectData }) {
  const { fetchProjectGlobals } = useLoadProjectGlobals(project);

  useEffect(() => {
    fetchProjectGlobals().catch(console.warn);
  }, []);

  const { setUpListeners } = usePointerState();
  useEffect(setUpListeners);

  const {
    triggerUploadModelModal,
    uploadModalProps: { onModelUpload, ...uploadModalProps },
  } = useUploadModel({ project });

  useLoadProject({ project, triggerUploadModelModal });

  const { showMeasurements, setShowMeasurements, showDirectionality, setShowDirectionality, _3DToolbarSelection } =
    useProjectState();

  useHotkeys("ctrl+d", (e) => {
    e.preventDefault();
    setShowDirectionality(!showDirectionality);
  });

  useHotkeys("ctrl+m", (e) => {
    e.preventDefault();
    setShowMeasurements(!showMeasurements);
  });

  const containerBg = resolveHexValue(useSemanticTokens().surface.secondary);
  const { canvasRef, updateProjectThumbnail } = useUpdateProjectThumbnail(project);
  const { models } = useModelsStore(({ models }) => ({ models }));
  const { loadPathCollectionsFrom3dm } = usePathCollectionLoader();
  const handleReferenceModelLoad = useCallback(
    async (file: File) => {
      if (models.length > 0) {
        const parentModelId = models[0].id;
        await onModelUpload(file, parentModelId);
      }
    },
    [models, onModelUpload],
  );
  const getFileExtension = (filename: string): string => {
    return filename.split(".").pop()?.toLowerCase() || "";
  };

  const { handleImport, handleExport } = useImportExportProject(project);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length) {
        const file = acceptedFiles[0];
        const extension = getFileExtension(file.name);
        if (["obj", "glb", "gltf"].includes(extension)) {
          await handleReferenceModelLoad(file);
        } else if (extension === "3dm") {
          const model = models.find((model) => !model.parentId);

          if (model) {
            await loadPathCollectionsFrom3dm(project.id, model, file);
          }
        } else if (extension === "json") {
          await handleImport(file);
        }
      }
    },
    [handleReferenceModelLoad, loadPathCollectionsFrom3dm, models, project.id],
  );

  useHotkeys("ctrl+shift+s", async (e) => {
    e.preventDefault();
    await handleExport();
  });

  const {
    triggerAddCommentPopup,
    addCommentPopupProps,
    onAddCommentToThread,
    onDeleteThreadComment,
    onUpdateThreadComment,
  } = useProjectComments();

  useUndoRedo(project);

  return (
    <Flex direction="column">
      <TopToolbar borderBottomWidth={1} />
      <Flex direction="row" height="calc(100vh - 45px)">
        <Container bg={containerBg} flex={1} maxW="unset" width="calc(100% - 15rem)">
          <CursorProvider>
            <CursorConsumer>
              {({ cursor }) => {
                return (
                  <ViewportDropzone onDrop={onDrop}>
                    <Canvas
                      id="workspace3D"
                      style={{ cursor }}
                      ref={canvasRef}
                      onCreated={updateProjectThumbnail}
                      onClick={updateProjectThumbnail}
                      onKeyUp={updateProjectThumbnail}
                      frameloop="demand"
                    >
                      <Workspace3D triggerAddCommentPopup={triggerAddCommentPopup} />
                    </Canvas>
                    <VisualizationSettingsToolbar />
                    {_3DToolbarSelection === "comment" && (
                      <ProjectComments
                        createThreadPopupProps={addCommentPopupProps}
                        onAddCommentToThread={onAddCommentToThread}
                        onDeleteThreadComment={onDeleteThreadComment}
                        onUpdateThreadComment={onUpdateThreadComment}
                      />
                    )}
                  </ViewportDropzone>
                );
              }}
            </CursorConsumer>
          </CursorProvider>
          <UploadModelModal onModelUpload={onModelUpload} {...uploadModalProps} />
          <DebugPanel />
        </Container>
        <Sidebar project={project} height="100%" width="15rem" />
      </Flex>
    </Flex>
  );
}
