import { useAuth } from "@auth";
import { useCurrentProject } from "@fragments/project/container/useCurrentProject";
import { useBackendModel } from "@hooks/backend-api/useBackendModel";
import { ModelAttributes, Project, Unit, Units } from "@models/backend";
import { Model } from "@models/project";
import { ModelsStore, useModelsStore } from "@state/models";
import { recordToArray, scaleMesh } from "@utils";
import { useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useShallow } from "zustand/react/shallow";

const isValidCategory = (key: string): key is keyof ModelAttributes => {
  return [
    "knitStructure",
    "meshSettings",
    "normals",
    "stitchDensity",
    "sections",
    "columns",
    "bitmapSettings",
    "postprocessorDocument",
  ].includes(key);
};

export const useModelUpdate = ({ modelId }: { projectId?: Project["id"]; modelId: Model["id"] }) => {
  const {
    project: { id: projectId },
  } = useCurrentProject();
  const { headers } = useAuth();
  const { columns, commentThreads, pathCollections, sections, updateModel } = useModelsStore(
    ({ columns, commentThreads, pathCollections, sections, updateModel }) => ({
      columns,
      commentThreads,
      pathCollections,
      sections,
      updateModel,
    }),
  );
  const model: Model | undefined = useModelsStore(
    useShallow((state: ModelsStore) => state.models.find(({ id }) => id == modelId)),
  )!;

  const { updateBackendModel, updateBackendModelLayers } = useBackendModel();

  const updateBackendAttributes = useCallback(
    (attributes: ModelAttributes) => updateBackendModel(projectId, modelId, { attributes }),
    [headers, projectId, modelId],
  );

  const debouncedUpdateBackendAttributes = useDebouncedCallback(updateBackendAttributes, 1000);

  const changeAttributes = useCallback(
    async (changedAttributes: Partial<ModelAttributes>) => {
      const baseAttributes: ModelAttributes = {
        ...(model.attributes ?? {}),
      };

      const newAttributes = Object.entries(changedAttributes).reduce((acc, [key, value]) => {
        if (isValidCategory(key)) {
          return {
            ...acc,
            [key]: value
              ? {
                  ...(acc[key] ?? {}),
                  ...value,
                }
              : null,
          };
        }
        return acc;
      }, baseAttributes);

      updateModel(modelId, { attributes: newAttributes });
      await debouncedUpdateBackendAttributes(newAttributes);
    },
    [model.attributes, modelId, debouncedUpdateBackendAttributes, updateModel],
  );

  const changeModelUnit = useCallback(
    async (model: Model, unit: Unit) => {
      const scale = Units[unit];

      const result = await scaleMesh(model, {
        scale,
        commentThreads: recordToArray(commentThreads),
        pathCollections: recordToArray(pathCollections[model.id]?.collections ?? {}),
        columnCurves: columns[model.id]?.columnCurves ?? [],
        sectionCurves: sections[model.id]?.sectionCurves ?? [],
      });

      const [, layers] = await Promise.all([
        updateBackendModel(projectId, model.id, { unit }),
        updateBackendModelLayers(projectId, model, result),
      ]);

      updateModel(model.id, { scale, unit, ...layers });
    },
    [headers, projectId, model, pathCollections, columns, sections, commentThreads, updateModel],
  );

  const changeUnit = useCallback(
    async (unit: Unit) => {
      if (!model) {
        return;
      }

      await Promise.all([
        changeModelUnit(model, unit),
        ...(model.references?.map((reference) => changeModelUnit(reference, unit)) ?? []),
      ]);
    },
    [model, changeModelUnit],
  );

  return { changeAttributes, changeUnit, model };
};
