import { Organization } from "@auth/AuthContext.ts";
import { Box, VStack } from "@chakra-ui/react";
import { ErrorScreen, Loading, YarnsView } from "@fragments";
import { useYarnLibrary } from "@hooks";

export function YarnsContainer({ organization }: { organization: Organization }) {
  const { yarns, isLoading, error, createYarn, updateYarn, deleteYarn } = useYarnLibrary(organization.id);

  if (isLoading) {
    return <Loading height="full" />;
  }

  if (error) {
    return <ErrorScreen showHeader={false} error={error} height="full" width="full" />;
  }

  return (
    <VStack gap={0} width="full" height="full" align="stretch">
      <Box minW="full" flex={1}>
        <YarnsView yarns={yarns} actions={{ createYarn, updateYarn, deleteYarn }} />
      </Box>
    </VStack>
  );
}
