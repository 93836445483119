import { Vector3, BooleanOperation } from "@variant-tech/pattern-derivation";

export type Point = [number, number, number];
export type ControlVector = [Point, Point];

export interface Path {
  id: string;
  type: "Path" | "BezierPath";
  points: Point[];
  controlVectors: ControlVector[];
  isLoop?: boolean;
}

export interface YarnToken {
  id: string;
}

export interface KnitStructure {
  id: string;
  isDefault: boolean | null;
  name: string;
  color: string | null;
  courseDensity: number | null;
  waleDensity: number | null;
  mode: "normal" | "Hue" | "Waste" | "Void" | null;
  yarnTokenCount: number;
}

export type ProjectKnitStructure = KnitStructure & {
  yarnTokenIds: string[];
};

export type PartialKnitStructure = Partial<KnitStructure>;

export interface ColorEntry {
  color: string;
  code: number;
  description?: string;
}

export interface Machine {
  id: string;
  name: string;
  colorEntries: ColorEntry[];
}

export type MachineColorEntry = ColorEntry & { machineId: string };

export interface PostprocessorDocumentEntry {
  knitStructure: KnitStructure;
  machineColorEntry: MachineColorEntry;
}

export interface PostprocessorDocument {
  id: string;
  organizationId: string;
  machineId: string;
  system: boolean;
  name: string;
  entries: PostprocessorDocumentEntry[];
}

export interface PathCollectionAttributes {
  knitStructure?: ProjectKnitStructure | null;
  stitchDensity?: {
    course?: number | null;
    wale?: number | null;
  } | null;
  transition?: {
    boundary?: number;
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
  general?: {
    excludeGoring?: boolean;
    finishedEdges?: boolean;
  };
}

export interface PathCollection {
  id: string;
  name: string;
  modelId: string;
  paths: Path[];
  usage?: "guide" | "zone" | "goring" | null;
  attributes?: PathCollectionAttributes | null;
  operation?: BooleanOperation | null;
}

export interface SectionAnchorAttributes {
  source?: "user" | "auto";
  excludeGoring?: boolean;
}

export interface SectionAnchor {
  id: string;
  name: string;
  point: Point;
  attributes: SectionAnchorAttributes;
}

export interface ColumnAnchorAttributes {
  source?: "user" | "auto";
}

export interface ColumnAnchor {
  id: string;
  name: string;
  point: Point;
  attributes: ColumnAnchorAttributes;
}

export type Unit = "mm" | "cm" | "m" | "in";

export const Units: { [key in Unit]: number } = {
  mm: 0.1,
  cm: 1,
  in: 2.54,
  m: 100,
};

export interface ModelAttributes {
  knitStructure?: ProjectKnitStructure;
  postprocessorDocument?: { postprocessorDocumentId?: string | null };
  meshSettings?: {
    color?: string;
    medialAxis?: boolean;
  };
  normals?: {
    flipNormals?: boolean;
  };
  stitchDensity?: {
    course?: number | null;
    wale?: number | null;
  } | null;
  sections?: {
    count?: number;
  };
  columns?: {
    count?: number;
  };
  bitmapSettings?: {
    flip?: boolean;
    includeCalibrationGrid?: boolean;
  };
}

export interface Model {
  id: string;
  name: string;
  filename: string;
  unit?: Unit;
  url: string;
  decimatedUrl?: string;
  zoneOrder?: Array<[string, number]>;
  pathCollections: PathCollection[];
  sectionAnchors: SectionAnchor[];
  columnAnchors: ColumnAnchor[];
  attributes?: ModelAttributes;
  parentId?: string;
}

export interface Tag {
  id: string;
  label: string;
  color: string;
}

export interface Member {
  id: string;
  name: string;
  avatar: string;
}

export interface Project {
  id: string;
  organizationId: string;
  name: string;
  thumbnail?: string;
  description?: string;
  models: Model[];
  tags: Tag[];
  members: Member[];
  createdAt: Date;
  updatedAt: Date;
  parentFolderId?: string;
  fileShareUrl?: string;
  colorways: Colorway[];
  yarnTokens: YarnToken[];
}

export type Process = {
  id: string;
  projectId: string;
  status: "created" | "ready" | "processing" | "failed" | "done";
  input: { glbUrl: string };
  info?: { message: string };
  output?: { bmpUrl: string; neighborMapUrl?: string; dataMapUrl?: string; objUrl?: string };
};

export interface ProjectComment {
  id: string;
  content: string;
  threadId: string;
  author: Member;
  createdAt: Date;
  updatedAt: Date;
}

export interface CommentThread {
  id: string;
  projectId: string;
  state: ThreadState;
  position: Vector3;
  layerId?: string;
  createdAt: Date;
  updatedAt: Date;
  author: Member;
  comments: ProjectComment[];
}

export type ThreadState = "open" | "resolved";

export type Yarn = {
  id: string;
  nameColor: string;
  material: string;
  manufacturer: string;
  count: string;
  countType: string;
  thickness?: number;
  fiberSize?: number;
  twistPerCm?: number;
  colorValue?: string;
  createdAt: Date;
  updatedAt: Date;
  member?: Member;
};

export interface Colorway {
  id: string;
  name: string;
  yarnTokens: YarnTokenValue[];
}

export interface YarnToken {
  id: string;
  name: string;
}

export interface YarnTokenValue {
  id: string;
  yarnId: string;
  numberOfEnds: number;
}
