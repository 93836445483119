import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { ProjectState } from "./types";

export const useProjectState = create(
  subscribeWithSelector<ProjectState>((set) => ({
    _3DToolbarSelection: "select",
    renderModeSelection: "design",
    showMeasurements: false,
    showDirectionality: false,
    showDebugPanel: false,

    set3DToolbarSelection: (_3DToolbarSelection) => set(() => ({ _3DToolbarSelection })),
    setRenderModeSelection: (renderModeSelection) => set(() => ({ renderModeSelection })),
    setShowMeasurements: (showMeasurements: boolean) => set(() => ({ showMeasurements })),
    setShowDirectionality: (showDirectionality: boolean) => set(() => ({ showDirectionality })),
    setShowDebugPanel: (showDebugPanel: boolean) => set(() => ({ showDebugPanel })),

    reset: () => {
      set(() => {
        return {
          _3DToolbarSelection: "none",
          renderModeSelection: "design",
          showMeasurements: false,
          showDirectionality: false,
          showDebugPanel: false,
        };
      });
    },
  })),
);

export * from "./types";
