import { useYarnLibrary } from "@hooks";
import { ReactNode } from "react";

type UseYarnLibrary = ReturnType<typeof useYarnLibrary>;

export interface YarnLibraryProps {
  yarns: UseYarnLibrary["yarns"];
  actions: {
    createYarn: UseYarnLibrary["createYarn"];
    updateYarn: UseYarnLibrary["updateYarn"];
    deleteYarn: UseYarnLibrary["deleteYarn"];
  };
}

export const count_placeholders = ["Denier", "Tex", "Metric"];

export const column_config = [
  { key: "image", flex: 1, minWidth: 80 },
  { key: "nameColor", flex: 1.5, minWidth: 120 },
  { key: "material", flex: 2.5, minWidth: 200 },
  { key: "manufacturer", flex: 2, minWidth: 125 },
  { key: "count", flex: 1.5, minWidth: 100 },
  { key: "countType", flex: 1.5, minWidth: 100 },
  { key: "thickness", flex: 1.5, minWidth: 100 },
  { key: "fiberSize", flex: 1.5, minWidth: 100 },
  { key: "twistPerCm", flex: 1.5, minWidth: 96, maxWidth: 96 },
];

export interface ColorPickerPosition {
  top: number;
  left: number;
}

export interface RowRendererProps {
  row: { index: number };
  children: ReactNode;
}

export interface CellUpdateHandler {
  (value: string): void;
}
