import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import {
  useBackendColumnAnchor,
  useBackendCommentThread,
  useBackendPathCollection,
  useBackendSectionAnchor,
} from "@hooks";
import { useBackendModel } from "@hooks/backend-api/useBackendModel";
import { analytics } from "@integrations/analytics";
import { Project } from "@models/backend";
import { Model } from "@models/project";
import { CommentThreadState, PathCollections, TemporalModelsStore } from "@state";
import { Columns, Sections } from "@state/models/types";
import { recordToArray } from "@utils";
import { useCallback } from "react";

export function useProjectUpdate(project: Project) {
  const { headers } = useAuth();
  const { updateBackendModel } = useBackendModel();
  const { updateBackendColumnAnchors } = useBackendColumnAnchor();
  const { updateBackendCommentThreads } = useBackendCommentThread();
  const { replaceBackendPathCollections } = useBackendPathCollection();
  const { updateBackendSectionAnchors } = useBackendSectionAnchor();

  const trackDurationAsync = useCallback(
    async (eventName: string, f: () => Promise<unknown>) =>
      analytics.trackDurationAsync(eventName, f, { projectId: project.id }),
    [project.id],
  );

  const updateModels = useCallback(
    async (project: Project, models: Model[] | undefined) =>
      Promise.all(models?.map(async (model) => updateBackendModel(project.id, model.id, model)) ?? []),
    [project, headers],
  );

  const updatePathCollections = useCallback(
    async (project: Project, pathCollections: PathCollections | undefined) =>
      Promise.all(
        Object.entries(pathCollections ?? {}).map(([modelId, { collections }]) =>
          replaceBackendPathCollections(project.id, modelId, recordToArray(collections)),
        ),
      ),
    [replaceBackendPathCollections],
  );

  const updateColumnAnchors = useCallback(
    async (project: Project, columnAnchors: Columns | undefined) =>
      Promise.all(
        Object.entries(columnAnchors ?? {}).map(([modelId, { columnCurves }]) =>
          updateBackendColumnAnchors(project.id, modelId, columnCurves),
        ),
      ),
    [updateBackendColumnAnchors],
  );

  const updateCommentThreads = useCallback(
    async (project: Project, commentThreads: Record<string, CommentThreadState> | undefined) =>
      updateBackendCommentThreads(project.id, recordToArray(commentThreads ?? {})),
    [updateBackendCommentThreads],
  );

  const updateSectionAnchors = useCallback(
    async (project: Project, sectionAnchors: Sections | undefined) =>
      Promise.all(
        Object.entries(sectionAnchors ?? {}).map(([modelId, { sectionCurves }]) =>
          updateBackendSectionAnchors(project.id, modelId, sectionCurves),
        ),
      ),
    [updateBackendSectionAnchors],
  );

  const updateProject = useCallback(
    async ({ models, sections, columns, pathCollections, commentThreads }: Partial<TemporalModelsStore>) => {
      await Promise.all([
        trackDurationAsync("UPDATE_COLUMN_ANCHORS", () => updateColumnAnchors(project, columns)),
        trackDurationAsync("UPDATE_COMMENT_THREADS", () => updateCommentThreads(project, commentThreads)),
        trackDurationAsync("UPDATE_MODELS", () => updateModels(project, models)),
        trackDurationAsync("UPDATE_PATH_COLLECTIONS", () => updatePathCollections(project, pathCollections)),
        trackDurationAsync("UPDATE_SECTION_ANCHORS", () => updateSectionAnchors(project, sections)),
      ]);
    },
    [updateModels, updatePathCollections],
  );

  const renameProject = useCallback(
    async (newName: string) => {
      try {
        return await BackendApi.patchProject({
          headers,
          params: { projectId: project.id },
          body: { name: newName },
        });
      } catch (error) {
        console.warn(`Failed to rename project ${project.id} to ${newName}`, error);
        throw error;
      }
    },
    [project.id, headers],
  );

  const addFileShareURl = useCallback(
    async (fileShareUrl: string) => {
      try {
        return await BackendApi.patchProject({
          headers,
          params: { projectId: project.id },
          body: { fileShareUrl },
        });
      } catch (error) {
        console.warn(`Failed to link file sharing url ${fileShareUrl} to ${project.id}`, error);
        throw error;
      }
    },
    [project.id, headers],
  );

  return {
    addFileShareURl,
    renameProject,
    updateProject,
  };
}
