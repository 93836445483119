import { Flex, Heading } from "@chakra-ui/react";
import { useTranslation } from "@hooks";
import { useSemanticTokens, spacing } from "@design-system";

export function YarnHeader() {
  const { t } = useTranslation("yarns.header");
  const semanticTokens = useSemanticTokens();

  return (
    <Flex
      minWidth="full"
      alignItems="center"
      justifyContent="space-between"
      gap="2"
      minH={spacing.space[3200]}
      px={spacing.space[800]}
      borderBottomWidth="1px"
      borderBottomColor={semanticTokens.border.classic.primary}
      bg={semanticTokens.surface.classic.primary}
    >
      <Heading as="h1" size="h1" color={semanticTokens.text.classic.primary}>
        {t("label")}
      </Heading>
    </Flex>
  );
}
