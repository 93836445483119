import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { useToast } from "@design-system";
import { useTranslation } from "@hooks";
import { SectionCurve } from "@models/project";
import { useCallback } from "react";

function fromModel(curve: Omit<SectionCurve, "id"> & { id?: SectionCurve["id"] }) {
  return {
    id: curve.id,
    name: curve.name,
    point: curve.point,
    attributes: curve.attributes,
  };
}

export function useBackendSectionAnchor() {
  const { headers } = useAuth();
  const { t } = useTranslation("hooks.sectionAnchors");
  const toast = useToast();

  const createBackendSectionAnchor = useCallback(
    async (projectId: string, modelId: string, sectionAnchor: Omit<SectionCurve, "id">) => {
      const request = {
        headers,
        params: {
          projectId,
          modelId,
        },
        body: fromModel(sectionAnchor),
      };

      try {
        return await BackendApi.createSectionAnchor(request);
      } catch (error) {
        console.log({ message: "Failed to upload section anchors", error, project: request.body });
        toast({
          title: t("create.toast.status.error.title"),
          children: t("create.toast.status.error.description"),
          variant: "error",
        });
        throw error;
      }
    },
    [headers, t, toast],
  );

  const updateBackendSectionAnchors = useCallback(
    async (
      projectId: string,
      modelId: string,
      sectionAnchors: (Omit<SectionCurve, "id"> & { id?: SectionCurve["id"] })[],
    ) => {
      const anchors = sectionAnchors.map((sectionAnchor) => fromModel(sectionAnchor));

      const request = {
        headers,
        params: {
          projectId,
          modelId,
        },
        body: {
          anchors,
        },
      };

      try {
        return await BackendApi.updateSectionAnchors(request);
      } catch (error) {
        console.log({ message: "Failed to upload section anchor", error, project: request.body });
        toast({
          title: t("create.toast.status.error.title"),
          children: t("create.toast.status.error.description"),
          variant: "error",
        });
        throw error;
      }
    },
    [headers, t, toast],
  );

  const updateBackendSectionAnchor = useCallback(
    async (projectId: string, modelId: string, sectionAnchor: SectionCurve) => {
      if (sectionAnchor.id === undefined) {
        return null;
      }
      const request = {
        headers,
        params: {
          projectId: projectId,
          modelId: modelId,
          id: sectionAnchor.id,
        },
        body: fromModel(sectionAnchor),
      };

      try {
        await BackendApi.updateSectionAnchor(request);
      } catch (error) {
        console.log({ message: "Failed to update section anchor", error, project: request.body });
        toast({
          title: t("update.toast.status.error.title"),
          children: t("update.toast.status.error.description"),
          variant: "error",
        });
      }
    },
    [headers, t, toast],
  );

  const deleteBackendSectionAnchor = useCallback(
    async (projectId: string, modelId: string, sectionAnchorId: string) => {
      const request = {
        headers,
        params: {
          projectId: projectId,
          modelId: modelId,
          id: sectionAnchorId,
        },
      };

      try {
        await BackendApi.deleteSectionAnchor(request);
      } catch (error) {
        console.log({ message: "Failed to delete section anchor", error, request });
        toast({
          title: t("delete.toast.status.error.title"),
          children: t("delete.toast.status.error.description"),
          variant: "error",
        });
      }
    },
    [headers, t, toast],
  );

  return {
    createBackendSectionAnchor,
    deleteBackendSectionAnchor,
    updateBackendSectionAnchor,
    updateBackendSectionAnchors,
  };
}
