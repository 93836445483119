import { useBackendPathCollection, useProgressToast, useTranslation } from "@hooks";
import { Model, PathCollection } from "@models/project";
import { useModelsStore } from "@state/models";
import { getPathCollectionsFrom3dm, load3dmFile } from "@utils";
import { File3dm } from "rhino3dm";

export function usePathCollectionLoader() {
  const { pushPathCollections, pathCollections } = useModelsStore(({ pushPathCollections, pathCollections }) => ({
    pushPathCollections,
    pathCollections,
  }));
  const { createBackendPathCollection } = useBackendPathCollection();
  const toast = useProgressToast();
  const { t } = useTranslation("project.sidebar.layers");
  const { t: tp } = useTranslation("hooks.rhino3dm.load.progress");

  async function addPathCollectionsFrom3dm(projectId: string, model: Model, file3dm: File3dm) {
    const onlyPaths = getPathCollectionsFrom3dm(file3dm);

    const startIdx = Object.keys(pathCollections[model.id]?.collections ?? {}).length;
    const collections = await Promise.all(
      onlyPaths.map(async (path, idx): Promise<PathCollection> => {
        const name = (path.isLoop ? t("types.closed") : t("types.curve")) + ` ${startIdx + idx}`;
        const collection: Omit<PathCollection, "id"> = {
          ...path,
          name,
          attributes: {},
          usage: null,
          operation: null,
        };
        const response = await createBackendPathCollection(projectId, model.id, collection);

        return {
          ...collection,
          id: response.id,
        };
      }),
    );

    pushPathCollections(model.id, collections);
  }

  async function loadPathCollectionsFrom3dm(projectId: string, model: Model, file: File) {
    const { update } = toast(tp, { title: file.name, status: "in-progress" });
    try {
      if (!window.rhino) {
        window.rhino = await window.rhino3dm();
      }
      const file3dm = await load3dmFile(file);
      update({
        message: tp(`status.in-progress.path-collections`),
        status: "in-progress",
      });
      await addPathCollectionsFrom3dm(projectId, model, file3dm);
      update({ status: "success" });
    } catch (error) {
      console.log(error);
      update({ status: "error", message: tp("status.error") });
    }
  }

  return { loadPathCollectionsFrom3dm };
}
