import { useRef, useState, useEffect, ReactNode } from "react";
import { Box, Grid, Text, IconButton } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  Column,
  flexRender,
  TableOptions,
  Row,
  RowData,
} from "@tanstack/react-table";
import { AddIcon } from "@chakra-ui/icons";
import { spacing, useSemanticTokens } from "@design-system";

declare module "@tanstack/react-table" {
  interface ColumnMeta<TData extends RowData, TValue> {
    flex?: number;
    __phantom__?: [TData, TValue];
  }
}

export type ResizableDataGridProps<Data> = {
  columns: ColumnDef<Data>[];
  data: Data[];
  fullTextSearch?: string;
  sorting?: SortingState;
  headerComponent?: (table: ReturnType<typeof useReactTable<Data>>) => ReactNode;
  footerComponent?: (table: ReturnType<typeof useReactTable<Data>>) => ReactNode;
  onAddRow?: () => void;
  onAddColumn?: () => void;
  title?: string;
  tableOptions?: Partial<TableOptions<Data>>;
  rowRenderer?: (props: { row: Row<Data>; children: ReactNode }) => ReactNode;
  hideScrollbars?: boolean;
} & StyleProps;

export function ResizableDataGrid<Data extends object>({
  columns,
  data,
  fullTextSearch: globalFilter,
  sorting,
  headerComponent,
  footerComponent,
  onAddRow,
  onAddColumn,
  title,
  tableOptions = {},
  rowRenderer,
  hideScrollbars,
}: ResizableDataGridProps<Data>) {
  const semanticTokens = useSemanticTokens();
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const resizeDebounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const previousWidthRef = useRef(0);

  const calculateColumnSizes = (availableWidth: number, tableColumns: Column<Data, unknown>[]) => {
    const totalFlex = tableColumns.reduce((sum, column) => sum + (column.columnDef.meta?.flex || 1), 0);

    const sizes = tableColumns.map((column) => {
      const flex = column.columnDef.meta?.flex || 1;
      const minSize = column.columnDef.minSize || 120;

      const flexSize = (flex / totalFlex) * (availableWidth - 2);

      return Math.max(minSize, flexSize);
    });

    const currentTotal = sizes.reduce((sum, size) => sum + size, 0);

    if (Math.abs(currentTotal - availableWidth) > 1) {
      const diff = availableWidth - currentTotal;
      const adjustments = sizes.map((size) => (size / currentTotal) * diff);

      return sizes.map((size, i) => Math.floor(size + adjustments[i]));
    }

    return sizes.map((size) => Math.floor(size));
  };

  useEffect(() => {
    if (containerRef.current) {
      const updateWidth = () => {
        const newWidth = containerRef.current?.offsetWidth || 0;
        if (Math.abs(newWidth - previousWidthRef.current) > 2) {
          previousWidthRef.current = newWidth;
          setContainerWidth(newWidth);
        }
      };

      updateWidth();

      const resizeObserver = new ResizeObserver(() => {
        if (resizeDebounceTimeout.current) {
          clearTimeout(resizeDebounceTimeout.current);
        }

        resizeDebounceTimeout.current = setTimeout(() => {
          updateWidth();
        }, 20);
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        if (resizeDebounceTimeout.current) {
          clearTimeout(resizeDebounceTimeout.current);
        }
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
        resizeObserver.disconnect();
      };
    }
  }, []);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      globalFilter,
      sorting,
    },
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    ...tableOptions,
  });

  useEffect(() => {
    if (containerWidth > 0) {
      const allColumns = table.getAllColumns();
      const sizes = calculateColumnSizes(containerWidth, allColumns);

      const columnSizingState = allColumns.reduce(
        (acc, column, index) => {
          acc[column.id] = sizes[index];
          return acc;
        },
        {} as Record<string, number>,
      );

      table.setColumnSizing(columnSizingState);
    }
  }, [containerWidth, data.length]);

  useEffect(() => {
    if (containerWidth > 0) {
      const isResizing = table.getState().columnSizingInfo.isResizingColumn;

      if (!isResizing && table.getState().columnSizingInfo.startSize !== null) {
        const timer = setTimeout(() => {
          const allColumns = table.getAllColumns();
          const sizes = calculateColumnSizes(containerWidth, allColumns);
          const columnSizingState = allColumns.reduce(
            (acc, column, index) => {
              acc[column.id] = sizes[index];
              return acc;
            },
            {} as Record<string, number>,
          );

          table.setColumnSizing(columnSizingState);
        }, 100);

        return () => clearTimeout(timer);
      }
    }
  }, [table.getState().columnSizingInfo, containerWidth]);

  const addButtonSize = spacing.space[800];

  return (
    <Box
      width="100%"
      height="100%"
      bg={semanticTokens.surface.classic.primary}
      color={semanticTokens.text.classic.primary}
      ref={containerRef}
      position="relative"
    >
      {title && (
        <Text
          fontSize="2xl"
          fontWeight="bold"
          p={spacing.space[400]}
          pl={spacing.space[600]}
          color={semanticTokens.text.classic.primary}
        >
          {title}
        </Text>
      )}

      <Box width="100%" height={title ? "calc(100% - 60px)" : "100%"} position="relative">
        <Box
          position="absolute"
          top="0"
          left="0"
          right={onAddColumn ? addButtonSize : "0"}
          bottom={onAddRow ? addButtonSize : "0"}
          overflow="hidden"
        >
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            overflowY="auto"
            overflowX="auto"
            sx={
              hideScrollbars
                ? {
                    "::-webkit-scrollbar": { display: "none" },
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }
                : {}
            }
          >
            <Box
              position="sticky"
              top={0}
              zIndex={2}
              bg={semanticTokens.surface.classic.primary}
              width="max-content"
              minWidth="100%"
            >
              {headerComponent ? (
                headerComponent(table)
              ) : (
                <Grid
                  gridTemplateColumns={table
                    .getAllColumns()
                    .map((column) => `${column.getSize()}px`)
                    .join(" ")}
                  bg={semanticTokens.surface.classic.primary}
                  borderBottom={`1px solid ${semanticTokens.border.classic.primary}`}
                  borderTop={`1px solid ${semanticTokens.border.classic.primary}`}
                  borderLeft={`1px solid ${semanticTokens.border.classic.primary}`}
                  borderRight={`1px solid ${semanticTokens.border.classic.primary}`}
                  minWidth="max-content"
                  width="100%"
                >
                  {table.getFlatHeaders().map((header, headerIndex) => {
                    const allHeaders = table.getFlatHeaders();
                    const isLastHeader = headerIndex === allHeaders.length - 1;

                    return (
                      <Box
                        key={header.id}
                        px={spacing.space[100]}
                        py={spacing.space[200]}
                        borderRight={isLastHeader ? "none" : `1px solid ${semanticTokens.border.classic.primary}`}
                        fontWeight="bold"
                        fontSize="sm"
                        height="32px"
                        position="relative"
                        userSelect="none"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        textAlign="left"
                        color={semanticTokens.text.classic.primary}
                      >
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}

                        {header.column.getCanResize() && (
                          <Box
                            position="absolute"
                            right="-1px"
                            top={0}
                            height="100%"
                            width={spacing.space[100]}
                            bg={header.column.getIsResizing() ? semanticTokens.icons.accent.primary : "transparent"}
                            cursor="col-resize"
                            userSelect="none"
                            sx={{ touchAction: "none" }}
                            onMouseDown={header.getResizeHandler()}
                            onTouchStart={header.getResizeHandler()}
                            _hover={{ bg: semanticTokens.icons.accent.primary }}
                          />
                        )}
                      </Box>
                    );
                  })}
                </Grid>
              )}
            </Box>

            {table.getRowModel().rows.length > 0 && (
              <Box width="max-content" minWidth="100%">
                {table.getRowModel().rows.map((row) => {
                  const rowContent = (
                    <Grid
                      key={row.id}
                      gridTemplateColumns={table
                        .getAllColumns()
                        .map((column) => `${column.getSize()}px`)
                        .join(" ")}
                      borderBottom={`1px solid ${semanticTokens.border.classic.primary}`}
                      borderLeft={`1px solid ${semanticTokens.border.classic.primary}`}
                      borderRight={`1px solid ${semanticTokens.border.classic.primary}`}
                      _hover={{ bg: semanticTokens.surface.classic.secondary }}
                      minWidth="max-content"
                      width="100%"
                    >
                      {row.getVisibleCells().map((cell, cellIndex) => {
                        const visibleCells = row.getVisibleCells();
                        const isLastCell = cellIndex === visibleCells.length - 1;

                        return (
                          <Box
                            key={cell.id}
                            px={spacing.space[100]}
                            py={spacing.space[200]}
                            borderRight={isLastCell ? "none" : `1px solid ${semanticTokens.border.classic.primary}`}
                            fontSize="sm"
                            userSelect="none"
                            height={spacing.space[1000]}
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            color={semanticTokens.text.classic.primary}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </Box>
                        );
                      })}
                    </Grid>
                  );

                  return rowRenderer ? rowRenderer({ row, children: rowContent }) : rowContent;
                })}
              </Box>
            )}
          </Box>

          {footerComponent && (
            <Box
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              bg={semanticTokens.surface.classic.primary}
              zIndex={1}
            >
              {footerComponent(table)}
            </Box>
          )}
        </Box>

        {onAddColumn && (
          <Box
            position="absolute"
            top="0"
            right="0"
            height={addButtonSize}
            width={addButtonSize}
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor={semanticTokens.surface.classic.primary}
          >
            <IconButton
              aria-label="Add column"
              icon={<AddIcon boxSize="12px" />}
              size="sm"
              width="20px"
              height="20px"
              color={semanticTokens.icons.primary}
              bg="transparent"
              variant="ghost"
              onClick={onAddColumn}
              _hover={{ bg: semanticTokens.surface.classic.tertiary }}
            />
          </Box>
        )}

        {onAddRow && (
          <Box
            position="absolute"
            bottom="0"
            left="0"
            height={addButtonSize}
            width={addButtonSize}
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor={semanticTokens.surface.classic.primary}
          >
            <IconButton
              aria-label="Add row"
              icon={<AddIcon boxSize="12px" />}
              size="sm"
              width="20px"
              height="20px"
              color={semanticTokens.icons.primary}
              bg="transparent"
              variant="ghost"
              onClick={onAddRow}
              _hover={{ bg: semanticTokens.surface.classic.tertiary }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
