import { utility } from "@design-system";
import { Model, PathCollectionInput } from "@models/project";
import { Curve } from "@variant-tech/pattern-derivation";
import { Mesh, Vector3 } from "three";
import { DirectionArrow } from "./DirectionArrow";

export type DirectionalityHelperProps = {
  mesh: Mesh;
  model: Model;
  pathCollection: PathCollectionInput & { id?: string };
  curve: Curve;
  isSelected: boolean;
  isHovered: boolean;
};

export function DirectionalityHelper({
  mesh,
  model,
  pathCollection,
  curve,
  isHovered,
  isSelected,
}: DirectionalityHelperProps) {
  const { samples } = curve;
  const color = isSelected || isHovered ? utility.blue : utility.green;
  const vectors = samples.map((v) => new Vector3(...v));

  return vectors.map((vector, index) => {
    if (index === vectors.length - 1) {
      return null;
    }

    const dir = vectors[index + 1].clone().sub(vector.clone()).normalize();
    const origin = vector.clone();
    const length = vector.distanceTo(vectors[index + 1]);
    const coneLength = length < 0.5 ? length : 0.5;
    const coneRadius = 0.2;

    return (
      <DirectionArrow
        key={index}
        mesh={mesh}
        modelId={model.id}
        collectionId={pathCollection.id}
        dir={dir}
        origin={origin}
        length={length}
        coneLength={coneLength}
        coneRadius={coneRadius}
        color={color}
      />
    );
  });
}
