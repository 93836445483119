import { HStack, useOutsideClick } from "@chakra-ui/react";
import { spacing, useSemanticTokens } from "@design-system";
import { AddComment, CommentPopup, Thread } from "@fragments";
import { AddCommentPlacementIcon } from "@fragments/project/comments/icons";
import { CreateThreadPopupProps } from "@hooks";
import { useModelsStore } from "@state";
import { recordToArray } from "@utils";
import { useRef } from "react";

export function ProjectComments({
  createThreadPopupProps: { position, isOpen, onAddComment, onClose },
  onAddCommentToThread,
  onDeleteThreadComment,
  onUpdateThreadComment,
}: {
  createThreadPopupProps: CreateThreadPopupProps;
  onAddCommentToThread: (threadId: string, content: string) => void;
  onDeleteThreadComment: (threadId: string, commentId: string) => void;
  onUpdateThreadComment: (threadId: string, commentId: string, update: { content: string }) => void;
}) {
  const popupRef = useRef<HTMLDivElement>(null);
  useOutsideClick({ ref: popupRef, enabled: isOpen, handler: onClose });
  const semanticTokens = useSemanticTokens();
  const { commentThreads } = useModelsStore();

  return (
    <>
      {position && (
        <CommentPopup
          position={{ x: position.x, y: position.y - 28 }}
          display={isOpen ? "block" : "none"}
          ref={popupRef}
          w="16.25rem"
        >
          {isOpen && (
            <HStack alignItems="baseline" gap={spacing.space["200"]}>
              <AddCommentPlacementIcon fill={semanticTokens.surface.invert.accent.primary} />
              <AddComment onAddComment={onAddComment} />
            </HStack>
          )}
        </CommentPopup>
      )}
      {recordToArray(commentThreads)
        .map((state) => state.data)
        .map((thread) => (
          <Thread
            thread={thread}
            key={thread.id}
            onAddComment={(content) => onAddCommentToThread(thread.id, content)}
            onDeleteComment={(commentId) => onDeleteThreadComment(thread.id, commentId)}
            onEditComment={(commentId, content) => onUpdateThreadComment(thread.id, commentId, { content })}
          />
        ))}
    </>
  );
}
